import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import { Close, SearchOutlined } from '@material-ui/icons';
import { filterTableOnChange } from '../../../Common/functions/tableFiltering';

// component -> React-root
const CoArzneimmittellistenHinzufuegen = ({ objParent }) => {
  const [_, dispatch] = useStateValue();

  const [arzneimittellistenState, setArzneimittellistenState] = useState([]);
  const [filteredArzneimittellistenState, setFilteredArzneimittellistenState] = useState([]);
  const [filterInputState, setFilterInputState] = useState('');

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArzneimittellisenHinzufuegenDialog(false);
  };

  // function ->
  const handleArzneimittellisteAusgewaehlt = (liste) => {
    objParent.openOrCloseArzneimittellisenHinzufuegenDialog(false);
    objParent.handleArzneimittellisteAusgewaehlt(liste);
  };

  // function ->
  const arzneimittellistenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get('/arzneimittel-listen')
      .then((response) => {
        const listen = response.data;
        setArzneimittellistenState(listen);
        setFilteredArzneimittellistenState(listen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    arzneimittellistenHolen();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={objParent.arzneimittellisenHinzufuegenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {sprache('DIALOG', 'TITEL', 'ARZNEIMITTELLISTEN')}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography__dialogSubtitle">
              {sprache('TEXT', 'KURZ', 'LIEFERANTENGRUPPE')}: {objParent.arzneimittellisenHinzufuegenDialogState.lieferantengruppenname}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{sprache('TEXT', 'MELDUNG', 'LISTEAUSWÄHLEN')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={filterInputState}
              onChange={(event) => filterTableOnChange(event, setFilterInputState, arzneimittellistenState, setFilteredArzneimittellistenState, 'name')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                )
              }}
              className="table__searchBar"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="medium" stickyHeader>
                <TableBody>
                  {filteredArzneimittellistenState.map((liste, index) => {
                    const { name } = liste;
                    return (
                      <TableRow key={`coLieferanten_arzneimittellisten_dialog_tableBody_row${index}`}>
                        <TableCell align="left" className="table__cell--hover" onClick={handleArzneimittellisteAusgewaehlt.bind(null, liste)}>
                          <Typography>{name}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoArzneimmittellistenHinzufuegen;
