import React, { useState } from "react";
import QrReader from "react-qr-reader";

// import -> common
import { sprache } from "../Common/sprache/sprache";
import CoBerechtigungleitfadenDialog from "../Common/components/js/CoBerechtigungleitfadenDialog";

// import -> material-ui
import { Button, Grid, Link, Typography } from "@material-ui/core";
import { PriorityHigh } from "@material-ui/icons";

// import -> reducer
import dispatchActions from "../../reducer/dispatchActions";
import useStateValue from "../../reducer/StateProvider";

const FACING_MODES = {
  ENVIRONMENT: "environment",
  USER: "user",
};

// component -> React-root
const WhenCameraAvailable = ({ objParent }) => {
  const { lieferantAnmelden } = objParent;

  const [globalState, dispatch] = useStateValue();
  const [facingModeState, setFacingModeState] = useState(
    FACING_MODES.ENVIRONMENT
  );

  // function ->
  const handleQrScannerOnScan = (data) => {
    if (data) {
      lieferantAnmelden(data);
    }
  };

  // function ->
  const facingModeAendernOnClick = () => {
    setFacingModeState((pre) => {
      return pre === FACING_MODES.ENVIRONMENT
        ? FACING_MODES.USER
        : FACING_MODES.ENVIRONMENT;
    });
  };

  // function ->
  const handleQrScannerError = () => {
    dispatch({ type: dispatchActions.removeCamera });
  };

  if (!globalState.cameraAvailable) {
    return <WhenNoCameraAvailable />;
  }

  return (
    <>
      <QrReader
        className="coQrScanner__qrReader"
        facingMode={facingModeState}
        delay={300}
        onError={handleQrScannerError}
        onScan={handleQrScannerOnScan}
      />
      <Button
        className="button__middle--red margin__top10"
        onClick={facingModeAendernOnClick}
      >
        {sprache("BUTTON", "TITEL", "KAMERAÄNDERN")}
      </Button>
    </>
  );
};

// component -> React-root
const WhenNoCameraAvailable = () => {
  const [
    berechtigungleitfadenDialogState,
    setBerechtigungleitfadenDialogState,
  ] = useState({ open: false });

  // function ->
  const openOrCloseBerechtigungleitfadenDialog = (shouldOpen) => {
    setBerechtigungleitfadenDialogState({ open: shouldOpen });
  };

  const objWhenNoCameraAvailable = {
    coBerechtigungleitfadenDialog: {
      berechtigungleitfadenDialogState,
      openOrCloseBerechtigungleitfadenDialog,
    },
  };

  return (
    <>
      {berechtigungleitfadenDialogState.open && (
        <CoBerechtigungleitfadenDialog
          objParent={objWhenNoCameraAvailable.coBerechtigungleitfadenDialog}
        />
      )}
      <Grid container className="padding__10 boxShadow">
        <Grid item xs={12}>
          <Typography className="typography flex">
            <PriorityHigh className="icon__highPriority" /> Sie haben den Zugang
            zu Ihrer Kamera verweigert!
          </Typography>
        </Grid>
        <Grid item xs={12} className="margin__top10">
          <Link
            component="button"
            className="button__link--blue size_small"
            onClick={() => openOrCloseBerechtigungleitfadenDialog(true)}
          >
            Wie Kann ich die Kameraberechtigung einer Webseite ändern?
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

// component -> React-root
const CoQRScanner = ({ objParent }) => {
  return <WhenCameraAvailable objParent={objParent} />;
};

export default CoQRScanner;
