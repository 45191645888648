import React, { useEffect, useState } from 'react';

// import -> common
import { createAxiosInstance, handleAndDownloadblobResponse } from '../Common/functions/axios';

// import -> reducer
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputLabel
} from '@material-ui/core';

// import -> components
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../Common/constants/tableSorting';
import { showHeaderWithSorting, sortTable } from '../Common/functions/tableSorting';
import { SearchOutlined } from '@material-ui/icons';
import CoPaginationTableFooter from '../Common/components/js/CoPaginationTableFooter';
import CoArtikelTableDialog from './Append/CoArtikelTableDialog';

const INITIAL_ELEMENTS_PER_PAGE = 5;

const CoLieferuebersicht = () => {
  const [globalState, _] = useStateValue();

  const isLieferant = globalState.authentication.user.access === 'LIEFERANT';
  const isAdmin = globalState.authentication.user.access === 'ADMIN' || globalState.authentication.user.access === 'SUPER_USER';

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [paketeState, setPaketeState] = useState([]);
  const [lieferantenState, setLieferantenState] = useState([]);
  const [selectedLieferantState, setSelectedLieferantState] = useState('alle');
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [filterInputState, setFilterInputState] = useState('');
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.NAME]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.LIEFERANT]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.VERSANDDATUM]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });
  const [elementsPerPageState, setElementsPerPageState] = useState(INITIAL_ELEMENTS_PER_PAGE);
  const [numOfPagesState, setNumOfPagesState] = useState(1);
  const [currentPageState, setCurrentPageState] = useState(1);
  const [showTableState, setShowTableState] = useState(false);
  const [artikelTableDialogState, setArtikelTableDialogState] = useState({
    paketId: '',
    open: false,
    paketName: ''
  });

  const tableHeaders =
    isAdmin && selectedLieferantState === 'alle'
      ? ['NAME', 'VERSANDDATUM', 'ARZNEIMITTELLISTEN', 'LIEFERANT', 'QRCODE', 'ARTIKEL']
      : isAdmin
      ? ['NAME', 'VERSANDDATUM', 'ARZNEIMITTELLISTEN', 'QRCODE', 'ARTIKEL']
      : ['NAME', 'VERSANDDATUM', 'ARZNEIMITTELLISTEN', 'INHALTSLISTE', 'QRCODE', 'ARTIKEL'];

  // function ->
  const openOrCloseArtikelTableDialog = (shouldOpen, paketName = '', paketId = '') => {
    setArtikelTableDialogState({
      paketId,
      paketName,
      open: shouldOpen
    });
  };

  // function ->
  const getAndDownloadQRCode = (id) => {
    authAxios
      .get(`/paket/${id}/qr-code`, { responseType: 'blob' })
      .then((response) => {
        handleAndDownloadblobResponse(response.data, 'pdf', 'qr-code');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const handleGetPaketSuccess = (paketeDaten) => {
    const totalElements = paketeDaten.totalElements;
    const elementsPerPage = elementsPerPageState.length === 0 ? INITIAL_ELEMENTS_PER_PAGE : +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    setPaketeState(paketeDaten.content);
    setShowTableState(true);
    setRerunEffectState((pre) => !pre);
  };

  // function ->
  const generatePDF = (paketId) => {
    authAxios.get(`/paket/${paketId}/pdf`, { responseType: 'blob' }).then((response) => {
      const { data } = response;
      const paketName = paketeState.find((paket) => paketId === paket.id).name;
      handleAndDownloadblobResponse(data, 'pdf', paketName);
    });
  };

  // function ->
  const getPakete = (isAdmin, lieferantId) => {
    const page = currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState.length === 0 ? INITIAL_ELEMENTS_PER_PAGE : +elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    const requestUrl =
      isAdmin && lieferantId === 'alle'
        ? `/paket/all/versandbereit?page=${page}&size=${size}&sort=id,desc`
        : isAdmin
        ? `/paket/${lieferantId}/all/versandbereit?page=${page}&size=${size}&sort=id,desc`
        : `/paket/all/versandbereit?page=${page}&size=${size}&sort=id,desc`;

    authAxios
      .post(requestUrl, {
        string: filterInputState
      })
      .then((response) => {
        const paketeDaten = response.data;
        handleGetPaketSuccess(paketeDaten);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const changeLieferanten = (event) => {
    setSelectedLieferantState(event.target.value);
    getPakete(true, event.target.value);
  };

  // function ->
  const getLieferanten = () => {
    authAxios
      .get('/user/lieferanten')
      .then((response) => {
        setLieferantenState(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (isLieferant) {
      getPakete();
    } else if (isAdmin && selectedLieferantState) {
      getPakete(true, selectedLieferantState);
    }

    return () => {
      isMounted = false;
    };
  }, [currentPageState, elementsPerPageState, filterInputState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (isAdmin) {
      getLieferanten();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // function ->
  const filterTable = (event) => {
    setFilterInputState(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, paketeState, setPaketeState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objLieferuebersicht = {
    paginationTableFooter: {
      currentPageState,
      setCurrentPageState,
      setElementsPerPageState,
      numOfPagesState,
      setNumOfPagesState,
      firstCellColSpan: 2,
      secondCellColSpan: isAdmin && selectedLieferantState === 'alle' ? 2 : 1,
      thirdCellColSpan: 1
    },
    artikelTableDialog: {
      openOrCloseArtikelTableDialog,
      artikelTableDialogState
    }
  };

  return (
    <>
      {artikelTableDialogState.open ? <CoArtikelTableDialog objParent={objLieferuebersicht.artikelTableDialog} /> : ''}
      <CoSeitenstruktur seitentitel="LIEFERUEBERSICHT">
        <Grid item container spacing={3} className="margin__top30">
          {isAdmin ? (
            <Grid item xs={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="coLieferuebersicht__lieferantauswahl">Lieferant</InputLabel>
                <Select value={selectedLieferantState} onChange={changeLieferanten} labelId="coLieferuebersicht__lieferantauswahl" label="Lieferant">
                  <MenuItem value="alle">Alle Lieferanten</MenuItem>
                  {lieferantenState.map((lieferant, index) => {
                    return (
                      <MenuItem key={`lieferant_option_${index}`} value={lieferant.id}>
                        {lieferant.username}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            {showTableState ? (
              <Paper>
                <TextField
                  fullWidth
                  vallue={filterInputState}
                  onChange={filterTable}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    )
                  }}
                  className="table__searchBar"
                  variant="standard"
                />
                <TableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow className="table__title">
                        {tableHeaders.map((header, index) => {
                          return (
                            <TableCell align="center" component="th" key={`coLieferuebersicht_paketentabelle_tabllenheader_${index}`}>
                              {showHeaderWithSorting(header, sortingState, setSortingState, paketeState, setPaketeState)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paketeState.map((gruppe, index) => {
                        const { name, versanddatum, lieferant, id, arzneimittellisten } = gruppe;
                        return (
                          <TableRow key={`coLieferuebersicht_paketentabelle_tableBody_tableRow_${index}`}>
                            <TableCell align="center">
                              <Typography>{name}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{versanddatum}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{arzneimittellisten?.join('; ')}</Typography>
                            </TableCell>
                            {isAdmin && selectedLieferantState === 'alle' ? (
                              <TableCell align="center">
                                <Typography>{lieferant}</Typography>
                              </TableCell>
                            ) : (
                              ''
                            )}
                            {!isAdmin ? (
                              <TableCell align="center">
                                <Button className="button__middle--black" onClick={() => generatePDF(id)}>
                                  Drucken
                                </Button>
                              </TableCell>
                            ) : (
                              ''
                            )}

                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={() => getAndDownloadQRCode(id)}>
                                Generieren
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={() => openOrCloseArtikelTableDialog(true, name, id)}>
                                Ansehen
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <CoPaginationTableFooter objParent={objLieferuebersicht.paginationTableFooter} />
                  </Table>
                </TableContainer>
              </Paper>
            ) : (
              ''
            )}

            <br />
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
      </CoSeitenstruktur>
    </>
  );
};

export default CoLieferuebersicht;
