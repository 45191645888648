import { forwardRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { SnackbarContent, useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';
import SnackbarCloseButton from './SnackbarCloseButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '@media (min-width:600px)': {
      minWidth: '344px !important'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  close: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '16px',
    marginRight: '-8px',
    marginLeft: 'auto'
  }
}));

export const PaketNameExistiertSnackbarKey = 'PaketNameExistiert';

const PaketNameExistiert = forwardRef(({ id, ...props }, ref) => {
  const classes = useStyles();

  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    const windowCloseSnackbarListener = window.addEventListener('click', (e) => {
      let target = e.target;
      let isSnackbar = false;
      // SnackbarItem-root
      while (target) {
        if (target.classList && [...target.classList].some((className) => className.split('-').includes('SnackbarItem'))) {
          isSnackbar = true;
          break;
        }
        target = target.parentNode;
      }

      if (!isSnackbar) {
        closeSnackbar(PaketNameExistiertSnackbarKey);
      }
    });

    return () => {
      window.removeEventListener('click', windowCloseSnackbarListener);
    };
  }, [closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert className="typography__alert" severity="error" sx={{ width: '100%' }}>
        <div className={classes.container}>
          <div>
            Paketname <strong>{props.paketName}</strong> existiert bereits
          </div>
          <div className={classes.close}>
            <SnackbarCloseButton snackbarKey={id} />
          </div>
        </div>
      </Alert>
    </SnackbarContent>
  );
});

PaketNameExistiert.displayName = PaketNameExistiertSnackbarKey;

export default PaketNameExistiert;
