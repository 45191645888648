import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../../../Common/constants/tableSorting';
import { showHeaderWithSorting, sortTable } from '../../../Common/functions/tableSorting';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material ui
import { Button, ButtonGroup, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';

// import -> components
import CoArzneimittellisenHinzufuegen from '../Arzneimittellisten/CoArzneimittellistenHinzufuegen';
import CoArzneimittellisenZeigen from '../Arzneimittellisten/CoArzneimittellistenZeigen';
import CoLieferantengruppeErstellenBearbeitenDialog from './CoLieferantengruppeErstellenBearbeiten';
import CoLoeschenbestaetigung from '../../../Common/components/js/CoLoeschenbestaetigung';
import { filterTableOnChange } from '../../../Common/functions/tableFiltering';
import { SearchOutlined } from '@material-ui/icons';

// component -> React-root
const CoLieferantengruppentabelle = ({ objParent }) => {
  const [globalState, dispatch] = useStateValue();

  const [lieferantengruppenState, setLieferantengruppenState] = useState([]);
  const [filteredLieferantengruppenState, setFilteredLieferantengruppenState] = useState([]);
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [filterInputState, setFilterInputState] = useState('');
  const [arzneimittellisenHinzufuegenDialogState, setArzneimittellisenHinzufuegenDialogState] = useState({ open: false, lieferatengruppe: {} });
  const [arzneimittellisenZeigenDialogState, setArzneimittellisenZeigenDialogState] = useState({ open: false, lieferantengruppe: {} });
  const [loeschenbestaetigungState, setLoeschenbestaetigungState] = useState(false);
  const [focusedListIdState, setFocusedListIdState] = useState(null);
  const [lieferantengruppeErstellenBearbeitenDialogState, setLieferantengruppeErstellenBearbeitenDialogState] = useState({ open: false, lieferantengruppe: {} });
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.GRUPPENNAME]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.GRUPPENNUMMER]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });

  const tabellenheader = ['GRUPPENNAME', 'GRUPPENNUMMER', 'LIEFERANTEN', 'ARZNEIMITTELLISTEN', 'BEARBEITEN', 'LÖSCHEN'];

  // function ->
  const openOrCloseLieferantengruppeErstellenBearbeitenDialog = (shouldOpen, lieferantengruppe) => {
    setLieferantengruppeErstellenBearbeitenDialogState({ open: shouldOpen, lieferantengruppe, bearbeiten: true });
  };

  // function ->
  const handleArzneimittellisteAusgewaehlt = (liste) => {
    const lieferantengruppeId = focusedListIdState;
    const listeId = liste.id;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .post(`/lieferantengruppen/${lieferantengruppeId}/arzneimittel-listen/${listeId}`)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const openOrCloseArzneimittellisenHinzufuegenDialog = (shouldOpen, lieferantengruppe) => {
    setArzneimittellisenHinzufuegenDialogState({ open: shouldOpen, lieferantengruppenname: lieferantengruppe?.name });
    setFocusedListIdState(lieferantengruppe?.id);
  };

  // function ->
  const openOrCloseArzneimittellisenZeigenDialog = (shouldOpen, lieferantengruppe) => {
    setArzneimittellisenZeigenDialogState({ open: shouldOpen, lieferantengruppe });
  };

  // function ->
  const openOrCloseLoeschenbestaetigung = (shouldOpen) => {
    setLoeschenbestaetigungState(shouldOpen);
  };

  // function ->
  const lieferantengruppeLoeschenOnClick = (gruppeId) => {
    openOrCloseLoeschenbestaetigung(true);
    setFocusedListIdState(gruppeId);
  };

  // function ->
  const handleLoeschenBestaetigt = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .delete(`/lieferantengruppen/${focusedListIdState}`)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  const objLieferantengruppentabelle = {
    coArzneimittellisenHinzufuegen: {
      openOrCloseArzneimittellisenHinzufuegenDialog,
      handleArzneimittellisteAusgewaehlt,
      authAxios: objParent.authAxios,
      arzneimittellisenHinzufuegenDialogState
    },
    coArzneimittellisenZeigen: {
      openOrCloseArzneimittellisenZeigenDialog,
      authAxios: objParent.authAxios,
      arzneimittellisenZeigenDialogState
    },
    coLoeschenbestaetigung: {
      loeschenbestaetigungState,
      openOrCloseLoeschenbestaetigung,
      handleLoeschenBestaetigt
    },
    coLieferantengruppeErstellenBearbeitenDialog: {
      lieferantengruppeErstellenBearbeitenDialogState,
      authAxios: objParent.authAxios,
      openOrCloseLieferantengruppeErstellenBearbeitenDialog,
      setRerunEffectState: objParent.setRerunEffectState
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    const lieferantengruppen = [...objParent.lieferantengruppenState];
    setLieferantengruppenState(lieferantengruppen);
    setFilteredLieferantengruppenState(lieferantengruppen);

    return () => {
      isMounted = false;
    };
  }, [objParent.lieferantengruppenState]);

  // function ->
  const callFilterTableOnChange = (eventOrEventMock) => {
    filterTableOnChange(eventOrEventMock, setFilterInputState, lieferantengruppenState, setFilteredLieferantengruppenState, 'name', 'gruppennummer');
    setRerunEffectState((pre) => !pre);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (filterInputState.length) {
      callFilterTableOnChange({ target: { value: filterInputState } });
    }

    return () => {
      isMounted = false;
    };
  }, [lieferantengruppenState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, filteredLieferantengruppenState, setFilteredLieferantengruppenState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  return (
    <>
      {arzneimittellisenHinzufuegenDialogState.open && <CoArzneimittellisenHinzufuegen objParent={objLieferantengruppentabelle.coArzneimittellisenHinzufuegen} />}
      {arzneimittellisenZeigenDialogState.open && <CoArzneimittellisenZeigen objParent={objLieferantengruppentabelle.coArzneimittellisenZeigen} />}
      {lieferantengruppeErstellenBearbeitenDialogState.open && <CoLieferantengruppeErstellenBearbeitenDialog objParent={objLieferantengruppentabelle.coLieferantengruppeErstellenBearbeitenDialog} />}
      {loeschenbestaetigungState && <CoLoeschenbestaetigung objParent={objLieferantengruppentabelle.coLoeschenbestaetigung} />}
      <Paper>
        <TextField
          fullWidth
          vallue={filterInputState}
          onChange={(event) => callFilterTableOnChange(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            )
          }}
          className="table__searchBar"
          variant="standard"
        />
        <TableContainer>
          <Table size="medium" stickyHeader>
            <TableHead>
              <TableRow className="table__title">
                {tabellenheader.map((header, index) => {
                  return (
                    <TableCell align="center" component="th" key={`coLiefernaten_gruppentabelle_tabllenheader_${index}`}>
                      {showHeaderWithSorting(header, sortingState, setSortingState, filteredLieferantengruppenState, setFilteredLieferantengruppenState)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLieferantengruppenState.map((gruppe, index) => {
                const { name, gruppennummer, id } = gruppe;
                return (
                  <TableRow key={`coLieferanten_gruppentabelle_tableBody_tableRow_${index}`}>
                    <TableCell align="center">
                      <Typography>{name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{gruppennummer}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button className="button__middle--black" onClick={objParent.openOrCloseLieferantentabelle.bind(null, true, id, name)}>
                        {sprache('BUTTON', 'TITEL', 'LIEFERANTEN')}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <ButtonGroup>
                        <Button className="button__middle--black border__right--white" onClick={openOrCloseArzneimittellisenHinzufuegenDialog.bind(null, true, gruppe)}>
                          {sprache('BUTTON', 'TITEL', 'HINZUFÜGEN')}
                        </Button>
                        {objParent.istVonArzneimittelliste ? (
                          ''
                        ) : (
                          <Button className="button__middle--black" onClick={openOrCloseArzneimittellisenZeigenDialog.bind(null, true, gruppe)}>
                            {sprache('BUTTON', 'TITEL', 'ZEIGEN')}
                          </Button>
                        )}
                      </ButtonGroup>
                    </TableCell>
                    <TableCell align="center">
                      <Button className="button__middle--black" onClick={openOrCloseLieferantengruppeErstellenBearbeitenDialog.bind(null, true, gruppe)}>
                        {sprache('BUTTON', 'TITEL', 'BEARBEITEN')}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button className="button__middle--black" onClick={lieferantengruppeLoeschenOnClick.bind(null, id)}>
                        {sprache('BUTTON', 'TITEL', 'LÖSCHEN')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default CoLieferantengruppentabelle;
