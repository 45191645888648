import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';

// import -> common
import { changeTextFieldsOnChange } from '../Common/functions/form';
import { LOCALSTORAGE_ITEMS } from '../Common/constants/localStorageItems';
import { logUserOut, parseJwt } from '../Common/functions/user';
import { sprache } from '../Common/sprache/sprache';
import { createAxiosInstance } from '../Common/functions/axios';

// import -> reducer
import dispatchActions from '../../reducer/dispatchActions';
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { Button, Container, Grid, TextField, Typography } from '@material-ui/core';

// import -> components
import CoQRScanner from '../QRScanner/CoQRScanner';
import CoFehlermeldung from '../Common/components/js/CoFehlermeldung';

const ANMELDER_TYPEN = {
  ADMIN: 1,
  LIEFERANT: 2
};

const TEXT_FIELD_NAMEN = {
  BENUTZERNAME: 1,
  PASSWORT: 2
};

const CoAnmeldung = () => {
  const history = useHistory();
  const { state } = useLocation();

  const [_, dispatch] = useStateValue();
  const [anmelderState, setAnmelderState] = useState(ANMELDER_TYPEN.ADMIN);
  const [fehlermeldungState, setFehlermeldungState] = useState({ open: false, text: '' });
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.BENUTZERNAME]: {
      wert: '',
      fehler: true
    },
    [TEXT_FIELD_NAMEN.PASSWORT]: {
      wert: '',
      fehler: true
    }
  });

  // function ->
  const anmelderAendernOnClick = (anmelderTyp) => {
    if (anmelderTyp === ANMELDER_TYPEN.ADMIN) {
      setTimeout(() => {
        setAnmelderState(anmelderTyp);
      }, 2000);
    } else {
      setAnmelderState(anmelderTyp);
    }
    setFehlermeldungState({ open: false, text: '' });
  };

  // function ->
  const lieferantAnmelden = (data) => {
    const requestBody = {
      token: data
    };
    axios
      .post(`${process.env.REACT_APP_API_HOST}/auth/lieferanten-login`, requestBody)
      .then((response) => {
        const benutzerDaten = response.data;
        handleAnmeldungSuccess(benutzerDaten);
      })
      .catch((error) => {
        handleLieferantAnmeldenError(error);
      });
  };

  // function ->
  const adminAnmelden = (benutzername, passwort) => {
    const requestBody = {
      username: benutzername,
      password: passwort
    };

    axios
      .post(`${process.env.REACT_APP_API_HOST}/auth/login`, requestBody)
      .then((response) => {
        const benutzerDaten = response.data;
        handleAnmeldungSuccess(benutzerDaten);
        history.push(state.from.pathname);
      })
      .catch((error) => {
        handleAdminAnmeldenFehler(error);
      });
  };

  // function ->
  const handleAnmeldungSuccess = (benutzerDaten) => {
    const { token, role: access, name } = benutzerDaten;

    const tokenExpirationTimeOut = parseJwt(token).exp - parseJwt(token).iat;
    const expirationDate = parseJwt(token).exp - parseJwt(token).iat + new Date().getTime();

    const isAdmin = access === 'ADMIN';
    if (isAdmin) {
      const authAxios = createAxiosInstance(token);
      authAxios.get('/admin');
    }

    dispatch({ type: dispatchActions.signUserIn, payload: { token, access, name } });

    localStorage.setItem(LOCALSTORAGE_ITEMS.MEDICHECKAUTHENTICATION, JSON.stringify({ auth: true, token, user: { access, name }, expirationDate }));

    setTimeout(() => logUserOut(dispatch), tokenExpirationTimeOut);
  };

  // function ->
  const handleLieferantAnmeldenError = (error) => {
    const fehlermeldungtext = sprache('TEXT', 'MELDUNG', 'FALSCHSCANNEREINGABE');
    setFehlermeldungState({ open: true, text: fehlermeldungtext });
    console.log(error);
  };

  // function ->
  const handleAdminAnmeldenFehler = (error) => {
    const fehlermeldungtext = sprache('TEXT', 'MELDUNG', 'FALSCHBENUTZERNAMEPASSWORT');
    setFehlermeldungState({ open: true, text: fehlermeldungtext });
    console.log(error);
  };

  // function ->
  const submitFormOnSubmit = (event) => {
    event.preventDefault();

    const benutzername = textFieldsState[TEXT_FIELD_NAMEN.BENUTZERNAME];
    const passwort = textFieldsState[TEXT_FIELD_NAMEN.PASSWORT];

    const benutzernamewert = benutzername.wert;
    const passwortwert = passwort.wert;

    const istBenutzernameTextFieldLeer = benutzername.fehler;
    const istPasswortTextFieldLeer = passwort.fehler;

    if (istBenutzernameTextFieldLeer || istPasswortTextFieldLeer) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }
    adminAnmelden(benutzernamewert, passwortwert);
  };

  const objParentAnmeldung = {
    coQRScanner: {
      lieferantAnmelden
    },
    coFehlermeldung: {
      fehlermeldungState
    }
  };

  return (
    <Container component="main" maxWidth="xl" className="container__vollBreite anmeldung__container">
      <Grid container spacing={3} className="anmeldung__gridSeite">
        <Grid item xs={12} className="grid__webseiteTitel">
          <Typography className="typography__webseiteTitel" align="center">
            {sprache('GENERELL', 'WEBSEITENAME', 'MEDICHECK')}
          </Typography>
        </Grid>
        <Grid item xs={12} className="anmeldung__gridSeiteTitel">
          <Typography className="anmeldung__typographyEinzigSeiteTitel" align="center">
            {sprache('SEITE', 'TITEL', 'ANMELDUNG')}
          </Typography>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Button fullWidth className="button__middle--red" onClick={() => anmelderAendernOnClick(ANMELDER_TYPEN.ADMIN)}>
              {sprache('BUTTON', 'TITEL', 'MITTELSLOGINDATEN')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth className="button__middle--red" onClick={() => anmelderAendernOnClick(ANMELDER_TYPEN.LIEFERANT)}>
              {sprache('BUTTON', 'TITEL', 'MITTELSQRCODE')}
            </Button>
          </Grid>
        </Grid>
        {fehlermeldungState.open && (
          <Grid item xs={12}>
            <CoFehlermeldung objParent={objParentAnmeldung.coFehlermeldung} />
          </Grid>
        )}
        <Grid item xs={12}>
          {anmelderState === ANMELDER_TYPEN.ADMIN && (
            <form onSubmit={submitFormOnSubmit}>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    value={textFieldsState[TEXT_FIELD_NAMEN.BENUTZERNAME].wert}
                    onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.BENUTZERNAME, setTextFieldsState)}
                    error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.BENUTZERNAME].fehler}
                    helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.BENUTZERNAME].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    autoFocus
                    label={sprache('FORM', 'LABEL', 'BENUTZERNAME')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={textFieldsState[TEXT_FIELD_NAMEN.PASSWORT].wert}
                    onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.PASSWORT, setTextFieldsState)}
                    error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PASSWORT].fehler}
                    helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PASSWORT].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    type="password"
                    label={sprache('FORM', 'LABEL', 'PASSWORT')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth type="submit" className="button__middle--red">
                    {sprache('BUTTON', 'TITEL', 'ANMELDEN')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {anmelderState === ANMELDER_TYPEN.LIEFERANT && <CoQRScanner objParent={objParentAnmeldung.coQRScanner} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoAnmeldung;
