import React, { useEffect, useState } from 'react';

// import -> common
import { changeTextFieldsOnChange } from '../../../Common/functions/form';
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const TEXT_FIELD_NAMEN = {
  VERFALLSDATUM: 1
};

// component -> React-root
const CoArzneimmittellistenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();
  const [arzneimittellistenState, setArzneimittellistenState] = useState([]);
  const [focusedListeState, setFocusedListeState] = useState({});
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.VERFALLSDATUM]: {
      wert: '',
      fehler: true
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArzneimittellistenDialog(false);
  };

  // function ->
  const handleArzneimittellisteAusgewaehlt = (liste) => {
    setFocusedListeState(liste);
  };

  // function ->
  const handleVerfallsdatumEingegeben = (event) => {
    event.preventDefault();

    const verfallsdatum = textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM];
    const verfallsdatumfehler = verfallsdatum.fehler;
    if (verfallsdatumfehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }
    const verfallsdatumwert = verfallsdatum.wert;
    const liste = focusedListeState;

    objParent.openOrCloseArzneimittellistenDialog(false);
    objParent.handleArzneimittellisteAusgewaehlt(liste, verfallsdatumwert);
  };

  const handleVerfallsdatumAbbgebrochen = () => {
    setFocusedListeState({});
  };

  // function ->
  const arzneimittellistenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });
    objParent.authAxios
      .get('/arzneimittel-listen')
      .then((response) => {
        const listen = response.data;
        setArzneimittellistenState(listen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    arzneimittellistenHolen();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={objParent.arzneimittellistenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {sprache('DIALOG', 'TITEL', 'ARZNEIMITTELLISTEN')}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {objParent.arzneimittellistenDialogState.arzneimittel.bezeichnung && (
            <Grid item xs={12}>
              <Typography className="typography__dialogSubtitle" align="left">
                {sprache('TEXT', 'KURZ', 'ARZNEIMITTEL')}: {objParent.arzneimittellistenDialogState.arzneimittel.bezeichnung}
              </Typography>
            </Grid>
          )}
          {!focusedListeState.id ? (
            <>
              <Grid item xs={12}>
                <Typography>{sprache('TEXT', 'MELDUNG', 'LISTEAUSWÄHLEN')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="medium" stickyHeader>
                    <TableBody>
                      {arzneimittellistenState.map((liste, index) => {
                        const { name } = liste;
                        return (
                          <TableRow key={`coArzneimittel_arzneimittellisten_dialog_tableBody_row${index}`}>
                            <TableCell align="left" className="table__cell--hover" onClick={handleArzneimittellisteAusgewaehlt.bind(null, liste)}>
                              <Typography>{name}</Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <form noValidate onSubmit={handleVerfallsdatumEingegeben}>
                <Grid item container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>{focusedListeState.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="date"
                      value={textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].wert}
                      onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.VERFALLSDATUM, setTextFieldsState)}
                      error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].fehler}
                      helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                      fullWidth
                      color="primary"
                      variant="outlined"
                      autoFocus
                      label={sprache('FORM', 'LABEL', 'VERFALLSDATUM')}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button fullWidth className="button__middle--blue" type="submit">
                      {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button fullWidth className="button__middle--red" onClick={handleVerfallsdatumAbbgebrochen}>
                      {sprache('BUTTON', 'TITEL', 'ABBRECHEN')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoArzneimmittellistenDialog;
