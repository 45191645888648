import React, { useState } from 'react';

// import -> common
import { changeTextFieldsOnChange } from '../../../Common/functions/form';
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const TEXT_FIELD_NAMEN = {
  NAME: 1
};

// component -> React-root
const CoArzneimittellisteErstellenBearbeitenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.NAME]: {
      wert: objParent.arzneimittellisteErstellenBearbeitenDialogState?.arzneimittelliste?.name ?? '',
      fehler: !Boolean(objParent.arzneimittellisteErstellenBearbeitenDialogState?.arzneimittelliste?.name?.length)
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArzneimittellisteErstellenBearbeitenDialog(false);
  };

  // function ->
  const arzneimittellisteErstellenRequest = (name) => {
    const requestBody = { name };
    objParent.authAxios
      .post('/arzneimittel-listen', requestBody)
      .then(() => {
        closeDialog();
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const arzneimittellisteBearbeitenRequest = (name) => {
    const requestBody = { name };
    const arzneimittellisteId = objParent.arzneimittellisteErstellenBearbeitenDialogState.arzneimittelliste?.id;
    objParent.authAxios
      .put(`/arzneimittel-listen/${arzneimittellisteId}`, requestBody)
      .then(() => {
        closeDialog();
        objParent.setRerunEffectState?.((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const arzneimittellisteErstellenBearbeitenOnSubmit = (event) => {
    event.preventDefault();

    const name = textFieldsState[TEXT_FIELD_NAMEN.NAME];

    const namefehler = name.fehler;

    if (namefehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    const namewert = name.wert;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    if (objParent.arzneimittellisteErstellenBearbeitenDialogState.erstellen) {
      arzneimittellisteErstellenRequest(namewert);
    } else if (objParent.arzneimittellisteErstellenBearbeitenDialogState.bearbeiten) {
      arzneimittellisteBearbeitenRequest(namewert);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={objParent.arzneimittellisteErstellenBearbeitenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {objParent.arzneimittellisteErstellenBearbeitenDialogState.erstellen
                ? sprache('DIALOG', 'TITEL', 'ARZNEIMITTELLISTEERSTELLEN')
                : sprache('DIALOG', 'TITEL', 'ARZNEIMITTELLISTEBEARBEITEN')}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={arzneimittellisteErstellenBearbeitenOnSubmit}>
          <Grid container spacing={3}>
            {objParent.arzneimittellisteErstellenBearbeitenDialogState.bearbeiten && (
              <Grid item xs={12} className="typography__dialogSubtitle">
                <Typography>
                  {sprache('TEXT', 'KURZ', 'ARZNEIMITTELLISTE')}: {objParent.arzneimittellisteErstellenBearbeitenDialogState.arzneimittelliste?.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                value={textFieldsState[TEXT_FIELD_NAMEN.NAME].wert}
                onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.NAME, setTextFieldsState)}
                error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler}
                helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                fullWidth
                color="primary"
                variant="outlined"
                autoFocus
                label={sprache('FORM', 'LABEL', 'NAME')}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth className="button__middle--blue" type="submit">
                {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth className="button__middle--red" onClick={closeDialog}>
                {sprache('BUTTON', 'TITEL', 'ABBRECHEN')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CoArzneimittellisteErstellenBearbeitenDialog;
