import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoLieferantengruppenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();

  const [lieferantengruppenState, setLieferantengruppenState] = useState([]);
  const [gruppenBestimmtesLieferantenState, setGruppenBestimmtesLieferantenState] = useState([]);

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseLieferantengruppenDialog(false);
  };

  // function ->
  const handleLieferantengruppeAusgewaehlt = (gruppe) => {
    objParent.openOrCloseLieferantengruppenDialog(false);
    objParent.handleLieferantengruppeAusgewaehlt(gruppe);
  };

  // function ->
  const lieferantengruppenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get('/lieferantengruppen')
      .then((response) => {
        const gruppen = response.data;
        setLieferantengruppenState(gruppen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const gruppenBestimmtesLieferantenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    const lieferantenId = objParent.lieferantengruppenDialogState.lieferant.id;

    objParent.authAxios
      .get(`/lieferanten/${lieferantenId}/lieferantengruppen`)
      .then((response) => {
        const gruppen = response.data;
        const gruppenNamen = gruppen.map((gruppe) => gruppe.name);
        setGruppenBestimmtesLieferantenState(gruppenNamen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    lieferantengruppenHolen();
    if (objParent.lieferantengruppenDialogState.lieferant.id) gruppenBestimmtesLieferantenHolen();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={objParent.lieferantengruppenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {sprache('DIALOG', 'TITEL', 'LIEFERANTENGRUPPEN')}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {objParent.lieferantengruppenDialogState.lieferant.name && (
            <Grid item xs={12}>
              <Typography className="typography__dialogSubtitle" align="left">
                {sprache('TEXT', 'KURZ', 'LIEFERANT')}: {objParent.lieferantengruppenDialogState.lieferant.name}
              </Typography>
            </Grid>
          )}
          {objParent.lieferantengruppenDialogState.lieferant.name && (
            <Grid item xs={12}>
              <Typography className="typography__dialogSubtitle" align="left">
                {sprache('TEXT', 'KURZ', 'HINZUGEFÜGTZU')}: {gruppenBestimmtesLieferantenState.join(', ')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>{sprache('TEXT', 'MELDUNG', 'GRUPPEAUSWÄHLEN')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="medium" stickyHeader>
                <TableBody>
                  {lieferantengruppenState.map((gruppe, index) => {
                    const { name } = gruppe;
                    return (
                      <TableRow key={`coLieferanten_lieferantengruppen_dialog_tableBody_row${index}`}>
                        <TableCell align="left" className="table__cell--hover" onClick={handleLieferantengruppeAusgewaehlt.bind(null, gruppe)}>
                          <Typography>{name}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoLieferantengruppenDialog;
