import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoArzneimittellistentabelle from '../../../Arzneimittel/Append/Liste/CoArzneimittellistentabelle';
import CoArzneimitteltabelle from '../../../Arzneimittel/Append/Single/CoArzneimitteltabelle';

// component -> React-root
const CoArzneimmittellistenZeigen = ({ objParent }) => {
  const [_, dispatch] = useStateValue();

  const [rerunEffectState, setRerunEffectState] = useState(true);
  const [arzneimittellistenState, setArzneimittellistenState] = useState([]);
  const [arzneimitteltabelleState, setArzneimitteltabelleState] = useState({ open: false, listeId: '' });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArzneimittellisenZeigenDialog(false);
  };

  // function ->
  const openOrCloseArzneimitteltabelle = (shouldOpen, listId, name) => {
    setArzneimitteltabelleState({ open: shouldOpen, listId: listId ?? '', name: name ?? '' });
  };

  // function ->
  const arzneimittellistenHolen = () => {
    const gruppeId = objParent.arzneimittellisenZeigenDialogState.lieferantengruppe.id;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get(`/lieferantengruppen/${gruppeId}/arzneimittel-listen`)
      .then((response) => {
        const listen = response.data;
        setArzneimittellistenState(listen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    arzneimittellistenHolen();

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objArzneimittellistenZeigen = {
    coArzneimittellistentabelle: {
      arzneimittellistenState,
      authAxios: objParent.authAxios,
      openOrCloseArzneimitteltabelle,
      rerunEffectState,
      setRerunEffectState,
      istBenutzerAdmin: true,
      istVonLieferantengruppe: true,
      lieferantentgruppeId: objParent.arzneimittellisenZeigenDialogState.lieferantengruppe.id
    },
    coArzneimitteltabelle: {
      arzneimitteltabelleState,
      authAxios: objParent.authAxios,
      openOrCloseArzneimitteltabelle,
      rerunEffectState,
      setRerunEffectState
    }
  };

  return (
    <>
      {arzneimitteltabelleState.open && <CoArzneimitteltabelle objParent={objArzneimittellistenZeigen.coArzneimitteltabelle} />}
      <Dialog fullWidth maxWidth="lg" onClose={closeDialog} open={objParent.arzneimittellisenZeigenDialogState.open}>
        <DialogTitle>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography className="typography__dialogTitle" align="left">
                {sprache('DIALOG', 'TITEL', 'ARZNEIMITTELLISTEN')}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className="typography__dialogSubtitle">
                {sprache('TEXT', 'KURZ', 'LIEFERANTENGRUPPE')}: {objParent.arzneimittellisenZeigenDialogState.lieferantengruppe.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CoArzneimittellistentabelle objParent={objArzneimittellistenZeigen.coArzneimittellistentabelle} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoArzneimmittellistenZeigen;
