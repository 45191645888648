import React, { useState, useRef, useEffect } from 'react';

// import -> common
import { createAxiosInstance } from '../Common/functions/axios';
import { changeTextFieldsOnChange, obTextFieldLeerUeberpruefen } from '../Common/functions/form';
import { datumGenerieren } from '../Common/functions/datum';
import { sprache } from '../Common/sprache/sprache';
import { stopVideo } from '../Common/functions/kamera';

// import -> reducer
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { Accordion, AccordionSummary, AccordionDetails, Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import { CheckCircleOutlineSharp, HighlightOffSharp, ExpandMore } from '@material-ui/icons';

// import -> components
import CoDataMatrixScannerV1 from '../DataMatrixScanner/CoDataMatrixScanner';
import CoDataMatrixScannerV2 from '../DataMatrixScanner/CoDataMatrixScannerV2';
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';
import CoPakettemplate from './Append/CoPakettemplate';
import CoVersandPaket from './Append/CoVersandPaket';
import { getMobileOperatingSystem } from '../DataMatrixScanner/phoneDetector';

const KEINE_PRODUKT_INFO = {
  pzn: '',
  verfallsdatum: '',
  seriennummer: ''
};

const TEXT_FIELD_NAMEN = {
  GESAMT_CODE: 1,
  PZN: 11,
  VERFALLSDATUM: 3
};

const phoneOS = getMobileOperatingSystem();

const Scanner = phoneOS === 'iOS' ? CoDataMatrixScannerV1 : CoDataMatrixScannerV2;

const CoWarenscanner = () => {
  const [globalState, _] = useStateValue();

  const isLieferant = globalState.authentication.user.access === 'LIEFERANT';

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [accordionManuellOpenState, setAccordionManuellOpenState] = useState(true);
  const [accordionFreeOpenState, setAccordionFreeOpenState] = useState(true);
  const [rerunEffectState1, setRerunEffectState1] = useState(false);
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.PZN]: {
      wert: '',
      fehler: true
    },
    [TEXT_FIELD_NAMEN.VERFALLSDATUM]: {
      wert: '',
      fehler: true
    },
    [TEXT_FIELD_NAMEN.GESAMT_CODE]: {
      wert: '',
      fehler: true
    }
  });
  const [produktinfoState, setProduktinfoState] = useState(KEINE_PRODUKT_INFO);
  const [scannerZeigenState, setScannerZeigenState] = useState(true);
  const [produktVorhandenState, setProduktVorhandenState] = useState(null);
  const [pznfehlerState, setPznfehlerState] = useState(false);
  const [verfallsdatumfehlerState, setVerfallsdatumfehlerState] = useState(false);

  const codeInputRef = useRef();

  // function ->
  const echoProduktVorhanden = () => {
    const produktVorhanden = produktVorhandenState;
    if (produktVorhanden === null) {
      return;
    }

    const fehlerIn = verfallsdatumfehlerState ? 'Fehler: Verfallsdatum ist nicht ausreichend' : pznfehlerState ? 'Fehler: Artikel befindet sich nicht auf der Bestellliste' : '';

    if (produktVorhanden) {
      return (
        <Typography className="typography flex typography__blue typography__600">
          <CheckCircleOutlineSharp className="icon icon__check" /> Erfolgreich gescannt
        </Typography>
      );
    } else {
      return (
        <Typography className="typography flex typography__red typography__600">
          <HighlightOffSharp className="icon icon__error" /> {fehlerIn}
        </Typography>
      );
    }
  };

  // function ->
  const obScannerergebnisExistiertUeberpruefenUndBehandeln = (scannerergebnis) => {
    const requestBody = {
      datamatrix: scannerergebnis
    };

    authAxios
      .post('/check', requestBody)
      .then((response) => {
        const produktinfo = response.data;
        handleCheckErgebnis(produktinfo);
        clearGesamtcodeTextField();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setScannerZeigenState(true);
      });
  };

  // function ->
  const handleCheckErgebnis = (produktinfo) => {
    const produktVorhanden = produktinfo.valid;
    const restInfo = produktinfo.scannedData;
    setProduktVorhandenState(produktVorhanden);

    setPznfehlerState(produktinfo.pzn === 'Unbekannt');
    setVerfallsdatumfehlerState(!produktVorhanden && produktinfo.pzn !== 'Unbekannt');
    if (restInfo) {
      restInfo.gewuenschtVerfallsdatum = produktinfo.verfallsdatum;
      setProduktinfoState(restInfo);
    }
    setRerunEffectState1((pre) => !pre);
  };

  const clearGesamtcodeTextField = () => {
    setTextFieldsState((pre) => ({
      ...pre,
      [TEXT_FIELD_NAMEN.GESAMT_CODE]: {
        fehler: false,
        wert: ''
      }
    }));
    codeInputRef.current.focus();
  };

  // function ->
  const scannerSoundSpielen = () => {
    const scannerSound = document.querySelector('#coDataMatrixScanner_audio');
    scannerSound.play();
  };

  // function ->
  const handleScannerergebnis = (scannerergebnis) => {
    scannerSoundSpielen();
    stopVideo();
    setScannerZeigenState(false);
    setProduktVorhandenState(null);
    setProduktinfoState(KEINE_PRODUKT_INFO);
    obScannerergebnisExistiertUeberpruefenUndBehandeln(scannerergebnis);
  };

  // function ->
  const changeDateOnChange = (event) => {
    let value = event.target.value;
    if (value.length > textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].wert.length) {
      if (Number.isNaN(+value[0]) || +value[0] > 1) return;
      if ((value[1] && Number.isNaN(+value[1])) || (+value[0] === 1 && +value[1] > 2) || (+value[0] === 0 && +value[1] === 0)) return;
      if (value[2] && value[2] !== '-') {
        value = value.substring(0, 2) + '-' + value.substring(3);
      }
      if (value[3] && Number.isNaN(+value[3])) return;
      if (value[4] && Number.isNaN(+value[4])) return;
      if (value[5] && Number.isNaN(+value[5])) return;
      if (value[6] && Number.isNaN(+value[6])) return;
      if (value[7]) return;
    }

    setTextFieldsState((pre) => ({
      ...pre,
      [TEXT_FIELD_NAMEN.VERFALLSDATUM]: {
        fehler: obTextFieldLeerUeberpruefen(value) || value.length !== 7,
        wert: value
      }
    }));
  };

  // function ->
  const handleAccordionManuellOpenOnChange = (event, open) => {
    setAccordionManuellOpenState(open);
  };

  // function ->
  const handleAccordionFreeOpenOnChange = (event, open) => {
    setAccordionFreeOpenState(open);
  };

  // function ->
  const submitPZNFieldFormOnClick = (event) => {
    event.preventDefault();

    const pzn = textFieldsState[TEXT_FIELD_NAMEN.PZN];
    const verfallsdatum = textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM];

    const pznfehler = pzn.fehler;
    const verfallsdatumfehler = verfallsdatum.fehler;

    if (pznfehler || verfallsdatumfehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    const pznwert = pzn.wert;
    let verfallsdatumwert = verfallsdatum.wert;
    setProduktVorhandenState(null);
    setProduktinfoState(KEINE_PRODUKT_INFO);

    if (verfallsdatumwert.length !== 7) return;

    verfallsdatumwert = verfallsdatumwert.replace(/(\d{2})-(\d{4})/, '$2-$1');

    checkManuellEingabe(pznwert, verfallsdatumwert);
  };

  // function ->
  const submitFreeFormOnSubmit = (event) => {
    event.preventDefault();

    const gesamtcode = textFieldsState[TEXT_FIELD_NAMEN.GESAMT_CODE];
    const gesamtcodefehler = gesamtcode.fehler;

    if (gesamtcodefehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    const gesamtcodewert = gesamtcode.wert;
    setProduktVorhandenState(null);
    setProduktinfoState(KEINE_PRODUKT_INFO);
    obScannerergebnisExistiertUeberpruefenUndBehandeln(gesamtcodewert);
  };

  // function ->
  const checkManuellEingabe = (pzn, verfallsdatum) => {
    verfallsdatum = verfallsdatum + '-01';
    const requestBody = {
      pzn,
      verfallsdatum
    };
    authAxios
      .post('/check/manual', requestBody)
      .then((response) => {
        const produktinfo = response.data;
        handleCheckErgebnis(produktinfo);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setScannerZeigenState(true);
      });
  };

  const objParentWarenscanner = {
    coDataMatrixScanner: {
      scannerZeigenState,
      handleScannerergebnis
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    codeInputRef?.current.focus();

    return () => {
      isMounted = false;
    };
  }, []);

  const objWarenScannerState = {
    paketTemplate: {
      rerunEffectState1,
      setRerunEffectState1
    }
  };

  return (
    <CoSeitenstruktur seitentitel="WARENSCANNER">
      <Grid item container spacing={3} className="margin__top30" justify="flex-start">
        <Grid item xs={12} lg="auto">
          <Grid item xs={12}>
            <Scanner objParent={objParentWarenscanner.coDataMatrixScanner} />
          </Grid>
          <Grid item xs={12} className="margin__top10 width__630">
            <Accordion className="accordion" expanded={accordionFreeOpenState} onChange={handleAccordionFreeOpenOnChange}>
              <AccordionSummary expandIcon={<ExpandMore />} className="accordion__summary">
                <Typography align="center" className="typography">
                  {sprache('TEXT', 'KURZ', 'CODEEINGEBEN')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <form onSubmit={submitFreeFormOnSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          value={textFieldsState[TEXT_FIELD_NAMEN.GESAMT_CODE].wert}
                          onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.GESAMT_CODE, setTextFieldsState)}
                          error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.GESAMT_CODE].fehler}
                          helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.GESAMT_CODE].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                          fullWidth
                          color="primary"
                          variant="outlined"
                          label={sprache('FORM', 'LABEL', 'CODE')}
                          inputRef={codeInputRef}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button fullWidth type="submit" className="button__middle--red">
                          {sprache('BUTTON', 'TITEL', 'SENDEN')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} className="margin__top10 width__630">
            <Accordion className="accordion" expanded={accordionManuellOpenState} onChange={handleAccordionManuellOpenOnChange}>
              <AccordionSummary expandIcon={<ExpandMore />} className="accordion__summary">
                <Typography align="center" className="typography">
                  {sprache('TEXT', 'KURZ', 'MANUELLEINGEBEN')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <form onSubmit={submitPZNFieldFormOnClick}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          value={textFieldsState[TEXT_FIELD_NAMEN.PZN].wert}
                          onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.PZN, setTextFieldsState)}
                          error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PZN].fehler}
                          helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PZN].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                          fullWidth
                          color="primary"
                          variant="outlined"
                          label={sprache('FORM', 'LABEL', 'PZN')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].wert}
                          onChange={changeDateOnChange}
                          error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].fehler}
                          helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.VERFALLSDATUM].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                          fullWidth
                          color="primary"
                          variant="outlined"
                          label={sprache('FORM', 'LABEL', 'VERFALLSDATUM')}
                          placeholder="mm-jjjj"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button fullWidth type="submit" className="button__middle--red">
                          {sprache('BUTTON', 'TITEL', 'SENDEN')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid item xs={12} lg="auto" className="width__345">
          <Grid item xs={12}>
            {echoProduktVorhanden()}
          </Grid>
          <Grid item xs={12} className={`${echoProduktVorhanden() ? 'margin__top20' : ''} coWarenscanner__resultTable`}>
            <TableContainer>
              <Table size="medium">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography__600">{sprache('TABELLE', 'HEADER', 'PZN')}</Typography>
                    </TableCell>
                    <TableCell align="left" component="th">
                      <Typography className={`typography__${pznfehlerState ? 'red' : 'blue'} typography__600`}>{produktinfoState.pzn}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography__600">{sprache('TABELLE', 'HEADER', 'VERFALLSDATUM')}</Typography>
                    </TableCell>
                    <TableCell align="left" component="th">
                      <Typography className={`${verfallsdatumfehlerState ? 'typography__red' : 'blue'} typography__600`}>
                        {produktinfoState.verfallsdatum && datumGenerieren(produktinfoState.verfallsdatum).nurDatum}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {verfallsdatumfehlerState && (
                    <TableRow>
                      <TableCell align="left" component="th">
                        <Typography className="typography__600">{sprache('TABELLE', 'HEADER', 'GEWÜNSCHTVERFALLSDATUM')}</Typography>
                      </TableCell>
                      <TableCell align="left" component="th">
                        <Typography className="typography__red typography__600">{datumGenerieren(produktinfoState.gewuenschtVerfallsdatum).nurDatum}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography__600">{sprache('TABELLE', 'HEADER', 'SERIENNUMMER')}</Typography>
                    </TableCell>
                    <TableCell align="left" component="th">
                      <Typography className="typography__600">{produktinfoState.seriennummer}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {isLieferant ? (
            <Grid xs={12} className="coWarenscanner__versandpaket--fullwidth margin__top30">
              <CoVersandPaket objParent={objWarenScannerState.paketTemplate} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        {isLieferant ? (
          <>
            <Grid item xs={12} lg={4}>
              <CoPakettemplate objParent={objWarenScannerState.paketTemplate} />
            </Grid>
            <Grid item xs={12} className="coWarenscanner__versandpaket--smallwidth margin__top30">
              <CoVersandPaket objParent={objWarenScannerState.paketTemplate} />
            </Grid>
          </>
        ) : (
          ''
        )}
      </Grid>
    </CoSeitenstruktur>
  );
};

export default CoWarenscanner;
