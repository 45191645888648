function gotStream(stream, videoEl) {
  window.stream = stream;
  videoEl.srcObject = stream;
  return navigator.mediaDevices.enumerateDevices();
}

export function start(selectedDeviceId, videoEl) {
  if (window.stream) {
    window.stream.getTracks().forEach((track) => {
      track.stop();
    });
  }
  const constraints = {
    audio: false,
    video: { deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined }
  };
  return navigator.mediaDevices.getUserMedia(constraints).then((stream) => gotStream(stream, videoEl));
}
