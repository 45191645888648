// import -> dispatchActions
import dispatchActions from './dispatchActions';

// import -> actionHanlder
import { normalHandler, signOutHandler, singInHanlder } from './actionHandler';

const reducer = (state, action) => {
  switch (action.type) {
    case dispatchActions.denyNavigation:
      return normalHandler(state, 'canNavigate', false);
    case dispatchActions.allowStoppingTheCamera:
      return normalHandler(state, 'canCameraStop', true);
    case dispatchActions.denyStoppingTheCamera:
      return normalHandler(state, 'canCameraStop', false);
    case dispatchActions.signUserIn:
      return singInHanlder(state, action.payload);
    case dispatchActions.logUserOut:
      return signOutHandler(state);
    case dispatchActions.toggleOpenBackdrop:
      return normalHandler(state, 'backdropOpen', action.payload);
    case dispatchActions.removeCamera:
      return normalHandler(state, 'cameraAvailable', false);
    case dispatchActions.enableCamera:
      return normalHandler(state, 'cameraAvailable', true);
    case dispatchActions.hideCameraWarning:
      return normalHandler(state, 'cameraWarningEnabled', false);
    default:
      return state;
  }
};

export default reducer;
