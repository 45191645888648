import React from 'react';
import ReactDOM from 'react-dom';

// import -> context & reducer
import initialState from './reducer/initialState';
import reducer from './reducer/reducer';
import { StateProvider } from './reducer/StateProvider';

// import -> component
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
