import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import menuLinks from '../menuLinks.json';

// import -> common
import { sprache } from '../../Common/sprache/sprache';
import { stopVideo } from '../../Common/functions/kamera';

// import -> reducer
import useStateValue from '../../../reducer/StateProvider';

// import -> material-ui
import { Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const CoTopNav = () => {
  const location = useLocation();
  const history = useHistory();

  const [globalState, _] = useStateValue();
  const [responsiveState, setResponsiveState] = useState(false);

  const {
    authentication: {
      user: { access: userAccess }
    },
    canNavigate,
    canCameraStop
  } = globalState;

  // function ->
  const setResponsiveStateOnClick = () => {
    setResponsiveState((pre) => !pre);
  };

  // function ->
  const filterAccessableLinks = (link) => {
    const accessToLink = link.access;
    return accessToLink.includes(userAccess);
  };

  // function ->
  const isLinkActive = (linkUrl) => {
    const istLieferant = userAccess === 'LIEFERANT';
    let pathname = location.pathname;
    const noParameters = pathname.length === 0 || pathname === '/';
    pathname = noParameters && istLieferant ? '/warenscanner' : noParameters && !istLieferant ? '/arzneimittel' : pathname;
    return linkUrl === pathname;
  };

  // function ->
  const handleLinkOnClick = (event, url) => {
    if (!canNavigate) {
      event.preventDefault();
      if (canCameraStop) {
        stopVideo();
        history.push(url, location.state);
      }
      return;
    }
  };

  // function ->
  const buildLinksArray = (link, index) => (
    <Link onClick={(event) => handleLinkOnClick(event, link.url)} to={link.url} key={index} className={`coNavigation__topNavLink ${isLinkActive(link.url) ? 'coNavigation__topNavLink--active' : ''}`}>
      <Typography className="coNavigation__topNavTypography">{sprache('SEITE', 'TITEL', link.title)}</Typography>
    </Link>
  );

  // function ->
  const buildMenuLinks = () => {
    const accessableLinks = menuLinks.filter(filterAccessableLinks);
    return accessableLinks.map(buildLinksArray);
  };

  return (
    <div className={`coNavigation__topNav ${responsiveState ? 'responsive' : ''}`} onClick={setResponsiveStateOnClick}>
      <a className="coNavigation__topNavLink">
        <Typography className="coNavigation__topNavTypography">{sprache('TEXT', 'KURZ', 'NAVIGATION')}</Typography>
      </a>
      {buildMenuLinks()}
      <a className="coNavigation__topNavLink coNavigation__topNavIcon">
        <MenuIcon />
      </a>
    </div>
  );
};

export default CoTopNav;
