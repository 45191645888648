import React, { useState } from 'react';

// import -> common
import { changeTextFieldsOnChange } from '../../../Common/functions/form';
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoLieferantengruppenDialog from '../Gruppe/CoLieferantengruppenDialog';

const TEXT_FIELD_NAMEN = {
  NAME: 1,
  NUMMER: 2,
  STRASSE: 3,
  PLZ: 4,
  ORT: 5
};

// component -> React-root
const CoLieferantenErstellenBearbeitenDialog = ({ objParent }) => {
  const { name: stateName, nummer: stateNummer, strasse: stateStrasse, plz: statePlz, ort: stateOrt } = objParent.lieferantenErstellenBearbeitenDialogState?.lieferant;

  const [_, dispatch] = useStateValue();

  const [lieferantengruppenDialogState, setlieferantengruppeDialogState] = useState({ open: false, lieferant: {} });
  const [zuLieferantengruppeHinzufuegenState, setZuLieferantengruppeHinzufuegenState] = useState({});
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.NAME]: {
      wert: stateName ?? '',
      fehler: !Boolean(stateName?.length)
    },
    [TEXT_FIELD_NAMEN.NUMMER]: {
      wert: stateNummer ?? '',
      fehler: !Boolean(stateNummer?.length)
    },
    [TEXT_FIELD_NAMEN.STRASSE]: {
      wert: stateStrasse ?? '',
      fehler: !Boolean(stateStrasse?.length)
    },
    [TEXT_FIELD_NAMEN.PLZ]: {
      wert: statePlz ?? '',
      fehler: !Boolean(statePlz?.length)
    },
    [TEXT_FIELD_NAMEN.ORT]: {
      wert: stateOrt ?? '',
      fehler: !Boolean(stateOrt?.length)
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseLieferantenErstellenBearbeitenDialog(false);
  };

  // function ->
  const openOrCloseLieferantengruppenDialog = (shouldOpen) => {
    setlieferantengruppeDialogState({ open: shouldOpen, lieferant: {} });
  };

  // function ->
  const handleLieferantengruppeAusgewaehlt = (gruppe) => {
    setZuLieferantengruppeHinzufuegenState({ gruppe });
  };

  // function ->
  const zuLieferantengruppeHinzufuegen = (lieferantId) => {
    const { gruppe } = zuLieferantengruppeHinzufuegenState;
    const gruppeId = gruppe.id;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .post(`/lieferantengruppen/${gruppeId}/lieferanten/${lieferantId}`)
      .then(() => {
        closeDialog();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantenErstellenRequest = ({ name, nummer, strasse, plz, ort }) => {
    const requestBody = { name, nummer, strasse, plz, ort };

    objParent.authAxios
      .post('/lieferanten', requestBody)
      .then((response) => {
        if (!zuLieferantengruppeHinzufuegenState.gruppe?.id) {
          closeDialog();

          return;
        }
        const lieferantId = response.data.id;
        zuLieferantengruppeHinzufuegen(lieferantId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantenBearbeitenRequest = ({ name, nummer, strasse, plz, ort }) => {
    const requestBody = { name, nummer, strasse, plz, ort };
    const lieferantId = objParent.lieferantenErstellenBearbeitenDialogState.lieferant?.id;

    objParent.authAxios
      .put(`/lieferanten/${lieferantId}`, requestBody)
      .then(() => {
        closeDialog();
        objParent.setRerunEffectState?.((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantenErstellenBearbeitenOnSubmit = (event) => {
    event.preventDefault();

    const name = textFieldsState[TEXT_FIELD_NAMEN.NAME];
    const nummer = textFieldsState[TEXT_FIELD_NAMEN.NUMMER];
    const strasse = textFieldsState[TEXT_FIELD_NAMEN.STRASSE];
    const plz = textFieldsState[TEXT_FIELD_NAMEN.PLZ];
    const ort = textFieldsState[TEXT_FIELD_NAMEN.ORT];

    const namefehler = name.fehler;
    const nummerfehler = nummer.fehler;
    const strassefehler = strasse.fehler;
    const plzfehler = plz.fehler;
    const ortfehler = ort.fehler;

    if (namefehler || nummerfehler || strassefehler || plzfehler || ortfehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    const namewert = name.wert;
    const nummerwert = nummer.wert;
    const strassewert = strasse.wert;
    const plzwert = plz.wert;
    const ortwert = ort.wert;

    const params = { name: namewert, nummer: nummerwert, strasse: strassewert, plz: plzwert, ort: ortwert };

    if (objParent.lieferantenErstellenBearbeitenDialogState.erstellen) {
      lieferantenErstellenRequest(params);
    } else if (objParent.lieferantenErstellenBearbeitenDialogState.bearbeiten) {
      lieferantenBearbeitenRequest(params);
    }
  };

  const objLieferantenErstellen = {
    coLieferantengruppenDialog: {
      lieferantengruppenDialogState,
      handleLieferantengruppeAusgewaehlt,
      openOrCloseLieferantengruppenDialog,
      authAxios: objParent.authAxios
    }
  };

  return (
    <>
      {lieferantengruppenDialogState.open && <CoLieferantengruppenDialog objParent={objLieferantenErstellen.coLieferantengruppenDialog} />}
      <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={objParent.lieferantenErstellenBearbeitenDialogState.open}>
        <DialogTitle>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography className="typography__dialogTitle" align="left">
                {objParent.lieferantenErstellenBearbeitenDialogState.erstellen ? sprache('DIALOG', 'TITEL', 'LIEFERANTENERSTELLEN') : sprache('DIALOG', 'TITEL', 'LIEFERANTENBEARBEITEN')}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={lieferantenErstellenBearbeitenOnSubmit}>
            <Grid container spacing={3}>
              {objParent.lieferantenErstellenBearbeitenDialogState.bearbeiten && (
                <Grid item xs={12}>
                  <Typography className="typography__dialogSubtitle">
                    {sprache('TEXT', 'KURZ', 'LIEFERANT')}: {objParent.lieferantenErstellenBearbeitenDialogState.lieferant?.name}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.NAME].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.NAME, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  autoFocus
                  label={sprache('FORM', 'LABEL', 'NAME')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.NUMMER].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.NUMMER, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NUMMER].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NUMMER].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  label={sprache('FORM', 'LABEL', 'NUMMER')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.STRASSE].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.STRASSE, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.STRASSE].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.STRASSE].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  label={sprache('FORM', 'LABEL', 'STRAßE')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.PLZ].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.PLZ, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PLZ].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PLZ].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  label={sprache('FORM', 'LABEL', 'PLZ')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.ORT].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.ORT, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.ORT].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.ORT].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  label={sprache('FORM', 'LABEL', 'ORT')}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              {objParent.lieferantenErstellenBearbeitenDialogState.erstellen && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Button fullWidth className="button__middle--black" onClick={openOrCloseLieferantengruppenDialog.bind(null, true)}>
                      {sprache('BUTTON', 'TITEL', 'ZUGRUPPEHINZUFÜGEN')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{zuLieferantengruppeHinzufuegenState.gruppe?.name}</Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <Button fullWidth className="button__middle--blue" type="submit">
                  {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button fullWidth className="button__middle--red" onClick={closeDialog}>
                  {sprache('BUTTON', 'TITEL', 'ABBRECHEN')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoLieferantenErstellenBearbeitenDialog;
