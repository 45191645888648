import axios from 'axios';

// function ->
export const createAxiosInstance = (token) => {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      Authorization: `${token}`
    }
  });
  return authAxios;
};

// function ->
export const handleAndDownloadblobResponse = (data, blobType, name = 'qr_code') => {
  const blob = new Blob([data], { blobType });
  const url = URL.createObjectURL(blob);

  const type = blobType === 'octet/stream' ? 'zip' : blobType === '.csv' ? 'csv' : blobType === 'pdf' ? 'pdf' : 'png';
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.${type}`);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
};
