import React, { useEffect } from 'react';

// import -> common
import { sprache } from '../../sprache/sprache';

// import -> material-ui
import { Button, Snackbar } from '@material-ui/core/';

const CoLoeschenbestaetigung = ({ objParent, options }) => {
  // function ->
  const handleLoeschenAbgebrochen = () => {
    objParent.openOrCloseLoeschenbestaetigung(false);
  };

  // functio ->
  const handleLoeschenBestaetigt = () => {
    objParent.openOrCloseLoeschenbestaetigung(false);
    objParent.handleLoeschenBestaetigt();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={objParent.loeschenbestaetigungState}
      autoHideDuration={6000}
      message={options?.text ? options.text : sprache('TEXT', 'MELDUNG', 'SINDSIESICHER')}
      onClose={handleLoeschenAbgebrochen}
      action={
        <>
          <Button className="button__small--red" onClick={handleLoeschenBestaetigt}>
            {sprache('TEXT', 'KURZ', 'JA')}
          </Button>
          <Button className="button__small--blue" onClick={handleLoeschenAbgebrochen}>
            {sprache('TEXT', 'KURZ', 'NEIN')}
          </Button>
        </>
      }
    />
  );
};
export default CoLoeschenbestaetigung;
