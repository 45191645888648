import React, { useEffect, useState } from 'react';

// import -> common
import { handleAndDownloadblobResponse } from '../../../Common/functions/axios';
import { sprache } from '../../../Common/sprache/sprache';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../../../Common/constants/tableSorting';
import { showHeaderWithSorting, sortTable } from '../../../Common/functions/tableSorting';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Close, SearchOutlined } from '@material-ui/icons';

// import -> components
import CoLoeschenbestaetigung from '../../../Common/components/js/CoLoeschenbestaetigung';
import CoLieferantenErstellenBearbeitenDialog from './CoLieferantenErstellenBearbeitenDialog';
import CoLiefernatengruppenDialog from '../Gruppe/CoLieferantengruppenDialog';
import { filterTableOnChange } from '../../../Common/functions/tableFiltering';

// component -> React-root
const CoLieferantentabelle = ({ objParent }) => {
  const [globalState, dispatch] = useStateValue();

  const [lieferantenState, setLieferantenState] = useState([]);
  const [filteredLieferantenState, setFilteredLieferantenState] = useState([]);
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [filterInputState, setFilterInputState] = useState('');
  const [lieferantengruppenDialogState, setLieferantengruppenDialogState] = useState({ open: false, lieferant: {} });
  const [lieferantenErstellenBearbeitenDialogState, setLieferantenErstellenBearbeitenDialogState] = useState({ open: false, arzneimittel: {} });
  const [loeschenbestaetigungState, setLoeschenbestaetigungState] = useState(false);
  const [focusedLieferantenIdState, setFocuesLieferantenIdState] = useState(null);
  const tableHeaders = ['NAME', 'NUMMER', 'ZUGRUPPEHINZUFÜGEN', 'QRCODE', 'BEARBEITEN', 'LÖSCHEN'];
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.NAME]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.NUMMER]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseLieferantentabelle(false);
  };

  // function ->
  const lieferantenLoeschenOnClick = (lieferantId) => {
    setFocuesLieferantenIdState(lieferantId);
    setLoeschenbestaetigungState(true);
  };

  // function ->
  const openOrCloseLoeschenbestaetigung = (shouldOpen) => {
    setLoeschenbestaetigungState(shouldOpen);
  };

  // function ->
  const handleLoeschenBestaetigt = () => {
    const istInGruppe = Boolean(objParent.lieferantentabelleState.gruppeId);
    const requestUrl = istInGruppe ? `/lieferantengruppen/${objParent.lieferantentabelleState.gruppeId}/lieferanten/${focusedLieferantenIdState}` : `/lieferanten/${focusedLieferantenIdState}`;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .delete(requestUrl)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const openOrCloseLieferantenErstellenBearbeitenDialog = (shouldOpen, lieferant) => {
    setLieferantenErstellenBearbeitenDialogState({ open: shouldOpen, lieferant, bearbeiten: true });
  };

  // function ->
  const lieferantengruppeAuswaehlenOnClick = (lieferant) => {
    const lieferantId = lieferant.id;
    setFocuesLieferantenIdState(lieferantId);
    openOrCloseLieferantengruppenDialog(true, lieferant);
  };

  // function ->
  const openOrCloseLieferantengruppenDialog = (shouldOpen, lieferant) => {
    setLieferantengruppenDialogState({ open: shouldOpen, lieferant: lieferant ?? {} });
  };

  // function ->
  const handleLieferantengruppeAusgewaehlt = (gruppe) => {
    const gruppeId = gruppe.id;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .post(`/lieferantengruppen/${gruppeId}/lieferanten/${focusedLieferantenIdState}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const downloadEinLieferantCode = (lieferantId) => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get(`/lieferanten/${lieferantId}/qr-code`, { responseType: 'blob' })
      .then((response) => {
        const { data } = response;
        handleAndDownloadblobResponse(data, 'image/png');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantenHolen = () => {
    const istInGruppe = Boolean(objParent.lieferantentabelleState.gruppeId);
    const requestUrl = istInGruppe ? `/lieferantengruppen/${objParent.lieferantentabelleState.gruppeId}/lieferanten` : 'lieferanten';

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get(requestUrl)
      .then((response) => {
        const lieferanten = response.data;
        setLieferantenState(lieferanten);
        setFilteredLieferantenState(lieferanten);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    lieferantenHolen();

    return () => {
      isMounted = false;
    };
  }, [objParent.rerunEffectState]);

  const objLieferantentabelle = {
    coLoeschenbestaetigung: {
      loeschenbestaetigungState,
      openOrCloseLoeschenbestaetigung,
      handleLoeschenBestaetigt
    },
    coLieferantenErstellenBearbeitenDialog: {
      lieferantenErstellenBearbeitenDialogState,
      authAxios: objParent.authAxios,
      openOrCloseLieferantenErstellenBearbeitenDialog,
      setRerunEffectState: objParent.setRerunEffectState
    },
    coLiefernatengruppenDialog: {
      lieferantengruppenDialogState,
      openOrCloseLieferantengruppenDialog,
      authAxios: objParent.authAxios,
      handleLieferantengruppeAusgewaehlt
    }
  };

  // function ->
  const callFilterTableOnChange = (eventOrEventMock) => {
    filterTableOnChange(eventOrEventMock, setFilterInputState, lieferantenState, setFilteredLieferantenState, 'name', 'nummer');
    setRerunEffectState((pre) => !pre);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (filterInputState.length) {
      callFilterTableOnChange({ target: { value: filterInputState } });
    }

    return () => {
      isMounted = false;
    };
  }, [lieferantenState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, filteredLieferantenState, setFilteredLieferantenState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  return (
    <>
      {lieferantenErstellenBearbeitenDialogState.open && <CoLieferantenErstellenBearbeitenDialog objParent={objLieferantentabelle.coLieferantenErstellenBearbeitenDialog} />}
      {lieferantengruppenDialogState.open && <CoLiefernatengruppenDialog objParent={objLieferantentabelle.coLiefernatengruppenDialog} />}
      {loeschenbestaetigungState && <CoLoeschenbestaetigung objParent={objLieferantentabelle.coLoeschenbestaetigung} />}
      <Dialog fullWidth maxWidth="xl" onClose={closeDialog} open={objParent.lieferantentabelleState.open}>
        <DialogTitle>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography className="typography__dialogTitle" align="left">
                {sprache('DIALOG', 'TITEL', 'LIEFERANTEN')}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {objParent.lieferantentabelleState.name && (
              <Grid item xs={12}>
                <Typography className="typography__dialogSubtitle">
                  {sprache('TEXT', 'KURZ', 'LIEFERANTENGRUPPE')}: {objParent.lieferantentabelleState.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper>
                <TextField
                  fullWidth
                  value={filterInputState}
                  onChange={(event) => callFilterTableOnChange(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    )
                  }}
                  className="table__searchBar"
                  variant="standard"
                />
                <TableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow>
                        {tableHeaders.map((header, index) => {
                          return (
                            <TableCell align="center" component="th" key={`coLieferanten_liefernatentabelle_tableHeader_${index}`}>
                              {showHeaderWithSorting(header, sortingState, setSortingState, filteredLieferantenState, setFilteredLieferantenState)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLieferantenState.map((lieferant, index) => {
                        const { name, nummer, id } = lieferant;
                        return (
                          <TableRow key={`coLieferanten_lieferantentabelle_tableBody_row${index}`}>
                            <TableCell align="center">
                              <Typography>{name}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{nummer}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={lieferantengruppeAuswaehlenOnClick.bind(null, lieferant)}>
                                {sprache('BUTTON', 'TITEL', 'HINZUFÜGEN')}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Link component="button" className="button__link--blue" onClick={downloadEinLieferantCode.bind(null, id)}>
                                {sprache('BUTTON', 'TITEL', 'QRCODEHERUNTERLADEN')}
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={openOrCloseLieferantenErstellenBearbeitenDialog.bind(null, true, lieferant)}>
                                {sprache('BUTTON', 'TITEL', 'BEARBEITEN')}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={lieferantenLoeschenOnClick.bind(null, id)}>
                                {sprache('BUTTON', 'TITEL', 'LÖSCHEN')}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoLieferantentabelle;
