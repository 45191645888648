import React from 'react';

// import -> common
import { sprache } from '../../sprache/sprache';

// import -> material-ui
import { Container, Grid, Typography } from '@material-ui/core';

const CoSeitenstruktur = ({ children, seitentitel }) => {
  return (
    <Container className="container">
      <Grid container className="padding__top30 padding__left12">
        <Grid item xs={12} className="grid__einzigSeiteTitel">
          <Typography className="typography__einzigSeiteTitel">{sprache('SEITE', 'TITEL', seitentitel)}</Typography>
        </Grid>
        {children}
      </Grid>
    </Container>
  );
};

export default CoSeitenstruktur;
