import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

// import -> scss
import './App.scss';

// import -> common
import { logUserOut } from './components/Common/functions/user';
import { LOCALSTORAGE_ITEMS } from './components/Common/constants/localStorageItems';

// import -> material ui
import { Backdrop, CircularProgress, createMuiTheme, MuiThemeProvider } from '@material-ui/core';

// import -> reducer
import useStateValue from './reducer/StateProvider';

// import -> components
import CoAnmeldung from './components/Anmeldung/CoAnmeldung';
import CoAarzneimittel from './components/Arzneimittel/CoArzneimittel';
import CoEinstellungen from './components/Einstellungen/CoEinstellungen';
import CoLieferanten from './components/Lieferanten/CoLieferanten';
import CoNavigation from './components/Navigation/CoNavigation';
import CoNichtGefunden from './components/404/CoNichtGefunden';
import CoVerboten from './components/Verboten/CoVerboten';
import CoWarenscanner from './components/Warenscanner/CoWarenscanner';
import CoDownloadArtikelCSV from './components/DownloadArtikelCSV/CoDownloadArtikelCSV';
import CoSuperUser from './components/SuperUser/CoSuperUser';
import CoLieferuebersicht from './components/Lieferuebersicht/CoLieferuebersicht';
import CoBestelluebersicht from './components/Bestelluebersicht/Bestelluebersicht';
import { SnackbarProvider } from 'notistack';

// component -> build private Route for internal site navigation over url
const PrivateRoute = ({ access: routeAccess, component: Component, ...rest }) => {
  const location = useLocation();

  const [globalState, _] = useStateValue();

  // array -> if user from authenticateState is avaible, than pick given access array
  const benutzerAccess = globalState.authentication.user?.access || [];

  // boolean -> it is checked whether the private route access is included in the user access
  const isAccessAvaible = routeAccess.includes(benutzerAccess);

  return (
    <Route {...rest}>
      {globalState.authentication.auth && isAccessAvaible ? (
        <Component />
      ) : globalState.authentication.auth && !isAccessAvaible ? (
        <CoVerboten />
      ) : (
        <Redirect to={{ pathname: '/anmeldung', state: { from: location } }} />
      )}
    </Route>
  );
};

// component -> Redirect to anmeldung giving the location state
const RedirectToAnmeldung = () => {
  const location = useLocation();
  return <Redirect to={{ pathname: '/anmeldung', state: { from: location } }} />;
};

// component -> Redirect to Home if the user is authenticated
const RedirectToHomeIfAuth = ({ component: Component }) => {
  const [globalState, _] = useStateValue();
  const location = useLocation();
  return globalState.authentication.auth ? <Redirect to={{ pathname: '/', state: { from: location } }} /> : <Component />;
};

// react -> component
const App = () => {
  const history = useHistory();
  const [globalState, dispatch] = useStateValue();

  // function ->
  const mainFarbeSetzen = () => {
    const istAnmeldungseite = !globalState.authentication.auth;
    return istAnmeldungseite ? '#bb2205' : '#000';
  };

  // object -> material ui -> global style -> overwrite
  const theme = createMuiTheme({
    palette: {
      primary: { main: mainFarbeSetzen() }
    },
    typography: {
      fontFamily: '"Roboto",sans-serif'
    }
  });

  // function ->
  const checkAndHanldeUserTokenExpiration = () => {
    const userData = localStorage.getItem(LOCALSTORAGE_ITEMS.MEDICHECKAUTHENTICATION) ? JSON.parse(localStorage.getItem(LOCALSTORAGE_ITEMS.MEDICHECKAUTHENTICATION)) : null;
    if (!userData) return;

    const expirationDate = userData.expirationDate;
    if (expirationDate <= new Date().getTime()) {
      logUserOut(dispatch);
    }
  };

  // function ->
  const renderMainRouter = () => {
    const benutzerAccess = globalState.authentication.user?.access;
    const istLieferant = benutzerAccess === 'LIEFERANT';
    if (istLieferant) {
      return <PrivateRoute exact path="/" component={CoWarenscanner} access={['LIEFERANT', 'ADMIN', 'KUNDE', 'SUPER_USER']} />;
    }

    return <PrivateRoute exact path="/" component={CoAarzneimittel} access={['ADMIN', 'KUNDE', 'SUPER_USER']} />;
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    checkAndHanldeUserTokenExpiration();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={6000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Backdrop open={globalState.backdropOpen} className="backdrop">
          <CircularProgress color="inherit" />
        </Backdrop>
        <Router history={history}>
          {globalState.authentication.auth && <CoNavigation />}
          <Switch>
            <Route exact path="/anmeldung">
              <RedirectToHomeIfAuth component={CoAnmeldung} />
            </Route>
            <Route exact path="/download/:pass">
              <CoDownloadArtikelCSV />
            </Route>
            {renderMainRouter()}
            <PrivateRoute exact path="/user-verwaltung" component={CoSuperUser} access={['ADMIN']} />
            <PrivateRoute exact path="/arzneimittel" component={CoAarzneimittel} access={['ADMIN', 'KUNDE', 'SUPER_USER']} />
            <PrivateRoute exact path="/einstellungen" component={CoEinstellungen} access={['LIEFERANT', 'ADMIN', 'KUNDE', 'SUPER_USER']} />
            {/* <PrivateRoute exact path="/kunde_anlegen" component={CoKundeAnlegen} access={['ADMIN']} /> */}
            <PrivateRoute exact path="/lieferanten" component={CoLieferanten} access={['ADMIN', 'SUPER_USER']} />
            <PrivateRoute exact path="/warenscanner" component={CoWarenscanner} access={['LIEFERANT', 'ADMIN', 'KUNDE', 'SUPER_USER']} />
            <PrivateRoute exact path="/lieferuebersicht" component={CoLieferuebersicht} access={['LIEFERANT', 'ADMIN', 'SUPER_USER']} />
            <PrivateRoute exact path="/bestelluebersicht" component={CoBestelluebersicht} access={['LIEFERANT', 'ADMIN', 'SUPER_USER']} />
            {globalState.authentication.auth ? <Route path="*" component={CoNichtGefunden} /> : <RedirectToAnmeldung />}
          </Switch>
        </Router>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default App;
