const dispatchActions = {
  denyNavigation: 1,
  allowStoppingTheCamera: 2,
  denyStoppingTheCamera: 3,
  signUserIn: 4,
  logUserOut: 5,
  toggleOpenBackdrop: 6,
  removeCamera: 7,
  enableCamera: 8,
  hideCameraWarning: 10
};

export default dispatchActions;
