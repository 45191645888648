import React, { useEffect, useState } from 'react';

// import -> common
import { createAxiosInstance } from '../Common/functions/axios';

// import -> reducer
import dispatchActions from '../../reducer/dispatchActions';
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';

// import -> components
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';
import CoSuperUserErstellenDialog from './Append/CoSuperUserErstellenDialog';
import CoSuperUserTabelle from './Append/CoSuperUserTabelle';

const CoSuperUser = () => {
  const [globalState, dispatch] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [superUserState, setSuperUserState] = useState([]);
  const [superUserErstellenDialogState, setSuperUsersErstellenDialogState] = useState({ open: false });
  const [rerunEffectState, setRerunEffectState] = useState(false);

  // function ->
  const openOrCloseSuperUsersErstellenDialog = (shouldOpen) => {
    setSuperUsersErstellenDialogState({ open: shouldOpen, erstellen: true, user: {} });
  };

  // function ->
  const anfrageSuperUsersErfolgHandeln = (listen) => {
    setSuperUserState(listen);
  };

  // function ->
  const superUsersHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });
    authAxios
      .get('/super-user')
      .then((response) => {
        const superUsers = response.data;
        anfrageSuperUsersErfolgHandeln(superUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) {
      return;
    }

    superUsersHolen();

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objSuperUsers = {
    coSuperUsersErstellenDialog: {
      superUserErstellenDialogState,
      authAxios,
      openOrCloseSuperUsersErstellenDialog,
      setRerunEffectState
    },
    coSuperUsersTabelle: {
      superUserState,
      setSuperUserState,
      authAxios,
      rerunEffectState,
      setRerunEffectState
    }
  };

  return (
    <>
      {superUserErstellenDialogState.open && <CoSuperUserErstellenDialog objParent={objSuperUsers.coSuperUsersErstellenDialog} />}
      <CoSeitenstruktur seitentitel="USERVERWALTUNG">
        <Grid item container spacing={3} className="margin__top30">
          <Grid item xs={12} md="auto">
            <Button startIcon={<Add />} className="button__middle--blue" onClick={openOrCloseSuperUsersErstellenDialog.bind(null, true)}>
              Neuen USER anlegen
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CoSuperUserTabelle objParent={objSuperUsers.coSuperUsersTabelle} />
          </Grid>
        </Grid>
      </CoSeitenstruktur>
    </>
  );
};

export default CoSuperUser;
