import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../Common/sprache/sprache';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../../Common/constants/tableSorting';
import { showHeaderWithSorting, sortTable } from '../../Common/functions/tableSorting';
import { filterTableOnChange } from '../../Common/functions/tableFiltering';

// import -> reducer
import useStateValue from '../../../reducer/StateProvider';
import dispatchActions from '../../../reducer/dispatchActions';

// import -> material ui
import { Button, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

// import -> components
import CoLoeschenbestaetigung from '../../Common/components/js/CoLoeschenbestaetigung';

// component -> React-root
const CoSuperUserTabelle = ({ objParent }) => {
  const [globalState, dispatch] = useStateValue();

  const [filterInputState, setFilterInputState] = useState('');
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [focusedUserIdState, setFocusedUserIdState] = useState(null);
  const [superUserState, setSuperUserState] = useState([]);
  const [filteredSuperUserState, setFilteredSuperUserState] = useState([]);
  const [bestaetigungState, setBestaetigungState] = useState(false);
  const [bestaetigungTextState, setBestaetigungTextState] = useState('');
  const [actionState, setActionState] = useState('');
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.NAME]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });

  const tabellenheader = ['NAME', 'STATUS', 'SPERREN', 'LÖSCHEN'];

  // function ->
  const openOrCloseBestaetigung = (shouldOpen) => {
    setBestaetigungState(shouldOpen);
  };

  // function ->
  const makeActionToPaketAndOpenWarning = (action, userId) => {
    setActionState(action);
    setFocusedUserIdState(userId);
    openOrCloseBestaetigung(true);
    if (action === 'userLoeschen') {
      setBestaetigungTextState('Wollen den Benutzer wirklich löschen?');
    } else if (action === 'userSperren') {
      setBestaetigungTextState('Wollen Sie den Benutzer wirklich sperren?');
    } else if (action === 'userEntsperren') {
      setBestaetigungTextState('Wollen Sie den Benutzer wirklich entsperren?');
    }
  };

  // function ->
  const handleBestaetigung = () => {
    if (actionState === 'userLoeschen') {
      userLoeschen();
    } else if (actionState === 'userSperren') {
      userSperren();
    } else if (actionState === 'userEntsperren') {
      userEntsperren();
    }
  };

  // function ->
  const userLoeschen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .delete(`/super-user/${focusedUserIdState}`)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const userSperren = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get(`/super-user/${focusedUserIdState}/sperren`)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const userEntsperren = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });
    objParent.authAxios
      .get(`/super-user/${focusedUserIdState}/entsperren`)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  const objSuerUserTabelle = {
    coLoeschenbestaetigung: {
      loeschenbestaetigungState: bestaetigungState,
      openOrCloseLoeschenbestaetigung: openOrCloseBestaetigung,
      handleLoeschenBestaetigt: handleBestaetigung
    }
  };

  // function ->
  const callFilterTableOnChange = (eventOrEventMock) => {
    filterTableOnChange(eventOrEventMock, setFilterInputState, superUserState, setFilteredSuperUserState, 'name');
    setRerunEffectState((pre) => !pre);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    const superUser = [...objParent.superUserState];
    setSuperUserState(superUser);
    setFilteredSuperUserState(superUser);

    return () => {
      isMounted = false;
    };
  }, [objParent.superUserState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    callFilterTableOnChange({ target: { value: filterInputState } });

    return () => {
      isMounted = false;
    };
  }, [superUserState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, filteredSuperUserState, setFilteredSuperUserState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  return (
    <>
      {bestaetigungState && <CoLoeschenbestaetigung objParent={objSuerUserTabelle.coLoeschenbestaetigung} options={{ text: bestaetigungTextState }} />}
      <Paper>
        <TextField
          fullWidth
          value={filterInputState}
          onChange={(event) => callFilterTableOnChange(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            )
          }}
          className="table__searchBar"
          variant="standard"
        />
        <TableContainer>
          <Table size="medium" stickyHeader>
            <TableHead>
              <TableRow className="table__title">
                {tabellenheader.map((header, index) => {
                  return (
                    <TableCell align="center" component="th" key={`coSuperUserTable_tabllenheader_${index}`}>
                      {showHeaderWithSorting(header, sortingState, setSortingState, filteredSuperUserState, setFilteredSuperUserState)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSuperUserState.map((user, index) => {
                const { name, id, locked } = user;
                return (
                  <TableRow key={`coSuperUserTable_tableBody_tableRow_${index}`}>
                    <TableCell align="center">
                      <Typography>{name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{locked ? 'Gesperrt' : 'Entsperrt'}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {locked ? (
                        <Button className="button__middle--black" onClick={makeActionToPaketAndOpenWarning.bind(null, 'userEntsperren', id)}>
                          Entsperren
                        </Button>
                      ) : (
                        <Button className="button__middle--black" onClick={makeActionToPaketAndOpenWarning.bind(null, 'userSperren', id)}>
                          Sperren
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Button className="button__middle--black" onClick={makeActionToPaketAndOpenWarning.bind(null, 'userLoeschen', id)}>
                        {sprache('BUTTON', 'TITEL', 'LÖSCHEN')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default CoSuperUserTabelle;
