import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import { Close, SearchOutlined } from '@material-ui/icons';
import { filterTableOnChange } from '../../../Common/functions/tableFiltering';

// component -> React-root
const CoLieferantengruppenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();
  const [lieferantengruppenState, setLieferantengruppenState] = useState([]);
  const [filteredLieferantengruppenState, setFilteredLieferantengruppenState] = useState([]);
  const [filterInputState, setFilterInputState] = useState('');

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseLieferantengruppenDialog(false);
  };

  // function ->
  const handleLieferantengruppeAusgewaehlt = (gruppeId) => {
    objParent.openOrCloseLieferantengruppenDialog(false);
    objParent.handleLieferantengruppeAusgewaehlt(gruppeId);
  };

  // function ->
  const lieferantengruppenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get('/lieferantengruppen')
      .then((response) => {
        const gruppen = response.data;
        setLieferantengruppenState(gruppen);
        setFilteredLieferantengruppenState(gruppen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    lieferantengruppenHolen();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={objParent.lieferantengruppenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {sprache('DIALOG', 'TITEL', 'LIEFERANTENGRUPPEN')}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography__dialogSubtitle">
              {sprache('TEXT', 'KURZ', 'ARZNEIMITTELLISTE')}: {objParent.lieferantengruppenDialogState.arzneimittellistenname}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{sprache('TEXT', 'MELDUNG', 'GRUPPEAUSWÄHLEN')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={filterInputState}
              onChange={(event) => filterTableOnChange(event, setFilterInputState, lieferantengruppenState, setFilteredLieferantengruppenState, 'name')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                )
              }}
              className="table__searchBar"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="medium" stickyHeader>
                <TableBody>
                  {filteredLieferantengruppenState.map((gruppe, index) => {
                    const { id, name } = gruppe;
                    return (
                      <TableRow key={`coArzneimittel_lieferantengruppen_dialog_tableBody_row${index}`}>
                        <TableCell align="left" className="table__cell--hover" onClick={handleLieferantengruppeAusgewaehlt.bind(null, id)}>
                          <Typography>{name}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoLieferantengruppenDialog;
