import React, { useState } from 'react';

// import -> material-ui
import { Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const browsersList = [
  {
    label: 'Chrome',
    value: 'chrome',
    icon: 'chrome.png',
    instructions: [
      'Klicken Sie rechts oben auf das Dreipunkt-Menü > "Einstellungen".',
      'Klicken Sie auf "Datenschutz und Sicherheit" > "Website-Einstellungen" > "Kamera".',
      'Wählen Sie die gewünschte Standardeinstellung aus.'
    ]
  },
  {
    label: 'Chrome auf iPhone oder iPad',
    value: 'chrome_iphone',
    icon: 'chrome.png',
    instructions: [
      'Rufen Sie eine Website auf, für die Sie Ihre Kamera verwenden möchten.',
      'Tippen Sie auf Zulassen oder Blockieren, wenn Sie dazu aufgefordert werden.',
      'Wenn Sie den Zugriff auf die Kamera deaktiviert haben, können Sie die entsprechenden Berechtigungen in den Geräteeinstellungen aktivieren.'
    ]
  },
  {
    label: 'Opera',
    value: 'opera',
    icon: 'opera.png',
    instructions: [
      'Gehen Sie zu "Einstellungen" ("Präferenzen" auf Mac).',
      'Gehen Sie zu "Sicherheit und Datenschutz" und dann Klicken Sie auf "Webseite-Einstellungen" > "Kamera".',
      'Wählen Sie die gewünschte Standardeinstellung aus.'
    ]
  },
  {
    label: 'Mozilla Firefox',
    value: 'firefox',
    icon: 'firefox.png',
    instructions: [
      'Klicken Sie rechts oben auf das Dreistrich-Menü > "Einstellungen".',
      'Gehen Sie zu "Sicherheit und Datenschutz".',
      'Dort finden Sie neben "Kamera" den Button "Einstellungen". Drucken Sie auf diesen, öffnet sich das Fenster "Einstellungen – Kamerazugriff".',
      'Wählen Sie die gewünschte Standardeinstellung aus.',
      'Um den Vorgang zu beenden, klicken Sie auf "Änderungen speichern".'
    ]
  },
  {
    label: 'Safari auf Mac',
    value: 'safari_mac',
    icon: 'safari.png',
    instructions: ['Wählen Sie in der App "Safari" auf dem Mac "Safari" > "Einstellungen".', 'Wählen Sie die Optionen "Kamera"', 'Wählen Sie die gewünschte Standardeinstellung aus.']
  },
  {
    label: 'Safari auf IOS',
    value: 'safari_ios',
    icon: 'safari.png',
    instructions: [
      'Rufen Sie eine Website auf, für die Sie Ihre Kamera verwenden möchten.',
      'Tippen Sie auf Zulassen oder Blockieren, wenn Sie dazu aufgefordert werden.',
      'Wenn Sie den Zugriff auf die Kamera deaktiviert haben, können Sie die entsprechenden Berechtigungen in den Geräteeinstellungen aktivieren.'
    ]
  }
];

// component -> React-root
const CoBerechtigungleitfadenDialog = ({ objParent }) => {
  const [selectedBrowserState, setSelectedBrowserState] = useState('');

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseBerechtigungleitfadenDialog(false);
  };

  // function ->
  const handleBroswerChange = (event) => {
    setSelectedBrowserState(event.target.value);
  };

  // function ->
  const findSelectedBrowser = () => {
    const browser = browsersList.find((browser) => browser.value === selectedBrowserState);
    return browser;
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={objParent.berechtigungleitfadenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              Kameraberechtigung ändern
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth className="coQrScanner__browserFormControl">
              <InputLabel id="coBerechtigungleitfadenDialog__browserSelect">Welchen Browser nutzen Sie?</InputLabel>
              <Select value={selectedBrowserState} onChange={handleBroswerChange} labelId="coBerechtigungleitfadenDialog__browserSelect" label="Welchen Browser nutzen Sie?">
                {browsersList.map((browser, index) => {
                  return (
                    <MenuItem key={`browser_option_${index}`} value={browser.value}>
                      <img width={30} src={`/assets/icons/${browser.icon}`} alt={browser.label} /> &nbsp;&nbsp;
                      {browser.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {selectedBrowserState ? (
            <>
              <Grid item xs={12} className="margin__top30">
                <Typography className="typography typography__600">Schritte:</Typography>
              </Grid>
              <Grid item xs={12} className="margin__top10">
                <ul>
                  {findSelectedBrowser().instructions.map((instruction, index) => (
                    <li style={{ marginBottom: 5, fontWeight: 100 }} key={`browser_instruction_${index}`}>
                      <Typography className="typography">{instruction}</Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            </>
          ) : (
            ''
          )}
        </Grid>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default CoBerechtigungleitfadenDialog;
