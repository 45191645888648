import React, { useEffect, useState } from 'react';

// import -> common
import { datumGenerieren } from '../../../Common/functions/datum';
import { sprache } from '../../../Common/sprache/sprache';
import { showHeaderWithSorting, sortTable } from '../../../Common/functions/tableSorting';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../../../Common/constants/tableSorting';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Close, SearchOutlined } from '@material-ui/icons';

// import -> components
import CoLoeschenbestaetigung from '../../../Common/components/js/CoLoeschenbestaetigung';
import CoArzneimittelErstellenBearbeitenDialog from './CoArzneimittelErstellenBearbeitenDialog';
import CoArzneimittellistenDialog from '../Liste/CoArzneimittellistenDialog';
import { filterTableOnChange } from '../../../Common/functions/tableFiltering';

// component -> React-root
const CoArzneimitteltabelle = ({ objParent }) => {
  const listenmittel = Boolean(objParent.arzneimitteltabelleState.listId);
  const [globalState, dispatch] = useStateValue();
  const [arzneimittelState, setArzneimittelState] = useState([]);
  const [filteredArzneimittelState, setFilteredArzneimittelState] = useState([]);
  const [filterInputState, setFilterInputState] = useState('');
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [arzneimittellistenDialogState, setArzneimittellistenDialogState] = useState({ open: false, arzneimittel: {} });
  const [arzneimittelErstellenBearbeitenDialogState, setArzneimittelErstellenBearbeitenDialogState] = useState({ open: false, arzneimittel: {} });
  const [loeschenbestaetigungState, setLoeschenbestaetigungState] = useState(false);
  const [focusedArzneimittelIdState, setFocuesArzneimittelIdState] = useState(null);
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.BEZEICHNUNG]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.PZN]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.VERFALLSDATUM]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });
  const tableHeaders = listenmittel ? ['PZN', 'BEZEICHNUNG', 'VERFALLSDATUM', 'ZULISTEHINZUFÜGEN', 'BEARBEITEN', 'LÖSCHEN'] : ['PZN', 'BEZEICHNUNG', 'ZULISTEHINZUFÜGEN', 'BEARBEITEN', 'LÖSCHEN'];

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArzneimitteltabelle(false);
  };

  // function ->
  const arzneimittelLoeschenOnClick = (mittelId) => {
    setFocuesArzneimittelIdState(mittelId);
    setLoeschenbestaetigungState(true);
  };

  // function ->
  const openOrCloseLoeschenbestaetigung = (shouldOpen) => {
    setLoeschenbestaetigungState(shouldOpen);
  };

  // function ->
  const handleLoeschenBestaetigt = () => {
    const requestUrl = listenmittel ? `/arzneimittel-listen/${objParent.arzneimitteltabelleState.listId}/arzneimittel/${focusedArzneimittelIdState}` : `/arzneimittel/${focusedArzneimittelIdState}`;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .delete(requestUrl)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const openOrCloseArzneimittelErstellenBearbeitenDialog = (shouldOpen, arzneimittel) => {
    setArzneimittelErstellenBearbeitenDialogState({ open: shouldOpen, arzneimittel, bearbeiten: true });
  };

  // function ->
  const arzneimittellisteAuswaehlenOnClick = (arzneimittel) => {
    const arzneimittelId = arzneimittel.id;
    setFocuesArzneimittelIdState(arzneimittelId);
    openOrCloseArzneimittellistenDialog(true, arzneimittel);
  };

  // function ->
  const openOrCloseArzneimittellistenDialog = (shouldOpen, arzneimittel) => {
    setArzneimittellistenDialogState({ open: shouldOpen, arzneimittel: arzneimittel ?? {} });
  };

  // function ->
  const handleArzneimittellisteAusgewaehlt = (liste, verfallsdatum) => {
    const listeId = liste.id;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .post(`/arzneimittel-listen/${listeId}/arzneimittel/${focusedArzneimittelIdState}`, {
        verfallsdatum: verfallsdatum
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const handleArzneimittelHolenEfolg = (arzneimittel) => {
    const newArzneimittelState = [];

    arzneimittel.forEach((mittel) => {
      const objArzneimittel = {};
      objArzneimittel.verfallsdatum = mittel.verfallsdatum;
      objArzneimittel.id = mittel.arzneimittel.id;
      objArzneimittel.bezeichnung = mittel.arzneimittel.bezeichnung;
      objArzneimittel.pzn = mittel.arzneimittel.pzn;
      newArzneimittelState.push(objArzneimittel);
    });

    setArzneimittelState(newArzneimittelState);
    setFilteredArzneimittelState(newArzneimittelState);
  };

  // function ->
  const arzneimittelHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    const requestUrl = listenmittel ? `/arzneimittel-listen/${objParent.arzneimitteltabelleState.listId}/arzneimittel` : 'arzneimittel';
    objParent.authAxios
      .get(requestUrl)
      .then((response) => {
        const arzneimittel = response.data;
        if (listenmittel) {
          handleArzneimittelHolenEfolg(arzneimittel);
        } else {
          setArzneimittelState(arzneimittel);
          setFilteredArzneimittelState(arzneimittel);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const callFilterTableOnChange = (eventOrEventMock) => {
    filterTableOnChange(eventOrEventMock, setFilterInputState, arzneimittelState, setFilteredArzneimittelState, 'bezeichnung', 'pzn', listenmittel ? 'verfallsdatum' : 'pzn');
    setRerunEffectState((pre) => !pre);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (filterInputState.length) {
      callFilterTableOnChange({ target: { value: filterInputState } });
    }

    return () => {
      isMounted = false;
    };
  }, [arzneimittelState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, filteredArzneimittelState, setFilteredArzneimittelState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    arzneimittelHolen();

    return () => {
      isMounted = false;
    };
  }, [objParent.rerunEffectState]);

  const objArzneimitteltabelle = {
    coLoeschenbestaetigung: {
      loeschenbestaetigungState,
      openOrCloseLoeschenbestaetigung,
      handleLoeschenBestaetigt
    },
    coArzneimittelErstellenBearbeitenDialog: {
      arzneimittelErstellenBearbeitenDialogState,
      authAxios: objParent.authAxios,
      openOrCloseArzneimittelErstellenBearbeitenDialog,
      setRerunEffectState: objParent.setRerunEffectState
    },
    coArzneimittellistenDialog: {
      arzneimittellistenDialogState,
      openOrCloseArzneimittellistenDialog,
      authAxios: objParent.authAxios,
      handleArzneimittellisteAusgewaehlt
    }
  };

  return (
    <>
      {arzneimittelErstellenBearbeitenDialogState.open && <CoArzneimittelErstellenBearbeitenDialog objParent={objArzneimitteltabelle.coArzneimittelErstellenBearbeitenDialog} />}
      {arzneimittellistenDialogState.open && <CoArzneimittellistenDialog objParent={objArzneimitteltabelle.coArzneimittellistenDialog} />}
      {loeschenbestaetigungState && <CoLoeschenbestaetigung objParent={objArzneimitteltabelle.coLoeschenbestaetigung} />}
      <Dialog fullWidth maxWidth="xl" onClose={closeDialog} open={objParent.arzneimitteltabelleState.open}>
        <DialogTitle>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography className="typography__dialogTitle" align="left">
                {sprache('DIALOG', 'TITEL', 'ARZNEIMITTEL')}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {objParent.arzneimitteltabelleState.name && (
              <Grid item xs={12}>
                <Typography className="typography__dialogSubtitle">
                  {sprache('TEXT', 'KURZ', 'ARZNEIMITTELLISTE')}: {objParent.arzneimitteltabelleState.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper>
                <TextField
                  fullWidth
                  value={filterInputState}
                  onChange={(event) => callFilterTableOnChange(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    )
                  }}
                  className="table__searchBar"
                  variant="standard"
                />
                <TableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow>
                        {tableHeaders.map((header, index) => {
                          return (
                            <TableCell align="center" component="th" key={`coArzneimittel_arzneimitteltabelle_tableHeader_${index}`}>
                              {showHeaderWithSorting(header, sortingState, setSortingState, filteredArzneimittelState, setFilteredArzneimittelState)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredArzneimittelState.map((mittel, index) => {
                        const { bezeichnung, pzn, verfallsdatum, id } = mittel;
                        return (
                          <TableRow key={`coArzneimittel_arzneimitteltabelle_tableBody_row${index}`}>
                            <TableCell align="center">
                              <Typography>{pzn}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{bezeichnung}</Typography>
                            </TableCell>
                            {listenmittel && (
                              <TableCell align="center">
                                <Typography>{verfallsdatum}</Typography>
                              </TableCell>
                            )}
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={arzneimittellisteAuswaehlenOnClick.bind(null, mittel)}>
                                {sprache('BUTTON', 'TITEL', 'HINZUFÜGEN')}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={openOrCloseArzneimittelErstellenBearbeitenDialog.bind(null, true, mittel)}>
                                {sprache('BUTTON', 'TITEL', 'BEARBEITEN')}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button className="button__middle--black" onClick={arzneimittelLoeschenOnClick.bind(null, id)}>
                                {sprache('BUTTON', 'TITEL', 'LÖSCHEN')}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoArzneimitteltabelle;
