// import -> common
import { LOCALSTORAGE_ITEMS } from '../constants/localStorageItems';

// import -> reducer
import dispatchActions from '../../../reducer/dispatchActions';

// funtion ->
export const logUserOut = (dispatch) => {
  dispatch({ type: dispatchActions.logUserOut });
  localStorage.removeItem(LOCALSTORAGE_ITEMS.MEDICHECKAUTHENTICATION);
  window.location.reload();
};

// function ->
export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((char) => {
        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
