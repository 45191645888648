import React, { useState } from 'react';

// import -> common
import { changeTextFieldsOnChange } from '../../../Common/functions/form';
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoArzneimittellistenDialog from '../Liste/CoArzneimittellistenDialog';

const TEXT_FIELD_NAMEN = {
  PZN: 1,
  BEZEICHNUNG: 2
};

// component -> React-root
const CoArzneimittelErstellenBearbeitenDialog = ({ objParent }) => {
  const { pzn: statePzn, bezeichnung: stateBezeichnung } = objParent.arzneimittelErstellenBearbeitenDialogState.arzneimittel;

  const [_, dispatch] = useStateValue();
  const [arzneimittellistenDialogState, setarzneimittellistenDialogState] = useState({ open: false, arzneimittel: {} });
  const [zuArzneimittellisteHinzufuegenState, setZuArzneimittellisteHinzufuegenState] = useState({});
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.PZN]: {
      wert: statePzn ?? '',
      fehler: !Boolean(statePzn?.length)
    },
    [TEXT_FIELD_NAMEN.BEZEICHNUNG]: {
      wert: stateBezeichnung ?? '',
      fehler: !Boolean(stateBezeichnung?.length)
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArzneimittelErstellenBearbeitenDialog(false);
  };

  // function ->
  const openOrCloseArzneimittellistenDialog = (shouldOpen, arzneimittel) => {
    setarzneimittellistenDialogState({ open: shouldOpen, arzneimittel: arzneimittel ?? {} });
  };

  // function ->
  const handleArzneimittellisteAusgewaehlt = (liste, verfallsdatum) => {
    setZuArzneimittellisteHinzufuegenState({ liste, verfallsdatum });
  };

  // function ->
  const zuArzneimittellisteHinzufuegen = (arzneimittelId) => {
    const { liste, verfallsdatum } = zuArzneimittellisteHinzufuegenState;
    const listeId = liste.id;
    const requestBody = {
      verfallsdatum
    };

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .post(`/arzneimittel-listen/${listeId}/arzneimittel/${arzneimittelId}`, requestBody)
      .then(() => {
        closeDialog();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const arzneimittelErstellenRequest = (pzn, bezeichnung) => {
    const requestBody = { pzn, bezeichnung };
    objParent.authAxios
      .post('/arzneimittel', requestBody)
      .then((response) => {
        if (!zuArzneimittellisteHinzufuegenState.liste?.id) {
          closeDialog();
          return;
        }
        const arzneimittelId = response.data.id;
        zuArzneimittellisteHinzufuegen(arzneimittelId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const arzneimittelBearbeitenRequest = (pzn, bezeichnung) => {
    const requestBody = { pzn, bezeichnung };
    const arzneimittelId = objParent.arzneimittelErstellenBearbeitenDialogState.arzneimittel?.id;
    objParent.authAxios
      .put(`/arzneimittel/${arzneimittelId}`, requestBody)
      .then(() => {
        closeDialog();
        objParent.setRerunEffectState?.((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const arzneimittelErstellenBearbeitenOnSubmit = (event) => {
    event.preventDefault();

    const pzn = textFieldsState[TEXT_FIELD_NAMEN.PZN];
    const bezeichnung = textFieldsState[TEXT_FIELD_NAMEN.BEZEICHNUNG];

    const pznfehler = pzn.fehler;
    const bezeichnungfehler = bezeichnung.fehler;

    if (pznfehler || bezeichnungfehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    const pznwert = pzn.wert;
    const bezeichnungwert = bezeichnung.wert;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    if (objParent.arzneimittelErstellenBearbeitenDialogState.erstellen) {
      arzneimittelErstellenRequest(pznwert, bezeichnungwert);
    } else if (objParent.arzneimittelErstellenBearbeitenDialogState.bearbeiten) {
      arzneimittelBearbeitenRequest(pznwert, bezeichnungwert);
    }
  };

  const objArzneimittelErstellen = {
    coArzneimittellistenDialog: {
      arzneimittellistenDialogState,
      handleArzneimittellisteAusgewaehlt,
      openOrCloseArzneimittellistenDialog,
      authAxios: objParent.authAxios
    }
  };

  return (
    <>
      {arzneimittellistenDialogState.open && <CoArzneimittellistenDialog objParent={objArzneimittelErstellen.coArzneimittellistenDialog} />}
      <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={objParent.arzneimittelErstellenBearbeitenDialogState.open}>
        <DialogTitle>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography className="typography__dialogTitle" align="left">
                {objParent.arzneimittelErstellenBearbeitenDialogState.erstellen ? sprache('DIALOG', 'TITEL', 'ARZNEIMITTELERSTELLEN') : sprache('DIALOG', 'TITEL', 'ARZNEIMITTELBEARBEITEN')}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={arzneimittelErstellenBearbeitenOnSubmit}>
            <Grid container spacing={3}>
              {objParent.arzneimittelErstellenBearbeitenDialogState.bearbeiten && (
                <Grid item xs={12}>
                  <Typography className="typography__dialogSubtitle">
                    {sprache('TEXT', 'KURZ', 'ARZNEIMITTEL')}: {objParent.arzneimittelErstellenBearbeitenDialogState.arzneimittel?.bezeichnung}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.PZN].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.PZN, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PZN].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PZN].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  autoFocus
                  label={sprache('FORM', 'LABEL', 'PZN')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={textFieldsState[TEXT_FIELD_NAMEN.BEZEICHNUNG].wert}
                  onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.BEZEICHNUNG, setTextFieldsState)}
                  error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.BEZEICHNUNG].fehler}
                  helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.BEZEICHNUNG].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  label={sprache('FORM', 'LABEL', 'BEZEICHNUNG')}
                />
              </Grid>

              {objParent.arzneimittelErstellenBearbeitenDialogState.erstellen && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Button fullWidth className="button__middle--black" onClick={openOrCloseArzneimittellistenDialog.bind(null, true)}>
                      {sprache('BUTTON', 'TITEL', 'ZULISTEHINZUFÜGEN')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{zuArzneimittellisteHinzufuegenState.liste?.name}</Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <Button fullWidth className="button__middle--blue" type="submit">
                  {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button fullWidth className="button__middle--red" onClick={closeDialog}>
                  {sprache('BUTTON', 'TITEL', 'ABBRECHEN')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoArzneimittelErstellenBearbeitenDialog;
