// funciton ->
export const datumGenerieren = (zeitstempel) => {
  // statment -> if not zeitstempel then genrate current date
  const date = zeitstempel ? new Date(zeitstempel) : new Date();

  const tag = date.getDate().toString().padStart(2, '0');
  const monat = (date.getMonth() + 1).toString().padStart(2, '0');
  const jahr = date.getFullYear().toString().padStart(4, '0');
  const stunde = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const sekunde = date.getSeconds().toString().padStart(2, '0');
  const millisekunde = date.getMilliseconds();

  const datumUndZeitFuerDatenbank = `${jahr}-${monat}-${tag}T${stunde}:${minute}:${sekunde}.${millisekunde}`;
  const datumUndZeit = `${tag}.${monat}.${jahr} - ${stunde}:${minute}:${sekunde}`;
  const datumUndZeitMitMillisekunden = `${tag}.${monat}.${jahr} ${stunde}:${minute}:${sekunde}.${millisekunde}`;
  const nurDatum = `${tag}.${monat}.${jahr}`;
  const nurZeit = `${stunde}:${minute}:${sekunde}`;
  const nurZeitWithMilliSeconds = `${stunde}:${minute}:${sekunde}.${millisekunde}`;
  const keinDatum = '1111-00-00T00:00:00.000';

  const datumFuerInputFeld = `${jahr}-${monat}-${tag}`;

  const resultObject = {
    datumUndZeitFuerDatenbank,
    datumUndZeit,
    datumUndZeitMitMillisekunden,
    nurDatum,
    nurZeit,
    nurZeitWithMilliSeconds,
    tag,
    monat,
    jahr,
    stunde,
    minute,
    sekunde,
    millisekunde,
    keinDatum,
    datumFuerInputFeld
  };

  return resultObject;
};
