import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../../../Common/constants/tableSorting';
import { showHeaderWithSorting, sortTable } from '../../../Common/functions/tableSorting';
import { filterTableOnChange } from '../../../Common/functions/tableFiltering';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material ui
import { Button, ButtonGroup, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

// import -> components
import CoArzneimittellisteErstellenBearbeitenDialog from './CoArzneimittellisteErstellenBearbeiten';
import CoLieferantengruppenDialog from '../Lieferantengruppen/CoLieferantengruppenDialog';
import CoLoeschenbestaetigung from '../../../Common/components/js/CoLoeschenbestaetigung';
import CoLieferantengruppenZeigenDialog from '../Lieferantengruppen/CoLieferantengruppenZeigenDialog';

// component -> React-root
const CoArzneimittellistentabelle = ({ objParent }) => {
  const [globalState, dispatch] = useStateValue();

  const [lieferantengruppenDialogState, setLieferantengruppenDialogState] = useState({ open: false, arzneimittellistenname: {} });
  const [filterInputState, setFilterInputState] = useState('');
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [loeschenbestaetigungState, setLoeschenbestaetigungState] = useState(false);
  const [focusedListIdState, setFocusedListIdState] = useState(null);
  const [arzneimittellisteErstellenBearbeitenDialogState, setArzneimittellisteErstellenBearbeitenDialogState] = useState({ open: false, arzneimittelliste: {} });
  const [lieferantengruppenZeigenDialogState, setLieferantengruppenZeigenDilogState] = useState({ open: false, arzneimittelliste: {} });
  const [arzneimittellistenState, setArzneimittellistenState] = useState([]);
  const [filteredArzneimittellistenState, setFilteredArzneimmittellistenState] = useState([]);
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.NAME]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });

  const tabellenheader = objParent.istBenutzerAdmin ? ['LISTENNAME', 'ARZNEIMITTEL', 'LIEFERANTENGRUPPEN', 'BEARBEITEN', 'LÖSCHEN'] : ['NAME'];

  // function ->
  const openOrCloseLieferantengruppenZeigenDialog = (shouldOpen, arzneimittelliste) => {
    setLieferantengruppenZeigenDilogState({ open: shouldOpen, arzneimittelliste });
  };

  // function ->
  const openOrCloseArzneimittellisteErstellenBearbeitenDialog = (shouldOpen, arzneimittelliste) => {
    setArzneimittellisteErstellenBearbeitenDialogState({ open: shouldOpen, arzneimittelliste, bearbeiten: true });
  };

  // function ->
  const openOrCloseLieferantengruppenDialog = (shouldOpen, arzneimittelliste) => {
    setLieferantengruppenDialogState({ open: shouldOpen, arzneimittellisteId: arzneimittelliste?.id, arzneimittellistenname: arzneimittelliste?.name });
    setFocusedListIdState(arzneimittelliste?.id);
  };

  // function ->
  const handleLieferantengruppeAusgewaehlt = (lieferantengruppeId) => {
    const arzneimittellisteId = focusedListIdState;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .post(`/lieferantengruppen/${lieferantengruppeId}/arzneimittel-listen/${arzneimittellisteId}`)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const openOrCloseLoeschenbestaetigung = (shouldOpen) => {
    setLoeschenbestaetigungState(shouldOpen);
  };

  // function ->
  const arzneimittellisteLoeschenOnClick = (listeId) => {
    openOrCloseLoeschenbestaetigung(true);
    setFocusedListIdState(listeId);
  };

  // function ->
  const handleLoeschenBestaetigt = () => {
    const requestUrl = objParent.istVonLieferantengruppe
      ? `/lieferantengruppen/${objParent.lieferantentgruppeId}/arzneimittel-listen/${focusedListIdState}`
      : `/arzneimittel-listen/${focusedListIdState}`;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .delete(requestUrl)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  const objArzneimittelListentabelle = {
    coArzneimittellisteErstellenBearbeitenDialog: {
      arzneimittellisteErstellenBearbeitenDialogState,
      authAxios: objParent.authAxios,
      openOrCloseArzneimittellisteErstellenBearbeitenDialog,
      setRerunEffectState: objParent.setRerunEffectState
    },
    coLieferantengruppenDialog: {
      authAxios: objParent.authAxios,
      handleLieferantengruppeAusgewaehlt,
      lieferantengruppenDialogState,
      openOrCloseLieferantengruppenDialog
    },
    coLoeschenbestaetigung: {
      loeschenbestaetigungState,
      openOrCloseLoeschenbestaetigung,
      handleLoeschenBestaetigt
    },
    coLieferantengruppenZeigenDialog: {
      openOrCloseLieferantengruppenZeigenDialog,
      lieferantengruppenZeigenDialogState,
      authAxios: objParent.authAxios
    }
  };

  // function ->
  const callFilterTableOnChange = (eventOrEventMock) => {
    filterTableOnChange(eventOrEventMock, setFilterInputState, arzneimittellistenState, setFilteredArzneimmittellistenState, 'name');
    setRerunEffectState((pre) => !pre);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    const arzneimittellisten = [...objParent.arzneimittellistenState];
    setArzneimittellistenState(arzneimittellisten);
    setFilteredArzneimmittellistenState(arzneimittellisten);

    return () => {
      isMounted = false;
    };
  }, [objParent.arzneimittellistenState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    callFilterTableOnChange({ target: { value: filterInputState } });

    return () => {
      isMounted = false;
    };
  }, [arzneimittellistenState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, filteredArzneimittellistenState, setFilteredArzneimmittellistenState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  return (
    <>
      {lieferantengruppenZeigenDialogState.open && <CoLieferantengruppenZeigenDialog objParent={objArzneimittelListentabelle.coLieferantengruppenZeigenDialog} />}
      {arzneimittellisteErstellenBearbeitenDialogState.open && <CoArzneimittellisteErstellenBearbeitenDialog objParent={objArzneimittelListentabelle.coArzneimittellisteErstellenBearbeitenDialog} />}
      {lieferantengruppenDialogState.open && <CoLieferantengruppenDialog objParent={objArzneimittelListentabelle.coLieferantengruppenDialog} />}
      {loeschenbestaetigungState && <CoLoeschenbestaetigung objParent={objArzneimittelListentabelle.coLoeschenbestaetigung} />}
      <Paper>
        <TextField
          fullWidth
          value={filterInputState}
          onChange={(event) => callFilterTableOnChange(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            )
          }}
          className="table__searchBar"
          variant="standard"
        />
        <TableContainer>
          <Table size="medium" stickyHeader>
            <TableHead>
              <TableRow className="table__title">
                {tabellenheader.map((header, index) => {
                  return (
                    <TableCell align="center" component="th" key={`coArzneimittel_listentabelle_tabllenheader_${index}`}>
                      {showHeaderWithSorting(header, sortingState, setSortingState, filteredArzneimittellistenState, setFilteredArzneimmittellistenState)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredArzneimittellistenState.map((liste, index) => {
                const { name, id } = liste;
                return (
                  <TableRow key={`coArzneimittel_listentabelle_tableBody_tableRow_${index}`}>
                    <TableCell align="center">
                      <Typography>{name}</Typography>
                    </TableCell>
                    {objParent.istBenutzerAdmin && (
                      <>
                        <TableCell align="center">
                          <Button className="button__middle--black" onClick={objParent.openOrCloseArzneimitteltabelle.bind(null, true, id, name)}>
                            {sprache('BUTTON', 'TITEL', 'ARZNEIMITTEL')}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <ButtonGroup>
                            <Button className="button__middle--black  border__right--white noWrap" onClick={openOrCloseLieferantengruppenDialog.bind(null, true, liste)}>
                              {sprache('BUTTON', 'TITEL', 'ZUGRUPPEHINZUFÜGEN')}
                            </Button>
                            {objParent.istVonLieferantengruppe ? (
                              ''
                            ) : (
                              <Button className="button__middle--black" onClick={openOrCloseLieferantengruppenZeigenDialog.bind(null, true, liste)}>
                                {sprache('BUTTON', 'TITEL', 'ZEIGEN')}
                              </Button>
                            )}
                          </ButtonGroup>
                        </TableCell>
                        <TableCell align="center">
                          <Button className="button__middle--black" onClick={openOrCloseArzneimittellisteErstellenBearbeitenDialog.bind(null, true, liste)}>
                            {sprache('BUTTON', 'TITEL', 'BEARBEITEN')}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button className="button__middle--black" onClick={arzneimittellisteLoeschenOnClick.bind(null, id)}>
                            {sprache('BUTTON', 'TITEL', 'LÖSCHEN')}
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default CoArzneimittellistentabelle;
