import { IconButton, makeStyles } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  button: {
    padding: '3px',
    fontSize: '1.125rem'
  },
  icon: {
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
}));

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} className={classes.button}>
      <IconClose className={classes.icon} />
    </IconButton>
  );
}

export default SnackbarCloseButton;
