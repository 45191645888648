import React, { useState } from 'react';

// import -> common
import { changeTextFieldsOnChange } from '../../../Common/functions/form';
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const TEXT_FIELD_NAMEN = {
  NAME: 1,
  NUMMER: 2
};

// component -> React-root
const CoLieferantaengruppeeErstellenBearbeitenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();

  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.NAME]: {
      wert: objParent.lieferantengruppeErstellenBearbeitenDialogState.lieferantengruppe?.name ?? '',
      fehler: !Boolean(objParent.lieferantengruppeErstellenBearbeitenDialogState.lieferantengruppe?.name?.length)
    },
    [TEXT_FIELD_NAMEN.NUMMER]: {
      wert: objParent.lieferantengruppeErstellenBearbeitenDialogState.lieferantengruppe?.gruppennummer ?? '',
      fehler: !Boolean(objParent.lieferantengruppeErstellenBearbeitenDialogState.lieferantengruppe?.gruppennummer?.length)
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseLieferantengruppeErstellenBearbeitenDialog(false);
  };

  // function ->
  const lieferantengruppeErstellenRequest = (name, nummer) => {
    const requestBody = { name, gruppennummer: nummer };

    objParent.authAxios
      .post('/lieferantengruppen', requestBody)
      .then(() => {
        objParent.setRerunEffectState((pre) => !pre);
        closeDialog();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantengruppeBearbeitenRequest = (name, nummer) => {
    const requestBody = { name, gruppennummer: nummer };
    const lieferantengruppeId = objParent.lieferantengruppeErstellenBearbeitenDialogState.lieferantengruppe?.id;

    objParent.authAxios
      .put(`/lieferantengruppen/${lieferantengruppeId}`, requestBody)
      .then(() => {
        objParent.setRerunEffectState?.((pre) => !pre);
        closeDialog();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantengruppeErstellenBearbeitenOnSubmit = (event) => {
    event.preventDefault();

    const name = textFieldsState[TEXT_FIELD_NAMEN.NAME];
    const nummer = textFieldsState[TEXT_FIELD_NAMEN.NUMMER];

    const namefehler = name.fehler;
    const nummerfehler = nummer.fehler;

    if (namefehler || nummerfehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    const namewert = name.wert;
    const nummerwert = nummer.wert;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    if (objParent.lieferantengruppeErstellenBearbeitenDialogState.erstellen) {
      lieferantengruppeErstellenRequest(namewert, nummerwert);
    } else if (objParent.lieferantengruppeErstellenBearbeitenDialogState.bearbeiten) {
      lieferantengruppeBearbeitenRequest(namewert, nummerwert);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={objParent.lieferantengruppeErstellenBearbeitenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {objParent.lieferantengruppeErstellenBearbeitenDialogState.erstellen
                ? sprache('DIALOG', 'TITEL', 'LIEFERANTENGRUPPEERSTELLEN')
                : sprache('DIALOG', 'TITEL', 'LIEFERANTENGRUPPEBEARBEITEN')}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={lieferantengruppeErstellenBearbeitenOnSubmit}>
          <Grid container spacing={3}>
            {objParent.lieferantengruppeErstellenBearbeitenDialogState.bearbeiten && (
              <Grid item xs={12}>
                <Typography className="typography__dialogSubtitle">
                  {sprache('TEXT', 'KURZ', 'LIEFERANTENGRUPPE')}: {objParent.lieferantengruppeErstellenBearbeitenDialogState.lieferantengruppe?.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                value={textFieldsState[TEXT_FIELD_NAMEN.NAME].wert}
                onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.NAME, setTextFieldsState)}
                error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler}
                helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                fullWidth
                color="primary"
                variant="outlined"
                autoFocus
                label={sprache('FORM', 'LABEL', 'NAME')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={textFieldsState[TEXT_FIELD_NAMEN.NUMMER].wert}
                onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.NUMMER, setTextFieldsState)}
                error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NUMMER].fehler}
                helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NUMMER].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                fullWidth
                color="primary"
                variant="outlined"
                label={sprache('FORM', 'LABEL', 'NUMMER')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth className="button__middle--blue" type="submit">
                {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth className="button__middle--red" onClick={closeDialog}>
                {sprache('BUTTON', 'TITEL', 'ABBRECHEN')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CoLieferantaengruppeeErstellenBearbeitenDialog;
