import React from 'react';

// import -> material-ui
import { Box, Typography } from '@material-ui/core';

const CoFehlermeldung = ({ objParent }) => {
  const fehlermeldungtext = objParent.fehlermeldungState.text;
  return (
    <Box>
      <Typography align="center" className="typography typography__red border__red">
        {fehlermeldungtext}
      </Typography>
    </Box>
  );
};

export default CoFehlermeldung;
