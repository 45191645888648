// function ->
export const obTextFieldLeerUeberpruefen = (wertZurUeberpruefung) => {
  //  statement -> checks if wertZurUeberpruefung is not empty
  return wertZurUeberpruefung ? false : true;
};

// function ->
export const changeTextFieldsOnChange = (event, textFieldName, setState, options = { fehler: true }, fehlerUeberpruefen = obTextFieldLeerUeberpruefen) => {
  const textFieldWert = event.target.value;
  setState((pre) => ({
    ...pre,
    [textFieldName]: {
      fehler: options.fehler ? fehlerUeberpruefen(textFieldWert) : null,
      wert: textFieldWert
    }
  }));
};
