// function ->
export const filterTableOnChange = (event, setInputState, originalState, setFilteredState, ...criteria) => {
  const arzneimittellisten = [...originalState];
  const searchedArzneimittellisten = arzneimittellisten.filter((e) => {
    let shouldKeep = criteria.some((_e) => e[_e]?.trim().toLowerCase().indexOf(event.target.value.trim().toLowerCase()) >= 0);
    return shouldKeep;
  });
  setFilteredState(searchedArzneimittellisten);
  setInputState(event.target.value);
};
