import React, { useEffect, useState } from 'react';

// import -> common
import { createAxiosInstance, handleAndDownloadblobResponse } from '../Common/functions/axios';
import { logUserOut } from '../Common/functions/user';
import { sprache } from '../Common/sprache/sprache';

// import -> reducer
import dispatchActions from '../../reducer/dispatchActions';
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { Button, ButtonGroup, Grid, IconButton, TextField, Typography } from '@material-ui/core';

// import -> components
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';
import { Check, Close, Edit } from '@material-ui/icons';

const CoEinstellungen = () => {
  const [globalState, dispatch] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const isLieferant = globalState.authentication.user.access === 'LIEFERANT';
  const isAdmin = globalState.authentication.user.access === 'ADMIN';

  const [adminEmailState, setAdminEmailState] = useState('');
  const [adminMailBearbeitungModusState, setAdminMailBearbeitungsModusState] = useState(false);
  const [adminMailInputState, setAdminMailInputState] = useState('');

  // function ->
  const downloadQrCode = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });
    authAxios
      .get('/lieferant-qr-code', { responseType: 'blob' })
      .then((response) => {
        const { data } = response;
        handleAndDownloadblobResponse(data, 'image/png', 'QR-Code');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const getAdminEmail = () => {
    authAxios
      .get('/admin-mail')
      .then((response) => {
        setAdminEmailState(response.data.string);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const changeAdminMail = () => {
    authAxios
      .put('/admin-mail', { string: adminMailInputState })
      .then(() => {
        getAdminEmail();
        setAdminMailBearbeitungsModusState(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (isAdmin) {
      getAdminEmail();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <CoSeitenstruktur seitentitel="EINSTELLUNGEN">
      <Grid container item spacing={3} className="margin__top30 border">
        <Grid item xs={12}>
          <Typography className="typography__abteilungtitel">{sprache('ABTEILUNG', 'TITEL', 'KONTO')}</Typography>
        </Grid>
        {isAdmin ? (
          <Grid item xs={12}>
            <Typography className="typography">
              <strong>Versand-E-Mail-Adresse </strong>&nbsp;&nbsp;
              {adminMailBearbeitungModusState ? (
                <TextField
                  onChange={(e) => {
                    setAdminMailInputState(e.target.value);
                  }}
                  value={adminMailInputState}
                  variant="outlined"
                />
              ) : (
                adminEmailState
              )}{' '}
              {adminMailBearbeitungModusState ? (
                <ButtonGroup>
                  <IconButton title="Änderungen speichern" onClick={changeAdminMail}>
                    <Check className="icon__green" />
                  </IconButton>
                  <IconButton title="Änderungen verwerfen" onClick={() => setAdminMailBearbeitungsModusState(false)}>
                    <Close className="icon__red" />
                  </IconButton>
                </ButtonGroup>
              ) : (
                <IconButton title="Bearbeiten" onClick={() => setAdminMailBearbeitungsModusState(true)}>
                  <Edit className="icon__darkblue" />
                </IconButton>
              )}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Button className="button__middle--red" onClick={() => logUserOut(dispatch)}>
            {sprache('BUTTON', 'TITEL', 'ABMELDEN')}
          </Button>
        </Grid>
      </Grid>
      {isLieferant && (
        <Grid container item spacing={3} className="margin__top30 border">
          <Grid item xs={12}>
            <Typography className="typography__abteilungtitel">{sprache('ABTEILUNG', 'TITEL', 'QRCODE')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button className="button__middle--red" onClick={downloadQrCode}>
              {sprache('BUTTON', 'TITEL', 'QRCODEHERUNTERLADEN')}
            </Button>
          </Grid>
        </Grid>
      )}
    </CoSeitenstruktur>
  );
};

export default CoEinstellungen;
