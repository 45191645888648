import { Button, ButtonGroup, FormControl, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { DeleteForever, Edit, Close, Check } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import dispatchActions from '../../../reducer/dispatchActions';
import useStateValue from '../../../reducer/StateProvider';
import { createAxiosInstance } from '../../Common/functions/axios';
import CoLoeschenbestaetigung from '../../Common/components/js/CoLoeschenbestaetigung';
import { useSnackbar } from 'notistack';
import PaketAbgeschlossen, { PaketAbgeschlossenSnackbarKey } from '../../Snackbar/PaketAbgeschlossen';
import PaketNameExistiert, { PaketNameExistiertSnackbarKey } from '../../Snackbar/PaketNameExsistiert';

const CoPakettemplate = ({ objParent }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [globalState, dispatch] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [paketeState, setPaketeState] = useState([]);
  const [paketArticlesState, setPaketArticlesState] = useState([]);
  const [angepasstArticleState, setAngepasstArticleState] = useState([]);
  const [selectedPaketState, setSelectedPaketState] = useState('');
  const [isInEditingModeState, setIsInEditingModeState] = useState(false);
  const [paketNameInputState, setPaketNameInputState] = useState('');
  const [bestaetigungState, setBestaetigungState] = useState(false);
  const [actionState, setActionState] = useState('');
  const [bestaetigungTextState, setBestaetigungTextState] = useState('');

  const [shouldShowPaketOptions, setShouldShowPaketOptions] = useState(false);

  const artikelTableHeader = isInEditingModeState ? ['Artikelbezeichung', 'PZN', 'Verfalldatum', 'Anzahl Packung', ''] : ['Artikelbezeichung', 'PZN', 'Verfalldatum', 'Anzahl Packung'];

  // function ->
  const getPakete = () => {
    authAxios
      .get('/paket/all/nicht_abgeschlossen')
      .then((response) => {
        setPaketeState(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const getCurrentPaket = () => {
    authAxios
      .get('/paket/current')
      .then((response) => {
        if (response.data.id !== undefined) {
          setSelectedPaketState(response.data.id);
        } else {
          setAngepasstArticleState([]);
          setSelectedPaketState('');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const getArtikeln = () => {
    authAxios
      .get(`/paket/${selectedPaketState}/artikel`)
      .then((response) => {
        setPaketArticlesState(response.data);
        setAngepasstArticleState(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const handlePaketChange = (event) => {
    setSelectedPaketState(event.target.value);
  };

  // function ->
  const switchEditingMode = (isOn) => {
    setIsInEditingModeState(isOn);
    if (isOn) setPaketNameInputState(paketeState.find((paket) => paket.id === selectedPaketState)?.name);
  };

  // function ->
  const changePaketNameOnChange = (event) => {
    setPaketNameInputState(event.target.value);
  };

  // function ->
  const makeActionToPaketAndOpenWarning = (action) => {
    setActionState(action);
    openOrCloseBestaetigung(true);
    if (action === 'paketAbschliessen') {
      setBestaetigungTextState('Wollen Sie das Paket wirklich abschließen?');
    } else if (action === 'paketLoeschen') {
      setBestaetigungTextState('Wollen Sie das Paket wirklich löschen?');
    }
  };

  // function ->
  const openOrCloseBestaetigung = (shouldOpen) => {
    setBestaetigungState(shouldOpen);
  };

  // function ->
  const paketAbschliessen = () => {
    const requestUrl = `/paket/${selectedPaketState}/abschliessen`;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .get(requestUrl, { responseType: 'blob' })
      .then((response) => {
        getPakete();
        getCurrentPaket();
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        enqueueSnackbar('', {
          key: PaketAbgeschlossenSnackbarKey,
          content: (key, message) => <PaketAbgeschlossen id={key} message={message} paketName={paketeState.find((paket) => paket.id === selectedPaketState).name} fileURL={fileURL} />
        });
        objParent.setRerunEffectState1((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const paketLoeschen = () => {
    const requestUrl = `/paket/${selectedPaketState}`;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .delete(requestUrl)
      .then(() => {
        getPakete();
        getCurrentPaket();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const handleBestaetigung = () => {
    if (actionState === 'paketAbschliessen') {
      paketAbschliessen();
    } else if (actionState === 'paketLoeschen') {
      paketLoeschen();
    }
  };

  // function ->
  const deleteArticleTemp = (articleId) => {
    let newAngepasstArticleState = angepasstArticleState.filter(
      (article) => article.arzneimittellistenEintrag.arzneimittel.id !== articleId || (article.arzneimittellistenEintrag.arzneimittel.id === articleId && article.anzahl > 1)
    );

    newAngepasstArticleState = newAngepasstArticleState.map((article) => {
      if (article.arzneimittellistenEintrag.arzneimittel.id === articleId) {
        return { ...article, anzahl: article.anzahl - 1 };
      }
      return article;
    });
    setAngepasstArticleState(newAngepasstArticleState);
  };

  // function ->
  const revertChanges = () => {
    setAngepasstArticleState(paketArticlesState);
    switchEditingMode(false);
  };

  // function ->
  const submitChanges = () => {
    const requestUrl = '/paket';
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    const selectedPaket = paketeState.find((paket) => paket.id === selectedPaketState);

    if (!selectedPaket) return;

    const requestBody = {
      id: Number(selectedPaketState),
      name: paketNameInputState,
      abgeschlossen: selectedPaket.abgeschlossen,
      bereitZumVersand: selectedPaket.bereitZumVersand,
      artikeln: angepasstArticleState
    };

    authAxios
      .put(requestUrl, requestBody)
      .then(() => {
        getPakete();
        getArtikeln();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('', {
          key: PaketNameExistiertSnackbarKey,
          content: (key, message) => <PaketNameExistiert id={key} message={message} paketName={paketNameInputState} />
        });
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
        switchEditingMode(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    getPakete();
    getCurrentPaket();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (selectedPaketState) {
      getArtikeln();
      getPakete();
    }

    return () => {
      isMounted = false;
    };
  }, [selectedPaketState, objParent.rerunEffectState1]);

  useEffect(() => {
    getCurrentPaket();
  }, [objParent.rerunEffectState1]);

  useEffect(() => {
    setShouldShowPaketOptions(Boolean(selectedPaketState));
  }, [selectedPaketState]);

  const objPakettemplate = {
    coLoeschenbestaetigung: {
      loeschenbestaetigungState: bestaetigungState,
      openOrCloseLoeschenbestaetigung: openOrCloseBestaetigung,
      handleLoeschenBestaetigt: handleBestaetigung
    }
  };

  return (
    <>
      {bestaetigungState && <CoLoeschenbestaetigung objParent={objPakettemplate.coLoeschenbestaetigung} options={{ text: bestaetigungTextState }} />}

      {paketeState.length ? (
        <div className="pakettemplate">
          <div className="pakettemplate__header">
            <div className="pakettemplate__headerSelection">
              {isInEditingModeState ? (
                <TextField fullWidth onChange={changePaketNameOnChange} value={paketNameInputState} variant="outlined" />
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <Select value={selectedPaketState} onChange={handlePaketChange}>
                    {paketeState.map((paket, index) => {
                      return (
                        <MenuItem key={`paket_option_${index}`} value={paket.id}>
                          {paket.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>
            {shouldShowPaketOptions ? (
              <div className="pakettemplate__headerButtons">
                {isInEditingModeState ? (
                  <ButtonGroup>
                    <IconButton title="Änderungen speichern" onClick={submitChanges}>
                      <Check className="icon__green" />
                    </IconButton>
                    <IconButton title="Änderungen verwerfen" onClick={revertChanges}>
                      <Close className="icon__red" />
                    </IconButton>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup>
                    <Button className="button__small--blue button--inGroup" onClick={() => makeActionToPaketAndOpenWarning('paketAbschliessen')}>
                      Paket voll
                    </Button>
                    <IconButton title="Bearbeiten" onClick={() => switchEditingMode(true)}>
                      <Edit className="icon__small--darkblue" />
                    </IconButton>
                    <IconButton title="Löschen" onClick={() => makeActionToPaketAndOpenWarning('paketLoeschen')}>
                      <DeleteForever className="icon__small--red" />
                    </IconButton>
                  </ButtonGroup>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="pakettemplate__body margin__top10">
            {angepasstArticleState.length > 0 ? (
              <TableContainer>
                <Table size="medium" stickyHeader>
                  <TableHead>
                    <TableRow className="table__title">
                      {artikelTableHeader.map((head, index) => (
                        <TableCell align="center" component="th" key={`coPaketTemplate_paketArtikeltabelle_tabllenheader_${index}`}>
                          {head}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {angepasstArticleState.map((article, index) => (
                      <TableRow key={`coPaketTemplate_paketArtikeltabelle_tabllenbody_row${index}`}>
                        <TableCell align="center">
                          <Typography className="typography pakettemplate__bodyArticleName">{article.arzneimittellistenEintrag.arzneimittel.bezeichnung}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className="typography pakettemplate__bodyArticleName">{article.arzneimittellistenEintrag.arzneimittel.pzn}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className="typography pakettemplate__bodyArticleName">{article.arzneimittellistenEintrag.verfallsdatum}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className="typography pakettemplate__bodyArticleName">{article.anzahl}</Typography>
                        </TableCell>
                        {isInEditingModeState ? (
                          <TableCell align="center">
                            <IconButton className="pakettemplate__bodyArticleDeleteIcon" title="Löschen" onClick={() => deleteArticleTemp(article.arzneimittellistenEintrag.arzneimittel.id)}>
                              <DeleteForever className="icon__red" />
                            </IconButton>
                          </TableCell>
                        ) : (
                          ''
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CoPakettemplate;
