export const normalHandler = (previousState, keyToChagneName, keyToChagneValue) => {
  return {
    ...previousState,
    [keyToChagneName]: keyToChagneValue
  };
};

export const singInHanlder = (previousState, authenticationData) => {
  return {
    ...previousState,
    authentication: {
      auth: true,
      token: authenticationData.token,
      user: { access: authenticationData.access, name: authenticationData.name }
    }
  };
};

export const signOutHandler = (previousState) => {
  return {
    ...previousState,
    authentication: {
      auth: false,
      token: '',
      user: {}
    }
  };
};
