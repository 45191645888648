import React from 'react';

// import -> material-ui
import { Typography } from '@material-ui/core';
import { sprache } from '../Common/sprache/sprache';

const CoNichtGefunden = () => {
  return (
    <div className="coNichtGefunden__wrapper">
      <Typography className="coNichtGefunden__text" align="center">
        <span className="typography__red"> 404 </span> &nbsp;&nbsp;&nbsp; {sprache('TEXT', 'MELDUNG', 'SEITENICHTGEFUNDEN')}
      </Typography>
    </div>
  );
};

export default CoNichtGefunden;
