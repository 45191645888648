import React, { useEffect, useState } from 'react';

// import -> common
import { createAxiosInstance, handleAndDownloadblobResponse } from '../Common/functions/axios';
import { csvTypes } from '../Common/constants/csvTypes';
import { sprache } from '../Common/sprache/sprache';

// import -> reducer
import dispatchActions from '../../reducer/dispatchActions';
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@material-ui/core';
import { Add, AddBox, FormatListNumbered, VerticalAlignTop } from '@material-ui/icons';

// import -> components
import CoArzneimittelErstellenBearbeitenDialog from './Append/Single/CoArzneimittelErstellenBearbeitenDialog';
import CoArzneimittellisteErstellenBearbeitenDialog from './Append/Liste/CoArzneimittellisteErstellenBearbeiten';
import CoArzneimittellistentabelle from './Append/Liste/CoArzneimittellistentabelle';
import CoArzneimitteltabelle from './Append/Single/CoArzneimitteltabelle';
import CoFehlermeldung from '../Common/components/js/CoFehlermeldung';
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';

const CoArzneimittel = () => {
  const [globalState, dispatch] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const istBenutzerAdmin = globalState.authentication.user.access === 'ADMIN' || globalState.authentication.user.access === 'SUPER_USER';

  const [arzneimittellistenState, setArzneimittellistenState] = useState([]);
  const [arzneimittelErstellenBearbeitenDialogState, setArzneimittelErstellenBearbeitenDialogState] = useState({ open: false });
  const [arzneimittellisteErstellenBearbeitenDialogState, setArzneimittellisteErstellenBearbeitenDialogState] = useState({ open: false });
  const [arzneimitteltabelleState, setArzneimitteltabelleState] = useState({ open: false, listId: '', name: '' });
  const [csvArzneimittellisteState, setCsvArzneimittellisteState] = useState({});
  const [csvFehlerState, setCsvFehlerState] = useState([]);
  const [csvUeberschreibenState, setCsvUeberschreibenState] = useState(false);
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [fileInputState, setFileInputState] = useState('');

  // function ->
  const openOrCloseArzneimitteltabelle = (shouldOpen, listId, name) => {
    setArzneimitteltabelleState({ open: shouldOpen, listId: listId ?? '', name: name ?? '' });
  };

  // function ->
  const openOrCloseArzneimittelErstellenBearbeitenDialog = (shouldOpen) => {
    setArzneimittelErstellenBearbeitenDialogState({ open: shouldOpen, erstellen: true, arzneimittel: {} });
  };

  // function ->
  const openOrCloseArzneimittellisteErstellenBearbeitenDialog = (shouldOpen) => {
    setArzneimittellisteErstellenBearbeitenDialogState({ open: shouldOpen, erstellen: true });
  };

  // function ->
  const echoCsvFehler = () => {
    return csvFehlerState
      .sort((fehlerA, fehlerB) => (+fehlerA.zeile > +fehlerB.zeile ? 1 : -1))
      .map((fehler) => {
        const { zeile, errorMessage } = fehler;
        const partOne = zeile ? `Zeile: ${zeile}. ` : '';
        const partTwo = `Fehler: ${errorMessage}`;

        const fehlernachricht = partOne + partTwo;

        return <CoFehlermeldung objParent={{ fehlermeldungState: { text: fehlernachricht } }} />;
      });
  };

  // function ->
  const handleCsvUploadOnChange = (event) => {
    const datei = event.target.files[0];
    const inputValue = event.target.value;
    setFileInputState(inputValue);
    if (!datei) return;
    const dateiType = datei.type;
    if (csvTypes.includes(dateiType)) {
      setCsvArzneimittellisteState(datei);
    }
  };

  // function ->
  const handleCsvUeberschreibenOnChange = (event) => {
    const ueberschreiben = event.target.checked;
    setCsvUeberschreibenState(ueberschreiben);
  };

  // function ->
  const anfrageCsvSpeichernerfolgHandeln = () => {
    setCsvArzneimittellisteState({});
    setCsvUeberschreibenState(false);
    setFileInputState('');
    setRerunEffectState((pre) => !pre);
  };

  // function ->
  const musterherunterladenOnClick = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .get('/csv/muster/arzneimittelliste', { responseType: 'blob' })
      .then((response) => {
        const { data } = response;
        handleAndDownloadblobResponse(data, '.csv', 'Arzneimittelmuster');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const csvArzneimittellisteSpeichern = () => {
    const formData = new FormData();
    formData.append('file', csvArzneimittellisteState);
    formData.append('ersetzen', csvUeberschreibenState);
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .post('/csv/arzneimittel', formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then((response) => {
        const errorsArr = response.data.errors;
        anfrageCsvSpeichernerfolgHandeln();
        setCsvFehlerState(errorsArr);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const anfrageArzneimittellsitenerfolgHandeln = (listen) => {
    setArzneimittellistenState(listen);
  };

  // function ->
  const arzneimittellistenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });
    authAxios
      .get('/arzneimittel-listen')
      .then((response) => {
        const listen = response.data;
        anfrageArzneimittellsitenerfolgHandeln(listen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) {
      return;
    }

    arzneimittellistenHolen();

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objArzneimittel = {
    coArzneimittellistentabelle: {
      arzneimittellistenState,
      setArzneimittellistenState,
      authAxios,
      istBenutzerAdmin,
      openOrCloseArzneimitteltabelle,
      rerunEffectState,
      setRerunEffectState
    },
    coArzneimittelerstellenBearbeitenDialog: {
      arzneimittelErstellenBearbeitenDialogState,
      authAxios,
      openOrCloseArzneimittelErstellenBearbeitenDialog
    },
    coArzneimittellisteErstellenBearbeitenDialog: {
      arzneimittellisteErstellenBearbeitenDialogState,
      authAxios,
      openOrCloseArzneimittellisteErstellenBearbeitenDialog,
      setRerunEffectState
    },
    coArzneimitteltabelle: {
      arzneimitteltabelleState,
      authAxios,
      openOrCloseArzneimitteltabelle,
      rerunEffectState,
      setRerunEffectState
    }
  };

  return (
    <>
      {arzneimitteltabelleState.open && <CoArzneimitteltabelle objParent={objArzneimittel.coArzneimitteltabelle} />}
      {arzneimittelErstellenBearbeitenDialogState.open && <CoArzneimittelErstellenBearbeitenDialog objParent={objArzneimittel.coArzneimittelerstellenBearbeitenDialog} />}
      {arzneimittellisteErstellenBearbeitenDialogState.open && <CoArzneimittellisteErstellenBearbeitenDialog objParent={objArzneimittel.coArzneimittellisteErstellenBearbeitenDialog} />}
      <CoSeitenstruktur seitentitel="ARZNEIMITTEL">
        <Grid item container spacing={3} className="margin__top30">
          <Grid item container xs={12} md="auto" spacing={3}>
            <Grid item xs={12}>
              {echoCsvFehler()}
            </Grid>
            <Grid item xs={12} sm="auto">
              <input type="file" accept={csvTypes.join(',')} style={{ display: 'none' }} id="arzneimittel_input_file_arzneimittelListe" value={fileInputState} onChange={handleCsvUploadOnChange} />
              <label htmlFor="arzneimittel_input_file_arzneimittelListe">
                <Button startIcon={<VerticalAlignTop />} component="span" className="button__middle--red">
                  {sprache('BUTTON', 'TITEL', 'ARZNEIMITTELLISTEHOCHLADEN')}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Link component="button" className="button__link--blue" onClick={musterherunterladenOnClick}>
                {sprache('BUTTON', 'TITEL', 'MUSTERCSVDATEIHERUNTERLADEN')}
              </Link>
            </Grid>
            {csvArzneimittellisteState.name && (
              <Grid item xs={12}>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <Typography className="typography__breakWord">{csvArzneimittellisteState.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      checked={csvUeberschreibenState}
                      onChange={handleCsvUeberschreibenOnChange}
                      control={<Checkbox color="primary" />}
                      label={sprache('FORM', 'LABEL', 'EXISTIERENDLISTEÜBERSCHREIBEN')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button className="button__middle--red" onClick={csvArzneimittellisteSpeichern}>
                      {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {istBenutzerAdmin && (
            <>
              <Grid item xs={12} md="auto">
                <Button startIcon={<AddBox />} className="button__middle--blue" onClick={openOrCloseArzneimittellisteErstellenBearbeitenDialog.bind(null, true)}>
                  {sprache('BUTTON', 'TITEL', 'ARZNEIMITTELLISTEERSTELLEN')}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button startIcon={<Add />} className="button__middle--orange" onClick={openOrCloseArzneimittelErstellenBearbeitenDialog.bind(null, true)}>
                  {sprache('BUTTON', 'TITEL', 'ARZNEIMITTELERSTELLEN')}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button startIcon={<FormatListNumbered />} className="button__middle--green" onClick={openOrCloseArzneimitteltabelle.bind(null, true, null, null)}>
                  {sprache('BUTTON', 'TITEL', 'ARZNEIMITTELANSEHEN')}
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <CoArzneimittellistentabelle objParent={objArzneimittel.coArzneimittellistentabelle} />
          </Grid>
        </Grid>
      </CoSeitenstruktur>
    </>
  );
};

export default CoArzneimittel;
