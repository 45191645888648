import { IconButton, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../constants/tableSorting';
import { sprache } from '../sprache/sprache';

// function ->
const sortArrayAccordingDirection = (sortingDirection, sortingType, a, b, isDate) => {
  const compareValue1 = isDate ? new Date(a[sortingType]) : !isNaN(a[sortingType]) ? Number(a[sortingType]) : a[sortingType]?.toLowerCase();
  const compareValue2 = isDate ? new Date(b[sortingType]) : !isNaN(b[sortingType]) ? Number(b[sortingType]) : b[sortingType]?.toLowerCase();

  if (compareValue1 === compareValue2) return 0;

  if (compareValue1 instanceof Date && compareValue2 instanceof Date && compareValue1.getTime() === compareValue2.getTime()) {
    return 0;
  }

  if (sortingDirection === SORTING_DIRECTIONS.ASCENDING) {
    if (typeof compareValue1 === 'number' && typeof compareValue2 === 'string') return -1;
    else if (typeof compareValue1 === 'string' && typeof compareValue2 === 'number') return 1;
    return compareValue1 > compareValue2 ? 1 : -1;
  }
  if (typeof compareValue1 === 'number' && typeof compareValue2 === 'string') return 1;
  else if (typeof compareValue1 === 'string' && typeof compareValue2 === 'number') return -1;
  return compareValue1 > compareValue2 ? -1 : 1;
};

// function ->
export const sortTable = (sortingType, sortingState, setSortingState, arrayToSortState, setArrayToSortState, isSameDirection) => {
  const listenState = [...arrayToSortState];

  // here it should be the opposite of the last sorting direction
  const sortingDirection = isSameDirection
    ? sortingState[sortingType].sortingDir
    : sortingState[sortingType].sortingDir === SORTING_DIRECTIONS.NO_SORTING || sortingState[sortingType].sortingDir === SORTING_DIRECTIONS.DESCENDING
    ? SORTING_DIRECTIONS.ASCENDING
    : SORTING_DIRECTIONS.DESCENDING;

  const stateKey = sortingType === SORTING_TYPES.GRUPPENNAME ? 'name' : sortingType;

  listenState.sort((a, b) => sortArrayAccordingDirection(sortingDirection, stateKey, a, b, sortingType === SORTING_TYPES.VERFALLSDATUM || sortingType === SORTING_TYPES.VERSANDDATUM));
  setArrayToSortState(listenState);
  setSortingState((pre) => {
    const newPre = {};
    for (let key in pre) {
      newPre[key] = {
        ...pre[key],
        isSorted: false
      };
    }

    return {
      ...newPre,
      [sortingType]: {
        sortingDir: sortingDirection,
        isSorted: true
      }
    };
  });
};

// function ->
const showSortingArrowIconButton = (sortingType, sortingState, setSortingState, arrayToSortState, setArrayToSortState) => {
  const sortingDirection = sortingState[sortingType].sortingDir;

  const { isSorted } = sortingState[sortingType];

  const classNameAddons = isSorted ? 'active' : '';

  // here it should be the opposite of the last sorting direction
  return (
    <IconButton className={`button__sortingArrowIcon ${classNameAddons}`} onClick={() => sortTable(sortingType, sortingState, setSortingState, arrayToSortState, setArrayToSortState)}>
      {sortingDirection === SORTING_DIRECTIONS.DESCENDING ? <ArrowUpward /> : <ArrowDownward />}
    </IconButton>
  );
};

// function ->
const isItTheRightHeader = (header) => {
  return (
    header === 'LISTENNAME' ||
    header === 'NAME' ||
    header === 'GRUPPENNAME' ||
    header === 'GRUPPENNUMMER' ||
    header === 'BEZEICHNUNG' ||
    header === 'PZN' ||
    header === 'VERFALLSDATUM' ||
    header === 'ARZNEIMITTELLISTE' ||
    header === 'NUMMER' ||
    header === 'LIEFERANT' ||
    header === 'VERSANDDATUM' ||
    header === 'SERIENNUMMER' ||
    header === 'CHARGE' ||
    header === 'ANZAHLPACKUNG'
  );
};

// function ->
export const showHeaderWithSorting = (header, sortingState, setSortingState, arrayToSortState, setArrayToSortState) => {
  const sortingType = header === 'LISTENNAME' ? SORTING_TYPES.NAME : SORTING_TYPES[header];
  return (
    <Typography align="center" className="noWrap">
      {isItTheRightHeader(header) ? showSortingArrowIconButton(sortingType, sortingState, setSortingState, arrayToSortState, setArrayToSortState) : ''}
      {sprache('TABELLE', 'HEADER', header)}
    </Typography>
  );
};
