import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import dispatchActions from '../../../reducer/dispatchActions';
import useStateValue from '../../../reducer/StateProvider';
import { createAxiosInstance, handleAndDownloadblobResponse } from '../../Common/functions/axios';
import CoLoeschenbestaetigung from '../../Common/components/js/CoLoeschenbestaetigung';
import { useSnackbar } from 'notistack';
import PaketVersendet, { PaketVersendetSnackbarKey } from '../../Snackbar/PaketVersendet';
import AlertSnackbar, { AlertSnackbarKey } from '../../Snackbar/AlertSnackbar';

const CoVersandPaket = ({ objParent }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [globalState, dispatch] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [paketeState, setPaketeState] = useState([]);
  const [bestaetigungState, setBestaetigungState] = useState(false);
  const [actionState, setActionState] = useState('');
  const [bestaetigungTextState, setBestaetigungTextState] = useState('');
  const [selectAllCheckboxState, setSelectAllCheckboxState] = useState(false);
  const [focusedPaketIdState, setFocusedPaketIdState] = useState(null);

  // function ->
  const getPakete = () => {
    authAxios
      .get('/paket/all/abgeschlossen')
      .then((response) => {
        setPaketeState(response.data.map((paket) => ({ ...paket, checked: false })));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const makeActionToPaketAndOpenWarning = (action, paketId) => {
    setActionState(action);
    openOrCloseBestaetigung(true);
    if (paketId) {
      setFocusedPaketIdState(paketId);
    }

    if (action === 'paketOeffnen') {
      setBestaetigungTextState('Wollen Sie das Paket wirklich wieder öffnen?');
    } else if (action === 'paketeZumVersandBereitstellen') {
      const paketeLength = paketeState.filter((e) => e.checked).length;
      if (paketeLength > 1) {
        setBestaetigungTextState('Wollen Sie die Pakete zur Abholung wirklich anmelden?');
      } else {
        setBestaetigungTextState('Wollen Sie das Paket zur Abholung wirklich anmelden?');
      }
    }
  };

  // function ->
  const generatePDF = (paketId) => {
    authAxios.get(`/paket/${paketId}/pdf`, { responseType: 'blob' }).then((response) => {
      const { data } = response;
      const paketName = paketeState.find((paket) => paketId === paket.id).name;
      handleAndDownloadblobResponse(data, 'pdf', paketName);
    });
  };

  // function ->
  const openOrCloseBestaetigung = (shouldOpen) => {
    setBestaetigungState(shouldOpen);
  };

  // function ->
  const paketOeffnen = () => {
    const requestUrl = `/paket/${focusedPaketIdState}/oeffnen`;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .get(requestUrl)
      .then(() => {
        objParent.setRerunEffectState1((pre) => !pre);
        setFocusedPaketIdState(null);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const paketeZumVersandBereitstellen = () => {
    const requestUrl = `/paket/versand_bereit`;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    const paketeToBeSend = paketeState.filter((e) => e.checked);
    const paketeToBeSendIds = paketeToBeSend.map((e) => e.id);

    const requestBody = {
      paketeIds: paketeToBeSendIds
    };

    authAxios
      .post(requestUrl, requestBody)
      .then(() => {
        const paketName = paketeToBeSend.length > 1 ? '' : paketeToBeSend[0].name;
        enqueueSnackbar('', {
          key: PaketVersendetSnackbarKey,
          content: (key, message) => <PaketVersendet id={key} message={message} paketName={paketName} />
        });
        setSelectAllCheckboxState(false);
        getPakete();

        const emailRequestUrl = `/paket/versand_bereit/email`;

        authAxios
          .post(emailRequestUrl, requestBody)
          .then(() => {
            enqueueSnackbar('E-Mail wurde erfolgreich versendet.', {
              key: AlertSnackbarKey,
              content: (key, message) => <AlertSnackbar id={key} message={message} severity={'success'} />
            });
          })
          .catch(() => {
            enqueueSnackbar('E-Mail konnte nicht versendet werden. Bitte wenden Sie sich an ihren Administrator.', {
              key: AlertSnackbarKey,
              content: (key, message) => <AlertSnackbar id={key} message={message} severity={'error'} />
            });
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const handleBestaetigung = () => {
    if (actionState === 'paketOeffnen') {
      paketOeffnen();
    } else if (actionState === 'paketeZumVersandBereitstellen') {
      paketeZumVersandBereitstellen();
    }
  };

  // function ->
  const togglePaketCheck = (event, paketId) => {
    let isEverythingChecked = true;

    const newPaketeState = paketeState.map((paket) => {
      const newPaketObject = {
        ...paket
      };
      if (paket.id === paketId) {
        newPaketObject.checked = event.target.checked;
      }

      if (!newPaketObject.checked) {
        isEverythingChecked = false;
      }

      return newPaketObject;
    });

    setSelectAllCheckboxState(isEverythingChecked);
    setPaketeState(newPaketeState);
  };

  // function ->
  const toggleAllPaketeCheck = (event) => {
    setSelectAllCheckboxState(event.target.checked);
    const newPaketeState = paketeState.map((paket) => ({
      ...paket,
      checked: event.target.checked
    }));
    setPaketeState(newPaketeState);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    getPakete();

    return () => {
      isMounted = false;
    };
  }, [objParent.rerunEffectState1]);

  const objPakettemplate = {
    coLoeschenbestaetigung: {
      loeschenbestaetigungState: bestaetigungState,
      openOrCloseLoeschenbestaetigung: openOrCloseBestaetigung,
      handleLoeschenBestaetigt: handleBestaetigung
    }
  };

  return (
    <>
      {bestaetigungState && <CoLoeschenbestaetigung objParent={objPakettemplate.coLoeschenbestaetigung} options={{ text: bestaetigungTextState }} />}

      {paketeState.length ? (
        <div className="pakettemplate">
          <div className="pakettemplate__header">
            <div className="pakettemplate__headerSelection">
              <Typography className="typography" align="center">
                Abgeschlossene Pakete
              </Typography>
            </div>
          </div>
          <div className="margin__top20">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox className="checkbox__blue" size="small" checked={selectAllCheckboxState} onChange={toggleAllPaketeCheck} />}
                label={selectAllCheckboxState ? 'Alle abwählen' : 'Alle auswählen'}
              />
            </FormGroup>
          </div>
          <div className="pakettemplate__body--max300">
            <TableContainer>
              <Table size="medium" stickyHeader>
                <TableBody>
                  {paketeState.map((paket, index) => (
                    <TableRow key={`coVersandPaket_pakettabelle_tabllenbody_row${index}`} className="versandpaket__tablerow_nopadding">
                      <TableCell align="center">
                        <Grid container alignItems="center" className="width__maxContent">
                          <Checkbox className="checkbox__blue" size="small" checked={paket.checked} onChange={(e) => togglePaketCheck(e, paket.id)} />
                          <Typography className="typography pakettemplate__bodyArticleName">{paket.name}</Typography>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <Button onClick={() => generatePDF(paket.id)} className="button__small--blue width__134">
                          Inhaltsliste drucken
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton title="Paket öffnen" onClick={() => makeActionToPaketAndOpenWarning('paketOeffnen', paket.id)}>
                          <Lock className="icon__small--darkblue" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div>
            <Button
              onClick={() => makeActionToPaketAndOpenWarning('paketeZumVersandBereitstellen')}
              disabled={!paketeState.some((paket) => paket.checked)}
              fullWidth
              className={`${paketeState.some((paket) => paket.checked) ? 'button__middle--blue' : 'button__middle--disabled'} margin__top10`}
            >
              Zur Abholung anmelden
            </Button>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CoVersandPaket;
