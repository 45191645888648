import React from 'react';

// import -> material-ui
import { Box } from '@material-ui/core';

// import -> components
import CoTopBar from './Append/CoTopBar';
import CoTopNav from './Append/CoTopNav';

const CoNavigation = () => {
  return (
    <Box>
      <CoTopBar />
      <CoTopNav />
    </Box>
  );
};

export default CoNavigation;
