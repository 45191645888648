import React, { useEffect, useState } from 'react';

// import -> common
import { createAxiosInstance } from '../Common/functions/axios';

// import -> reducer
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { FormControl, Select, MenuItem, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, InputLabel } from '@material-ui/core';

// import -> components
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../Common/constants/tableSorting';
import { showHeaderWithSorting, sortTable } from '../Common/functions/tableSorting';
import { SearchOutlined } from '@material-ui/icons';
import CoPaginationTableFooter from '../Common/components/js/CoPaginationTableFooter';

const INITIAL_ELEMENTS_PER_PAGE = 5;

const CoBestelluebersicht = () => {
  const [globalState, _] = useStateValue();

  const isLieferant = globalState.authentication.user.access === 'LIEFERANT';
  const isAdmin = globalState.authentication.user.access === 'ADMIN' || globalState.authentication.user.access === 'SUPER_USER';

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [artikelState, setArtikelState] = useState([]);
  const [lieferantenState, setLieferantenState] = useState([]);
  const [selectedLieferantState, setSelectedLieferantState] = useState('alle');
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [filterInputState, setFilterInputState] = useState('');
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.PZN]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.BEZEICHNUNG]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.VERFALLSDATUM]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    // [SORTING_TYPES.SERIENNUMMER]: {
    //   sortingDir: SORTING_DIRECTIONS.NO_SORTING,
    //   isSorted: false
    // },
    [SORTING_TYPES.LIEFERANT]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    // [SORTING_TYPES.CHARGE]: {
    //   sortingDir: SORTING_DIRECTIONS.NO_SORTING,
    //   isSorted: false
    // },
    [SORTING_TYPES.ARZNEIMITTELLISTE]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });

  const [elementsPerPageState, setElementsPerPageState] = useState(INITIAL_ELEMENTS_PER_PAGE);
  const [numOfPagesState, setNumOfPagesState] = useState(1);
  const [currentPageState, setCurrentPageState] = useState(1);
  const [showTableState, setShowTableState] = useState(false);

  // const tableHeaders =
  //   isAdmin && selectedLieferantState === 'alle'
  //     ? ['LIEFERANT', 'BEZEICHNUNG', 'PZN', 'VERFALLSDATUM', 'SERIENNUMMER', 'CHARGE', 'ANZAHLPACKUNG', 'ARZNEIMITTELLISTE', 'VERSANDDATUM']
  //     : isAdmin
  //     ? ['BEZEICHNUNG', 'PZN', 'VERFALLSDATUM', 'SERIENNUMMER', 'CHARGE', 'ANZAHLPACKUNG', 'ARZNEIMITTELLISTE', 'VERSANDDATUM']
  //     : ['BEZEICHNUNG', 'PZN', 'VERFALLSDATUM', 'ANZAHLPACKUNG', 'ARZNEIMITTELLISTE'];

  const tableHeaders =
    isAdmin && selectedLieferantState === 'alle'
      ? ['LIEFERANT', 'PZN', 'BEZEICHNUNG', 'VERFALLSDATUM', 'ARZNEIMITTELLISTE']
      : isAdmin
      ? ['PZN', 'BEZEICHNUNG', 'VERFALLSDATUM', 'ARZNEIMITTELLISTE']
      : ['PZN', 'BEZEICHNUNG', 'VERFALLSDATUM', 'ARZNEIMITTELLISTE'];

  // function ->
  const handleGetArtikelSuccess = (artikelDaten, isWithLieferant) => {
    const totalElements = artikelDaten.totalElements;
    const elementsPerPage = elementsPerPageState.length === 0 ? INITIAL_ELEMENTS_PER_PAGE : +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    setArtikelState(
      artikelDaten.content.map((artikel) => {
        if (isWithLieferant) {
          return {
            lieferant: artikel.lieferant,
            bezeichnung: artikel.arzneimittellistenEintrag.arzneimittel.bezeichnung,
            pzn: artikel.arzneimittellistenEintrag.arzneimittel.pzn,
            arzneimittelliste: artikel.arzneimittellistenEintrag.arzneimittelListe.name,
            verfallsdatum: artikel.arzneimittellistenEintrag.verfallsdatum
          };
        }
        return {
          bezeichnung: artikel.arzneimittel.bezeichnung,
          pzn: artikel.arzneimittel.pzn,
          arzneimittelliste: artikel.arzneimittelListe.name,
          verfallsdatum: artikel.verfallsdatum
        };
      })
    );
    setShowTableState(true);
    setRerunEffectState((pre) => !pre);
  };

  // function ->
  const getArtikel = (isAdmin, lieferantId) => {
    const page = currentPageState > numOfPagesState ? numOfPagesState - 1 : currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState.length === 0 ? INITIAL_ELEMENTS_PER_PAGE : +elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    // const requestUrl =
    // isAdmin && lieferantId === 'alle'
    //   ? `/paket/artikel?page=${page}&size=${size}&sort=id,desc`
    //   : isAdmin
    //   ? `/paket/${lieferantId}/artikel?page=${page}&size=${size}&sort=id,desc`
    //   : `/paket/artikel?page=${page}&size=${size}&sort=id,desc`;

    const isForAllLieferantenOrUserLieferant = (isAdmin && lieferantId === 'alle') || !isAdmin;

    const requestUrl = isForAllLieferantenOrUserLieferant
      ? `/arzneimittel-listen/all/arzneimittel?page=${page}&size=${size}&sort=id,desc`
      : `/arzneimittel-listen/all/${lieferantId}/arzneimittel?page=${page}&size=${size}&sort=id,desc`;

    authAxios
      .post(requestUrl, {
        string: filterInputState
      })
      .then((response) => {
        const artikelDaten = response.data;
        handleGetArtikelSuccess(artikelDaten, isForAllLieferantenOrUserLieferant);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const changeLieferanten = (event) => {
    setSelectedLieferantState(event.target.value);
    getArtikel(true, event.target.value);
  };

  // function ->
  const getLieferanten = () => {
    authAxios
      .get('/user/lieferanten')
      .then((response) => {
        setLieferantenState(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (isLieferant) {
      getArtikel();
    } else if (isAdmin && selectedLieferantState) {
      getArtikel(true, selectedLieferantState);
    }

    return () => {
      isMounted = false;
    };
  }, [currentPageState, elementsPerPageState, filterInputState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (isAdmin) {
      getLieferanten();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // function ->
  const filterTable = (event) => {
    setCurrentPageState('');
    setFilterInputState(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, artikelState, setArtikelState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objBestelluebersicht = {
    paginationTableFooter: {
      currentPageState,
      setCurrentPageState,
      setElementsPerPageState,
      numOfPagesState,
      setNumOfPagesState,
      firstCellColSpan: 2,
      secondCellColSpan: isAdmin && selectedLieferantState === 'alle' ? 2 : 1,
      thirdCellColSpan: 1
    }
  };

  return (
    <>
      <CoSeitenstruktur seitentitel="BESTELLUEBERSICHT">
        <Grid item container spacing={3} className="margin__top30">
          {isAdmin ? (
            <Grid item xs={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="CoBestelluebersicht__lieferantauswahl">Lieferant</InputLabel>
                <Select value={selectedLieferantState} onChange={changeLieferanten} labelId="CoBestelluebersicht__lieferantauswahl" label="Lieferant">
                  <MenuItem value="alle">Alle Lieferanten</MenuItem>
                  {lieferantenState.map((lieferant, index) => {
                    return (
                      <MenuItem key={`lieferant_option_${index}`} value={lieferant.id}>
                        {lieferant.username}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            {showTableState ? (
              <Paper>
                <TextField
                  fullWidth
                  vallue={filterInputState}
                  onChange={filterTable}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    )
                  }}
                  className="table__searchBar"
                  variant="standard"
                />
                <TableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow className="table__title">
                        {tableHeaders.map((header, index) => {
                          return (
                            <TableCell align="center" component="th" key={`CoBestelluebersicht_paketentabelle_tabllenheader_${index}`}>
                              {showHeaderWithSorting(header, sortingState, setSortingState, artikelState, setArtikelState)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {artikelState.map((artikel, index) => {
                        const { lieferant, pzn, verfallsdatum, bezeichnung, arzneimittelliste, anzahl, seriennummer, charge, versanddatum } = artikel;
                        return (
                          <TableRow key={`coLieferuebersicht_artikeltabelle_tableBody_row${index}`}>
                            {isAdmin && selectedLieferantState === 'alle' ? (
                              <TableCell align="center" className="table__cell">
                                <Typography>{lieferant}</Typography>
                              </TableCell>
                            ) : (
                              ''
                            )}
                            <TableCell align="center" className="table__cell">
                              <Typography>{pzn}</Typography>
                            </TableCell>
                            <TableCell align="center" className="table__cell">
                              <Typography>{bezeichnung}</Typography>
                            </TableCell>
                            <TableCell align="center" className="table__cell">
                              <Typography>{verfallsdatum}</Typography>
                            </TableCell>
                            {/* {isAdmin ? (
                              <>
                                <TableCell align="center" className="table__cell">
                                  <Typography>{seriennummer}</Typography>
                                </TableCell>
                                <TableCell align="center" className="table__cell">
                                  <Typography>{charge}</Typography>
                                </TableCell>
                              </>
                            ) : (
                              ''
                            )}
                            <TableCell align="center" className="table__cell">
                              <Typography>{anzahl}</Typography>
                            </TableCell> */}
                            <TableCell align="center" className="table__cell">
                              <Typography>{arzneimittelliste}</Typography>
                            </TableCell>
                            {/* <TableCell align="center" className="table__cell">
                              <Typography>{versanddatum}</Typography>
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <CoPaginationTableFooter objParent={objBestelluebersicht.paginationTableFooter} />
                  </Table>
                </TableContainer>
              </Paper>
            ) : (
              ''
            )}

            <br />
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
      </CoSeitenstruktur>
    </>
  );
};

export default CoBestelluebersicht;
