// import -> common
import { LOCALSTORAGE_ITEMS } from '../components/Common/constants/localStorageItems';

const initialState = {
  authentication: localStorage.getItem(LOCALSTORAGE_ITEMS.MEDICHECKAUTHENTICATION)
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE_ITEMS.MEDICHECKAUTHENTICATION))
    : {
        auth: false,
        user: { access: [''], name: '' },
        token: ''
      },
  backdropOpen: false,
  canNavigate: true,
  canCameraStop: false,
  cameraAvailable: true,
  cameraWarningEnabled: true
};
export default initialState;
