import React, { useState } from 'react';

// import -> common
import { changeTextFieldsOnChange } from '../../Common/functions/form';
import { sprache } from '../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../reducer/StateProvider';
import dispatchActions from '../../../reducer/dispatchActions';

// import -> material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const TEXT_FIELD_NAMEN = {
  NAME: 1,
  PASSWORT: 2,
  EMAIL: 3
};

// component -> React-root
const CoSuperUserErstellenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();
  const [textFieldsState, setTextFieldsState] = useState({
    fehlerZeigen: false,
    [TEXT_FIELD_NAMEN.NAME]: {
      wert: '',
      fehler: true
    },
    [TEXT_FIELD_NAMEN.PASSWORT]: {
      wert: '',
      fehler: true
    },
    [TEXT_FIELD_NAMEN.EMAIL]: {
      wert: '',
      fehler: true
    }
  });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseSuperUsersErstellenDialog(false);
  };

  // function ->
  const superErstellenRequest = (name, passwort, email) => {
    const requestBody = { username: name, password: passwort, email };
    objParent.authAxios
      .post('/super-user', requestBody)
      .then(() => {
        closeDialog();
        objParent.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const superUserErstellenOnSubmit = (event) => {
    event.preventDefault();

    const name = textFieldsState[TEXT_FIELD_NAMEN.NAME];
    const passwort = textFieldsState[TEXT_FIELD_NAMEN.PASSWORT];
    const email = textFieldsState[TEXT_FIELD_NAMEN.EMAIL];

    const namefehler = name.fehler;
    const passwortfehler = passwort.fehler;
    const emailfehler = email.fehler;

    if (namefehler || passwortfehler || emailfehler) {
      setTextFieldsState((pre) => ({
        ...pre,
        fehlerZeigen: true
      }));
      return;
    }

    const namewert = name.wert;
    const passwortwert = passwort.wert;
    const emailwert = email.wert;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    superErstellenRequest(namewert, passwortwert, emailwert);
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={objParent.superUserErstellenDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              Neuen USER anlegen
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={superUserErstellenOnSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                value={textFieldsState[TEXT_FIELD_NAMEN.NAME].wert}
                onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.NAME, setTextFieldsState)}
                error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler}
                helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.NAME].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                fullWidth
                color="primary"
                variant="outlined"
                type="text"
                autoFocus
                label={sprache('FORM', 'LABEL', 'NAME')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={textFieldsState[TEXT_FIELD_NAMEN.EMAIL].wert}
                onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.EMAIL, setTextFieldsState)}
                error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.EMAIL].fehler}
                helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.EMAIL].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                fullWidth
                type="email"
                color="primary"
                variant="outlined"
                label="E-Mail"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={textFieldsState[TEXT_FIELD_NAMEN.PASSWORT].wert}
                onChange={(event) => changeTextFieldsOnChange(event, TEXT_FIELD_NAMEN.PASSWORT, setTextFieldsState)}
                error={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PASSWORT].fehler}
                helperText={textFieldsState.fehlerZeigen && textFieldsState[TEXT_FIELD_NAMEN.PASSWORT].fehler && sprache('FORM', 'FEHLER', 'LEERESTEXTFELD')}
                fullWidth
                color="primary"
                variant="outlined"
                type="password"
                label={sprache('FORM', 'LABEL', 'PASSWORT')}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth className="button__middle--blue" type="submit">
                {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth className="button__middle--red" onClick={closeDialog}>
                {sprache('BUTTON', 'TITEL', 'ABBRECHEN')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CoSuperUserErstellenDialog;
