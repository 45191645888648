import React, { useEffect, useState } from 'react';

// import -> material-ui
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Close, SearchOutlined } from '@material-ui/icons';
import { showHeaderWithSorting, sortTable } from '../../Common/functions/tableSorting';
import { SORTING_DIRECTIONS, SORTING_TYPES } from '../../Common/constants/tableSorting';
import { filterTableOnChange } from '../../Common/functions/tableFiltering';
import { createAxiosInstance } from '../../Common/functions/axios';
import useStateValue from '../../../reducer/StateProvider';

// component -> React-root
const CoArtikelTableDialog = ({ objParent }) => {
  const [globalState, _] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [artikelState, setArtikelState] = useState([]);
  const [filteredArtikelState, setFilteredArtikelState] = useState([]);
  const [sortedFilteredArtikelState, setSortedFilteredArtikelState] = useState([]);
  const [filterInputState, setFilterInputState] = useState('');
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [sortingState, setSortingState] = useState({
    [SORTING_TYPES.BEZEICHNUNG]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.PZN]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.VERFALLSDATUM]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.ARZNEIMITTELLISTE]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.SERIENNUMMER]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.CHARGE]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    },
    [SORTING_TYPES.ANZAHLPACKUNG]: {
      sortingDir: SORTING_DIRECTIONS.NO_SORTING,
      isSorted: false
    }
  });

  const isAdmin = globalState.authentication.user.access === 'ADMIN' || globalState.authentication.user.access === 'SUPER_USER';

  const tableHeaders = isAdmin
    ? ['PZN', 'BEZEICHNUNG', 'VERFALLSDATUM', 'SERIENNUMMER', 'CHARGE', 'ANZAHLPACKUNG', 'ARZNEIMITTELLISTE']
    : ['PZN', 'BEZEICHNUNG', 'VERFALLSDATUM', 'ANZAHLPACKUNG', 'ARZNEIMITTELLISTE'];

  // function ->
  const callFilterTableOnChange = (eventOrEventMock) => {
    let boundedFunction;
    if (isAdmin) {
      boundedFunction = filterTableOnChange.bind(
        null,
        eventOrEventMock,
        setFilterInputState,
        artikelState,
        setFilteredArtikelState,
        'pzn',
        'verfallsdatum',
        'bezeichnung',
        'arzneimittelliste',
        'anzahl',
        'seriennummer',
        'charge'
      );
    } else {
      boundedFunction = filterTableOnChange.bind(
        null,
        eventOrEventMock,
        setFilterInputState,
        artikelState,
        setFilteredArtikelState,
        'pzn',
        'verfallsdatum',
        'bezeichnung',
        'arzneimittelliste',
        'anzahl'
      );
    }
    boundedFunction();
    setRerunEffectState((pre) => !pre);
  };

  useEffect(() => {
    authAxios
      .get(`/paket/${objParent.artikelTableDialogState.paketId}/artikel`)
      .then((response) => {
        const newState = response.data.map((artikel) => {
          const {
            anzahl,
            arzneimittellistenEintrag: {
              verfallsdatum,
              charge,
              seriennummer,
              arzneimittel: { pzn, bezeichnung },
              arzneimittelListe: { name: arzneimittelliste }
            }
          } = artikel;
          return { anzahl: '' + anzahl, charge, seriennummer, pzn, verfallsdatum, bezeichnung, arzneimittelliste };
        });
        setArtikelState(newState);
        setFilteredArtikelState(newState);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    let sortingColumn = {};

    for (let key in sortingState) {
      if (sortingState[key].isSorted) {
        sortingColumn = sortingState[key];
        sortingColumn.sortingType = key;
        break;
      }
    }

    if (Object.keys(sortingColumn).length !== 0) {
      sortTable(sortingColumn.sortingType, sortingState, setSortingState, filteredArtikelState, setSortedFilteredArtikelState, true);
    }

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseArtikelTableDialog(false);
  };

  const currentArtikelStateToMap = sortedFilteredArtikelState.length ? sortedFilteredArtikelState : filteredArtikelState;

  return (
    <Dialog fullWidth maxWidth="lg" onClose={closeDialog} open={objParent.artikelTableDialogState.open}>
      <DialogTitle>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography__dialogTitle" align="left">
              {objParent.artikelTableDialogState.paketName} - Artikel
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper>
              <TextField
                fullWidth
                value={filterInputState}
                onChange={(event) => callFilterTableOnChange(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  )
                }}
                className="table__searchBar"
                variant="standard"
              />
              <TableContainer>
                <Table size="medium" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((header, index) => {
                        return (
                          <TableCell align="center" component="th" key={`coLieferuebersicht_artikeltabelle_tableHeader_${index}`}>
                            {showHeaderWithSorting(header, sortingState, setSortingState, filteredArtikelState, setSortedFilteredArtikelState)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentArtikelStateToMap.map((artikel, index) => {
                      const { pzn, verfallsdatum, bezeichnung, arzneimittelliste, anzahl, seriennummer, charge } = artikel;
                      return (
                        <TableRow key={`coLieferuebersicht_artikeltabelle_tableBody_row${index}`}>
                          <TableCell align="center" className="table__cell">
                            <Typography>{pzn}</Typography>
                          </TableCell>
                          <TableCell align="center" className="table__cell">
                            <Typography>{bezeichnung}</Typography>
                          </TableCell>
                          <TableCell align="center" className="table__cell">
                            <Typography>{verfallsdatum}</Typography>
                          </TableCell>
                          {isAdmin ? (
                            <>
                              <TableCell align="center" className="table__cell">
                                <Typography>{seriennummer}</Typography>
                              </TableCell>
                              <TableCell align="center" className="table__cell">
                                <Typography>{charge}</Typography>
                              </TableCell>
                            </>
                          ) : (
                            ''
                          )}
                          <TableCell align="center" className="table__cell">
                            <Typography>{anzahl}</Typography>
                          </TableCell>
                          <TableCell align="center" className="table__cell">
                            <Typography>{arzneimittelliste}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoArtikelTableDialog;
