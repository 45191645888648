import React, { useEffect, useState } from 'react';

// import -> common
import { sprache } from '../../../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../../../reducer/StateProvider';
import dispatchActions from '../../../../reducer/dispatchActions';

// import -> material-ui
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoLieferantengruppentabelle from '../../../Lieferanten/Append/Gruppe/CoLieferantengruppentabelle';
import CoLieferantentabelle from '../../../Lieferanten/Append/Single/CoLieferantentabelle';

// component -> React-root
const CoLieferantengruppenZeigenDialog = ({ objParent }) => {
  const [_, dispatch] = useStateValue();

  const [rerunEffectState, setRerunEffectState] = useState(true);
  const [lieferantengruppenState, setLieferantengruppenState] = useState([]);
  const [lieferantentabelleState, setLieferantentabelleState] = useState({ open: false, gruppeId: '' });

  // function ->
  const closeDialog = () => {
    objParent.openOrCloseLieferantengruppenZeigenDialog(false);
  };

  // function ->
  const openOrCloseLieferantentabelle = (shouldOpen, gruppeId, name) => {
    setLieferantentabelleState({ open: shouldOpen, gruppeId: gruppeId ?? '', name: name ?? '' });
  };

  // function ->
  const lieferantengruppenHolen = () => {
    const listeId = objParent.lieferantengruppenZeigenDialogState.arzneimittelliste.id;

    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    objParent.authAxios
      .get(`/arzneimittel-listen/${listeId}/lieferantengruppen`)
      .then((response) => {
        const gruppen = response.data;
        setLieferantengruppenState(gruppen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    lieferantengruppenHolen();

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objLieferantengruppenZeigen = {
    coLieferantengruppentabelle: {
      lieferantengruppenState,
      authAxios: objParent.authAxios,
      openOrCloseLieferantentabelle,
      rerunEffectState,
      setRerunEffectState,
      istBenutzerAdmin: true,
      istVonArzneimittelliste: true,
      arzneimittellisteId: objParent.lieferantengruppenZeigenDialogState.arzneimittelliste.id
    },
    coLieferantentabelle: {
      lieferantentabelleState,
      authAxios: objParent.authAxios,
      openOrCloseLieferantentabelle,
      rerunEffectState,
      setRerunEffectState
    }
  };

  return (
    <>
      {lieferantentabelleState.open && <CoLieferantentabelle objParent={objLieferantengruppenZeigen.coLieferantentabelle} />}
      <Dialog fullWidth maxWidth="lg" onClose={closeDialog} open={objParent.lieferantengruppenZeigenDialogState.open}>
        <DialogTitle>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography className="typography__dialogTitle" align="left">
                {sprache('DIALOG', 'TITEL', 'LIEFERANTENGRUPPEN')}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className="typography__dialogSubtitle">
                {sprache('TEXT', 'KURZ', 'ARZNEIMITTELLISTE')}: {objParent.lieferantengruppenZeigenDialogState.arzneimittelliste.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CoLieferantengruppentabelle objParent={objLieferantengruppenZeigen.coLieferantengruppentabelle} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoLieferantengruppenZeigenDialog;
