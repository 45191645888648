// function ->
export const stopVideo = () => {
  const stream = document.querySelector('video')?.srcObject;
  if (!stream) {
    return;
  }
  const tracks = stream.getTracks();
  tracks.forEach((track) => {
    track.stop();
  });
};
