import { BinaryBitmap, BrowserMultiFormatReader, HTMLCanvasElementLuminanceSource, HybridBinarizer } from '@zxing/library';

class BrowserMultiFormatReaderInverse extends BrowserMultiFormatReader {
  enumerator = 0;
  createBinaryBitmap(mediaElement) {
    this.getCaptureCanvasContext(mediaElement);
    if (this._isHTMLVideoElement(mediaElement)) {
      this.drawFrameOnCanvas(mediaElement);
    } else {
      this.drawImageOnCanvas(mediaElement);
    }
    const canvas = this.getCaptureCanvas(mediaElement);

    const luminanceSource = new HTMLCanvasElementLuminanceSource(canvas);
    const hybridBinarizer = new HybridBinarizer(luminanceSource);
    const invertedHybridBinarizer = new HybridBinarizer(luminanceSource.invert());

    this.enumerator++;

    return new BinaryBitmap(this.enumerator % 2 ? hybridBinarizer : invertedHybridBinarizer);
  }
}

export default BrowserMultiFormatReaderInverse;
