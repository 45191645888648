import React, { useEffect, useState } from 'react';

// import -> common
import { createAxiosInstance, handleAndDownloadblobResponse } from '../Common/functions/axios';
import { csvTypes } from '../Common/constants/csvTypes';
import { sprache } from '../Common/sprache/sprache';

// import -> reducer
import useStateValue from '../../reducer/StateProvider';
import dispatchActions from '../../reducer/dispatchActions';

// import -> material-ui
import { Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@material-ui/core';
import { Add, AddBox, FormatListNumbered, VerticalAlignBottom, VerticalAlignTop } from '@material-ui/icons';

// import -> components
import CoLieferantenErstellenBearbeitenDialog from './Append/Single/CoLieferantenErstellenBearbeitenDialog';
import CoLieferantengruppeErstellenBearbeitenDialog from './Append/Gruppe/CoLieferantengruppeErstellenBearbeiten';
import CoLieferantengruppentabelle from './Append/Gruppe/CoLieferantengruppentabelle';
import CoLieferantentabelle from './Append/Single/CoLieferantentabelle';
import CoFehlermeldung from '../Common/components/js/CoFehlermeldung';
import CoSeitenstruktur from '../Common/components/js/CoSeitenstruktur';

const CoLieferanten = () => {
  const [globalState, dispatch] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [lieferantengruppenState, setLieferantengruppenState] = useState([]);
  const [lieferantenErstellenBearbeitenDialogState, setLieferantenErstellenBearbeitenDialogState] = useState({ open: false });
  const [lieferantengruppeErstellenBearbeitenDialogState, setLieferantengruppeErstellenBearbeitenDialogState] = useState({ open: false });
  const [lieferantentabelleState, setLieferantentabelleState] = useState({ open: false, gruppeId: '', name: '' });
  const [csvLieferantengruppeState, setCsvLieferantengruppeState] = useState({});
  const [csvFehlerState, setCsvFehlerState] = useState([]);
  const [csvUeberschreibenState, setCsvUeberschreibenState] = useState(false);
  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [fileInputState, setFileInputState] = useState('');

  // function ->
  const openOrCloseLieferantentabelle = (shouldOpen, gruppeId, name) => {
    setLieferantentabelleState({ open: shouldOpen, gruppeId: gruppeId ?? '', name: name ?? '' });
  };

  // function ->
  const openOrCloseLieferantenErstellenBearbeitenDialog = (shouldOpen) => {
    setLieferantenErstellenBearbeitenDialogState({ open: shouldOpen, erstellen: true, lieferant: {} });
  };

  // function ->
  const openOrCloseLieferantengruppeErstellenBearbeitenDialog = (shouldOpen) => {
    setLieferantengruppeErstellenBearbeitenDialogState({ open: shouldOpen, erstellen: true });
  };

  // function ->
  const echoCsvFehler = () => {
    return csvFehlerState
      .sort((fehlerA, fehlerB) => (+fehlerA.zeile > +fehlerB.zeile ? 1 : -1))
      .map((fehler) => {
        const { zeile, errorMessage } = fehler;
        const partOne = zeile ? `Zeile: ${zeile}. ` : '';
        const partTwo = `Fehler: ${errorMessage}`;

        const fehlernachricht = partOne + partTwo;

        return <CoFehlermeldung objParent={{ fehlermeldungState: { text: fehlernachricht } }} />;
      });
  };

  // function ->
  const musterherunterladenOnClick = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .get('/csv/muster/lieferantengruppe', { responseType: 'blob' })
      .then((response) => {
        const { data } = response;
        handleAndDownloadblobResponse(data, '.csv', 'Lieferantengruppenmuster');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const lieferantenCodesHerunterladen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .get('/lieferanten/qr-codes', { responseType: 'blob' })
      .then((response) => {
        const { data } = response;
        handleAndDownloadblobResponse(data, 'octet/stream', 'qr-codes');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const handleCsvUploadOnChange = (event) => {
    const datei = event.target.files[0];
    const inputValue = event.target.value;
    setFileInputState(inputValue);
    if (!datei) return;
    const dateiType = datei.type;
    if (csvTypes.includes(dateiType)) {
      setCsvLieferantengruppeState(datei);
    }
  };

  // function ->
  const handleCsvUeberschreibenOnChange = (event) => {
    const ueberschreiben = event.target.checked;
    setCsvUeberschreibenState(ueberschreiben);
  };

  // function ->
  const anfrageCsvSpeichernerfolgHandeln = () => {
    setCsvLieferantengruppeState({});
    setCsvUeberschreibenState(false);
    setFileInputState('');
    setRerunEffectState((pre) => !pre);
  };

  // function ->
  const csvLieferantengruppeSpeichern = () => {
    const formData = new FormData();
    formData.append('file', csvLieferantengruppeState);
    formData.append('ersetzen', csvUeberschreibenState);
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .post('/csv/lieferanten', formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then((response) => {
        anfrageCsvSpeichernerfolgHandeln();
        const errorsArr = response.data.errors;
        setCsvFehlerState(errorsArr);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  // function ->
  const anfrageLieferantenlsitenerfolgHandeln = (gruppen) => {
    setLieferantengruppenState(gruppen);
  };

  // function ->
  const lieferantengruppenHolen = () => {
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });

    authAxios
      .get('/lieferantengruppen')
      .then((response) => {
        const gruppen = response.data;
        anfrageLieferantenlsitenerfolgHandeln(gruppen);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) {
      return;
    }

    lieferantengruppenHolen();

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState]);

  const objLieferanten = {
    coLieferantengruppentabelle: {
      lieferantengruppenState,
      setLieferantengruppenState,
      authAxios,
      openOrCloseLieferantentabelle,
      rerunEffectState,
      setRerunEffectState
    },
    coLieferantenerstellenBearbeitenDialog: {
      lieferantenErstellenBearbeitenDialogState,
      authAxios,
      openOrCloseLieferantenErstellenBearbeitenDialog
    },
    coLieferantengruppeErstellenBearbeitenDialog: {
      lieferantengruppeErstellenBearbeitenDialogState,
      authAxios,
      openOrCloseLieferantengruppeErstellenBearbeitenDialog,
      setRerunEffectState
    },
    coLieferantentabelle: {
      lieferantentabelleState,
      authAxios,
      openOrCloseLieferantentabelle,
      rerunEffectState,
      setRerunEffectState
    }
  };

  return (
    <>
      {lieferantentabelleState.open && <CoLieferantentabelle objParent={objLieferanten.coLieferantentabelle} />}
      {lieferantenErstellenBearbeitenDialogState.open && <CoLieferantenErstellenBearbeitenDialog objParent={objLieferanten.coLieferantenerstellenBearbeitenDialog} />}
      {lieferantengruppeErstellenBearbeitenDialogState.open && <CoLieferantengruppeErstellenBearbeitenDialog objParent={objLieferanten.coLieferantengruppeErstellenBearbeitenDialog} />}
      <CoSeitenstruktur seitentitel="LIEFERANTEN">
        <Grid item container spacing={3} className="margin__top30">
          <Grid item xs={12}>
            <Link component="button" className="button__link--blue" onClick={lieferantenCodesHerunterladen}>
              <VerticalAlignBottom style={{ marginBottom: -3 }} /> {sprache('BUTTON', 'TITEL', 'LIEFERANTENQRCODESHERUNTERLADEN')}
            </Link>
          </Grid>
          <Grid item container xs={12} md="auto" spacing={3}>
            <Grid item xs={12}>
              {echoCsvFehler()}
            </Grid>
            <Grid item xs={12} sm="auto">
              <input type="file" accept={csvTypes.join(',')} style={{ display: 'none' }} id="lieferanten_input_file_lieferantengruppe" value={fileInputState} onChange={handleCsvUploadOnChange} />
              <label htmlFor="lieferanten_input_file_lieferantengruppe">
                <Button startIcon={<VerticalAlignTop />} component="span" className="button__middle--red">
                  {sprache('BUTTON', 'TITEL', 'LIEFERANTENLISTEHOCHLADEN')}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Link component="button" className="button__link--blue" onClick={musterherunterladenOnClick}>
                {sprache('BUTTON', 'TITEL', 'MUSTERCSVDATEIHERUNTERLADEN')}
              </Link>
            </Grid>
            {csvLieferantengruppeState.name && (
              <Grid item xs={12}>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <Typography className="typography__breakWord">{csvLieferantengruppeState.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      checked={csvUeberschreibenState}
                      onChange={handleCsvUeberschreibenOnChange}
                      control={<Checkbox color="primary" />}
                      label={sprache('FORM', 'LABEL', 'EXISTIERENDLISTEÜBERSCHREIBEN')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button className="button__middle--red" onClick={csvLieferantengruppeSpeichern}>
                      {sprache('BUTTON', 'TITEL', 'SPEICHERN')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md="auto">
            <Button startIcon={<AddBox />} className="button__middle--blue" onClick={openOrCloseLieferantengruppeErstellenBearbeitenDialog.bind(null, true)}>
              {sprache('BUTTON', 'TITEL', 'LIEFERANTENGRUPPEERSTELLEN')}
            </Button>
          </Grid>
          <Grid item xs={12} md="auto">
            <Button startIcon={<Add />} className="button__middle--green" onClick={openOrCloseLieferantenErstellenBearbeitenDialog.bind(null, true)}>
              {sprache('BUTTON', 'TITEL', 'LIEFERANTENERSTELLEN')}
            </Button>
          </Grid>
          <Grid item xs={12} md="auto">
            <Button startIcon={<FormatListNumbered />} className="button__middle--orange" onClick={openOrCloseLieferantentabelle.bind(null, true, null, null)}>
              {sprache('BUTTON', 'TITEL', 'LIEFERANTENANSEHEN')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CoLieferantengruppentabelle objParent={objLieferanten.coLieferantengruppentabelle} />
          </Grid>
        </Grid>
      </CoSeitenstruktur>
    </>
  );
};

export default CoLieferanten;
