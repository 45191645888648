import React, { useEffect, useRef, useState } from 'react';
import { NotFoundException } from '@zxing/library';

// import -> common
import { sprache } from '../Common/sprache/sprache';
import CoBerechtigungleitfadenDialog from '../Common/components/js/CoBerechtigungleitfadenDialog';

// import -> reducer
import dispatchActions from '../../reducer/dispatchActions';
import useStateValue from '../../reducer/StateProvider';

// import -> material-ui
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Link, IconButton } from '@material-ui/core';
import { Close, PriorityHigh } from '@material-ui/icons';

import BrowserMultiFormatReaderInverse from './BrowserMultiFormatReader';

// component -> react-root
const WhenCameraAvailable = ({ objParent }) => {
  const [globalState, dispatch] = useStateValue();
  const [videoInputDevicesState, setVideoInputDevicesState] = useState([]);
  const [selectedDeviceIdState, setSelectedDeviceIdState] = useState('');

  const codeReaderRef = useRef(new BrowserMultiFormatReaderInverse());

  // function ->
  const handleKameraAendernOnChange = (event) => {
    const deviceId = event.target.value;
    setSelectedDeviceIdState(deviceId);
  };

  // function ->
  const denyNavigation = () => {
    if (!globalState.cameraAvailable) return;
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: true });
    dispatch({ type: dispatchActions.denyNavigation });
  };

  // function ->
  const allowStoppingTheCamera = () => {
    dispatch({ type: dispatchActions.allowStoppingTheCamera });
    dispatch({ type: dispatchActions.toggleOpenBackdrop, payload: false });
  };

  // function ->
  const denyStoppingTheCamera = () => {
    dispatch({ type: dispatchActions.denyStoppingTheCamera });
  };

  // function ->
  const startScanner = (selectedDeviceId) => {
    denyStoppingTheCamera();
    denyNavigation();

    const codeReader = codeReaderRef.current;
    codeReader.timeBetweenDecodingAttempts = 0;

    codeReader.stopContinuousDecode();

    // after the camera start maximum after 2 seconds then allow the navigation
    setTimeout(allowStoppingTheCamera, 2000);
    codeReader
      .decodeFromVideoDevice(selectedDeviceId, 'coDataMatrixScanner_video', (result, err) => {
        if (result) {
          objParent.handleScannerergebnis(result.text);
        }
        if (err && !(err instanceof NotFoundException)) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: dispatchActions.removeCamera });
        allowStoppingTheCamera();
      });
  };

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((e) => {
          const videoInputDevices = e.filter((e) => e.kind === 'videoinput');
          if (!videoInputDevices[0]?.deviceId) {
            dispatch({ type: dispatchActions.removeCamera });
            allowStoppingTheCamera();
          }
          setVideoInputDevicesState(videoInputDevices);
          setSelectedDeviceIdState(videoInputDevices[0]?.deviceId);
        })
        .catch((err) => {
          console.error(err);
          dispatch({ type: dispatchActions.removeCamera });
          allowStoppingTheCamera();
        });
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (selectedDeviceIdState && objParent.scannerZeigenState) {
      startScanner(selectedDeviceIdState);
    }

    return () => {
      isMounted = false;
      codeReaderRef.current.stopContinuousDecode();
    };
  }, [objParent.scannerZeigenState, selectedDeviceIdState]);

  if (!globalState.cameraAvailable) {
    return <WhenNoCameraAvailable />;
  }

  return (
    <Box style={{ overflow: 'hidden' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <video id="coDataMatrixScanner_video" className="coDataMatrixScanner__video" width="300" height="200"></video>
          <audio id="coDataMatrixScanner_audio" style={{ display: 'none' }}>
            <source src="./assets/voices/scanner_beeb.mp3" type="audio/mpeg"></source>
          </audio>
        </Grid>
        {Boolean(selectedDeviceIdState) && (
          <Grid item xs={12}>
            <FormControl className="width__max200" variant="outlined">
              <InputLabel id="coDataMatrixScanner_selectKameraLabel">{sprache('FORM', 'LABEL', 'KAMERAAUSWÄHLEN')}</InputLabel>
              <Select value={selectedDeviceIdState} onChange={handleKameraAendernOnChange} labelId="coDataMatrixScanner_selectKameraLabel" label={sprache('FORM', 'LABEL', 'KAMERAAUSWÄHLEN')}>
                {videoInputDevicesState.map((device, index) => {
                  return (
                    <MenuItem key={index} value={device?.deviceId}>
                      {device.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

// component -> react-root
const WhenNoCameraAvailable = () => {
  const [globalState, dispatch] = useStateValue();
  const [berechtigungleitfadenDialogState, setBerechtigungleitfadenDialogState] = useState({ open: false });

  // function ->
  const openOrCloseBerechtigungleitfadenDialog = (shouldOpen) => {
    setBerechtigungleitfadenDialogState({ open: shouldOpen });
  };

  // function ->
  const closeWarning = () => {
    dispatch({ type: dispatchActions.hideCameraWarning });
  };

  const objWhenNoCameraAvailable = {
    coBerechtigungleitfadenDialog: {
      berechtigungleitfadenDialogState,
      openOrCloseBerechtigungleitfadenDialog
    }
  };

  const shouldHideComponent = !globalState.cameraWarningEnabled;

  return (
    <>
      {berechtigungleitfadenDialogState.open && <CoBerechtigungleitfadenDialog objParent={objWhenNoCameraAvailable.coBerechtigungleitfadenDialog} />}
      <Grid container className="padding__10 boxShadow width__max540" style={{ display: shouldHideComponent ? 'none' : 'flex' }}>
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={10}>
            <Typography className="typography flex">
              <PriorityHigh className="icon__highPriority" /> Sie haben den Zugang zu Ihrer Kamera verweigert!
            </Typography>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <IconButton onClick={closeWarning}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} className="margin__top10">
          <Link component="button" className="button__link--blue size_small" onClick={() => openOrCloseBerechtigungleitfadenDialog(true)}>
            Wie Kann ich die Kameraberechtigung einer Webseite ändern?
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

// component -> react-root
const CoDataMatrixScanner = ({ objParent }) => {
  return <WhenCameraAvailable objParent={objParent} />;
};

export default CoDataMatrixScanner;
