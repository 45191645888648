import React from 'react';

// import -> material-ui
import { Typography } from '@material-ui/core';

const CoVerboten = () => {
  return (
    <div className="coNichtGefunden__wrapper">
      <Typography className="coNichtGefunden__text" align="center">
        Sie haben <span className="typography__red"> keinen </span> Zugriff auf diese Seite
      </Typography>
    </div>
  );
};

export default CoVerboten;
