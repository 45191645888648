import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// import -> common
import { sprache } from '../../Common/sprache/sprache';

// import -> material-ui
import { Box, Grid, Typography } from '@material-ui/core';

// import -> reducer
import useStateValue from '../../../reducer/StateProvider';

const CoTopBar = () => {
  const history = useHistory();

  const [globalState, _] = useStateValue();

  // function ->
  const goToHome = () => {
    history.push('/');
  };

  return (
    <Box className="coNavigation__topBar">
      <Grid container justify="space-between" alignItems="center" className="grid__webseiteTitel padding__left12 padding__right12">
        <Grid item xs={6}>
          <Typography className="typography__webseiteTitel width__fitContent" onClick={goToHome}>
            {sprache('GENERELL', 'WEBSEITENAME', 'MEDICHECK')}
          </Typography>
        </Grid>
        {globalState.authentication.user.name && (
          <Grid item xs={6} container justify="flex-end">
            {/* <Typography className="padding__right20 typography__white">{globalState.authentication.user.name}</Typography> */}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CoTopBar;
