export const SORTING_TYPES = {
  LISTENNAME: 'listenname',
  NAME: 'name',
  GRUPPENNAME: 'gruppenname',
  GRUPPENNUMMER: 'gruppennummer',
  BEZEICHNUNG: 'bezeichnung',
  PZN: 'pzn',
  VERFALLSDATUM: 'verfallsdatum',
  ARZNEIMITTELLISTE: 'arzneimittelliste',
  NUMMER: 'nummer',
  LIEFERANT: 'lieferant',
  VERSANDDATUM: 'versanddatum',
  SERIENNUMMER: 'seriennummer',
  CHARGE: 'charge',
  ANZAHLPACKUNG: 'anzahl'
};

export const SORTING_DIRECTIONS = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NO_SORTING: 'no_sorting'
};
