import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useStateValue from '../../reducer/StateProvider';
import { createAxiosInstance, handleAndDownloadblobResponse } from '../Common/functions/axios';
import { sprache } from '../Common/sprache/sprache';

const CoDownloadArtikelCSV = () => {
  const [globalState, _] = useStateValue();

  const authToken = globalState.authentication.token;
  const authAxios = createAxiosInstance(authToken);

  const [siteTextState, setSiteTextState] = useState('Die Datei wird heruntergeladen.');

  const { pass } = useParams();

  // function ->
  const getArtikel = () => {
    authAxios
      .post('/paket_artikel/csv', {
        string: pass
      })
      .then((response) => {
        authAxios
          .post('/paket_artikel/csv-name', { string: pass })
          .then((response_) => {
            handleAndDownloadblobResponse(response.data, '.csv', response_.data.string);
            setSiteTextState('Die Datei ist erfolgreich runtergeladen.');
          })
          .catch(() => {
            setSiteTextState('Die Datei konnte nicht runtergeladen werden.');
          });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    getArtikel();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      {globalState.authentication.auth ? (
        ''
      ) : (
        <div className="grid__webseiteTitel" style={{ marginBottom: 30 }}>
          <Typography className="typography__webseiteTitel" align="center">
            {sprache('GENERELL', 'WEBSEITENAME', 'MEDICHECK')}
          </Typography>
        </div>
      )}
      <div className="coNichtGefunden__wrapper">
        <Typography className="coNichtGefunden__text" align="center">
          {siteTextState}
        </Typography>
      </div>
    </div>
  );
};

export default CoDownloadArtikelCSV;
